import { Routes } from '@angular/router';
import { EspacesComponent } from './espaces/espaces.component';
import { EspacesListComponent } from './espaces-list/espaces-list.component';
import { EspacesCategoriesListComponent } from './espaces-categories-list/espaces-categories-list.component';

export const EspacesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list/:type',
        component: EspacesListComponent,
        data: {
          title: 'Espacios'
        }
      },
      {
        path: 'categories',
        component: EspacesCategoriesListComponent,
        data: {
          title: 'Secciones'
        }
      },
      {
        path: 'new',
        component: EspacesComponent,
        data: {
          title: 'Nuevo espacio'
        }
      },
      {
        path: 'edit/:id',
        component: EspacesComponent,
        data: {
          title: 'Editar espacio'
        }
      }
    ]
  }
];
