import { PaginationRequest } from './paginationRequest.model';

export class ComunidadAutonomaRequest extends PaginationRequest {

  private comunidadAutonomaId: number;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getComunidadAutonomaId(): number {
    return this.comunidadAutonomaId;
  }

  /**
   * 
   */
  public setComunidadAutonomaId(comunidadAutonomaId: number): void {
    this.comunidadAutonomaId = comunidadAutonomaId;
  }

}