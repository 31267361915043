import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AngularCropperjsModule } from 'angular-cropperjs';
import {
  NgModule,
  LOCALE_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { ModalConfirmComponent } from './shared/modalconfirm/modalconfirm.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AgmCoreModule } from '@agm/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FileSaverModule } from 'ngx-filesaver';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DndDirective } from './directive/dnd.directive';
import { ConfirmaInvitacionComponent } from './confirma-invitacion/confirma-invitacion.component';
import { PlanReviewComponent } from './v2/planes-reviews/plan-review/plan-review.component';
import { PlanUpgradeComponent } from './v2/planes/plan-upgrade/plan-upgrade.component';
import { CustomersPaymentsComponent } from './v2/customers/customers-payments/customers-payments.component';
import { CustomersWizardTermsComponent } from './v2/customers/customers-wizard-terms/customers-wizard-terms.component';
import { PlanSpaceGoComponent } from './v2/planes/plan-space-go/plan-space-go.component';
import { ChargeBalanceComponent } from './v2/saldos/charge-balance/charge-balance.component';
import { PlanBasicInfoComponent } from './v2/planes/plan-basic-info/plan-basic-info.component';
import { TpvIntegrationModule } from './v2/tpv-integration/tpv-integration.module';
import { PlanBuyLocalsAskComponent } from './v2/planes/plan-buy-locals-ask/plan-buy-locals-ask.component';
import { FaqComponent } from './v2/components/faq/faq.component';
import { EspacesModule } from './v2/espaces/espaces.module';
import { PromotionalCouponComponent } from './v2/components/promotional-coupon/promotional-coupon.component';
import { SelectLocalsComponent } from './v2/components/select-locals/select-locals.component';
import { OptionProductComponent } from './v2/components/option-product/option-product.component';
import { SharedModule } from './shared/shared.module';
import { ConfirmModalComponent } from './v2/components/confirm-modal/confirm-modal.component';
import { ActivationElementsComponent } from './v2/components/activation-elements/activation-elements.component';
import { EventsCustomersComponent } from './v2/events/events-customers/events-customers.component';
import { ConvertSpaceToModalComponent } from './v2/components/convert-space-to-modal/convert-space-to-modal.component';
import { NextFeatureAvaibleComponent } from './v2/saldos/next-feature-avaible/next-feature-avaible.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

registerLocaleData(localeEs, 'es');

export let InjectorInstance: Injector;

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ModalConfirmComponent,
    SidebarComponent,
    ConfirmaInvitacionComponent,
    DndDirective,
    PlanReviewComponent,
    PlanUpgradeComponent,
    CustomersPaymentsComponent,
    CustomersWizardTermsComponent,
    PlanSpaceGoComponent,
    PlanBasicInfoComponent,
    ChargeBalanceComponent,
    PlanBuyLocalsAskComponent,
    FaqComponent,
    PromotionalCouponComponent,
    SelectLocalsComponent,
    OptionProductComponent,
    ConfirmModalComponent,
    ActivationElementsComponent,
    EventsCustomersComponent,
    ConvertSpaceToModalComponent,
    NextFeatureAvaibleComponent
  ],

  entryComponents: [
    PlanReviewComponent,
    PlanUpgradeComponent,
    CustomersPaymentsComponent,
    PlanSpaceGoComponent,
    PlanBasicInfoComponent,
    PlanBuyLocalsAskComponent,
    ChargeBalanceComponent,
    FaqComponent,
    PromotionalCouponComponent,
    SelectLocalsComponent,
    OptionProductComponent,
    ConfirmModalComponent,
    ConvertSpaceToModalComponent,
    ActivationElementsComponent,
    EventsCustomersComponent,
    NextFeatureAvaibleComponent
  ],

  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    PerfectScrollbarModule,
    NgxSpinnerModule,
    AppRoutingModule,
    CKEditorModule,
    NgxQRCodeModule,
    SharedModule,
    FileSaverModule,
    NgxUiLoaderModule,
    AngularCropperjsModule,
    EspacesModule,
    BsDatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAr-JS7Y9dbePHpmzhSL4wjQVji2QKBlgY',
      libraries: ['places'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TpvIntegrationModule,
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
