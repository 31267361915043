import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {
  private modalRef: NgbModalRef = null;

  constructor( private modalService: NgbModal) {
  }

  public show(
    content: any,
    successFn?: Function,
    properties?: any,
    modalOptions?: NgbModalOptions
  ): Promise<any> {
    const options = this.buildModalOptions( modalOptions);
    this.modalRef = this.modalService.open( content, options);

    if (properties) {
      for (let prop of Object.keys( properties)) {
        this.modalRef.componentInstance[prop] = properties[prop];
      }
    }

    let result = this.modalRef.result;

    if (successFn) {
      result = result.then(
        (result) => successFn( result),
        () => {}
      );
    }

    return result;
  }

  public open( modal: TemplateRef<any>): NgbModalRef  {
    return this.modalService.open( modal, this.buildModalOptions());
  }

  public close() {
    this.modalRef.close();
    this.modalRef = null;
  }

  private buildModalOptions( options?: NgbModalOptions): NgbModalOptions {
    let result: NgbModalOptions = {
      centered: true,
      windowClass: 'newModal',
      backdrop : 'static',
      keyboard : false
    };

    if (options) {
      result = Object.assign( result, options);
    }

    return result;
  }
}
