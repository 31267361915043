import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  constructor(
    private notification: NotificationService,
    private spinner: NgxSpinnerService)
  {
  }

  public run( fn: Function, errorPrefixMsg?: string, spinner: boolean = true) {
    if(spinner)
      this.spinner.show();

    fn().then(
      () => this.spinner.hide(),

      (error) => this.notification.error(
        errorPrefixMsg
        ? `${errorPrefixMsg}: ${error}`
        : error
      )
    );
  }
}
