import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Local } from "../../../models/local";
import { Plan } from "../../../models/plan";

@Component({
  selector: "app-convert-space-to-modal",
  templateUrl: "./convert-space-to-modal.component.html",
  styleUrls: ["./convert-space-to-modal.component.css"],
})
export class ConvertSpaceToModalComponent implements OnInit {
  @Input() public title: string;
  @Input() public msg: string;
  @Input() public ok: string;
  @Input() public ko: string;
  @Input() public local: Local;
  @Input() public plan: Plan;

  constructor(private modal: NgbActiveModal) {}

  ngOnInit() {
    if (
      (this.local.planId == undefined ||
      this.local.planId == null) ||
      this.local.planId < 2
    ) {
      this.title = "Mejorar espacio a PRO ó PREMIUM";
      this.msg = "¿ Deseas mejorar este espacio ?";
    } else {
      this.title = "Mejorar espacio a PREMIUM ";
      this.msg = "¿ Deseas mejorar este espacio ?";
    }
  }

  close() {
    this.modal.close({ close: true, didmiss: false });
  }

  dismiss() {
    this.modal.dismiss({ close: false, didmiss: true });
  }

  upgradeToPro(local: Local) {
    // Lógica para actualizar a PRO
    this.modal.close({ upgrade: "PRO" }); // Cerrar el modal
  }

  upgradeToPremium(local: Local) {
    // Lógica para actualizar a PREMIUM
    this.modal.close({ upgrade: "PREMIUM" }); // Cerrar el modal
  }
}
