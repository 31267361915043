import { PaginationRequest } from './paginationRequest.model';

export class LocalRequest extends PaginationRequest {

  private userId: number;
  private clienteId: number;
  private paisId: string;
  private comunidadAutonomaId: string;
  private provinciaId: string;
  private ciudadId: string;
  private administracionId: number;
  private rutaId: number;
  private activo: boolean;
  private nombre: string;
  private ciudad: boolean;
  private zonas: boolean;
  private zonaSubzonas: boolean;
  private isAdministracionCategoria: boolean;
  private oficinaturismo: number;
  private isGeneral: boolean;
  private peticionescontrol: boolean;
  private planId: number;

  constructor() {
    super();
    this.oficinaturismo = null;
    this.isGeneral = null;
    this.isAdministracionCategoria = null;
  }

  /**
   *
   */
  public getUserId(): number {
    return this.userId;
  }

  /**
   *
   */
  public setUserId(userId: number): void {
    this.userId = userId;
  }

   /**
   *
   */
    public getClienteId(): number {
      return this.clienteId;
    }

    /**
     *
     */
    public setClienteId(clienteId: number): void {
      this.clienteId = clienteId;
    }

  /**
   *
   */
   public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   *
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

  /**
   *
   */
  public getRutaId(): number {
    return this.rutaId;
  }

  /**
   *
   */
  public setRutaId(rutaId: number): void {
    this.rutaId = rutaId;
  }

  /**
   *
   */
  public getActivo(): boolean {
    return this.activo;
  }

  /**
   *
   */
  public setActivo(activo: boolean): void {
    this.activo = activo;
  }

  /**
   *
   */
  public getNombre(): string {
    return this.nombre;
  }

  /**
   *
   */
  public setNombre(nombre: string): void {
    this.nombre = nombre;
  }

  /**
   *
   */
  public getCiudad(): boolean {
    return this.ciudad;
  }

  /**
   *
   */
  public setCiudad(ciudad: boolean): void {
    this.ciudad = ciudad;
  }

  /**
   *
   */
     public getPaisId(): string {
      return this.paisId;
    }

    /**
     *
     */
    public setPaisId(paisId: string): void {
      this.paisId = paisId;
    }

    /**
   *
   */
     public getProvinciaId(): string {
      return this.provinciaId;
    }

    /**
     *
     */
    public setProvinciaId(provinciaId: string): void {
      this.provinciaId = provinciaId;
    }

    /**
   *
   */
     public getComunidadAutonomaId(): string {
      return this.comunidadAutonomaId;
    }

    /**
     *
     */
    public setComunidadAutonomaId(comunidadAutonomaId: string): void {
      this.comunidadAutonomaId = comunidadAutonomaId;
    }

    /**
   *
   */
     public getCiudadId(): string {
      return this.ciudadId;
    }

    /**
     *
     */
    public setCiudadId(ciudadId: string): void {
      this.ciudadId = ciudadId;
    }

  /**
   *
   */
  public getZonas(): boolean {
    return this.zonas;
  }

  /**
   *
   */
  public setZonas(zonas: boolean): void {
    this.zonas = zonas;
  }

  /**
   *
   */
   public getPeticionesControl(): boolean {
    return this.peticionescontrol;
  }

  /**
   *
   */
  public setPeticionesControl(peticionescontrol: boolean): void {
    this.peticionescontrol = peticionescontrol;
  }

  /**
   *
   */
  public getZonaSubzonas(): boolean {
    return this.zonaSubzonas;
  }

  /**
   *
   */
  public setZonaSubzonas(zonaSubzonas: boolean): void {
    this.zonaSubzonas = zonaSubzonas;
  }

  /**
   *
   */
   public getOficinaturismo(): number {
    return this.oficinaturismo;
  }

  /**
   *
   */
  public setOficinaturismo(value: number): void {
    this.oficinaturismo = value;
  }

  /**
   *
   */
   public getIsAdministracionCategoria(): boolean {
    return this.isAdministracionCategoria;
  }

  /**
   *
   */
  public setIsAdministracionCategoria(value: boolean): void {
    this.isAdministracionCategoria = value;
  }

  /**
   *
   */
   public getIsGeneral(): boolean {
    return this.isGeneral;
  }

  /**
   *
   */
  public setIsGeneral(value: boolean): void {
    this.isGeneral = value;
  }

  /**
   *
   */
  public getPlanId(): number {
    return this.planId;
  }

  /**
   *
   */
  public setPlanId(value: number): void {
    this.planId = value;
  }

}
