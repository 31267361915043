import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as global from "./global.service";
import { objToCamelCase } from "../helpers/object.helper";
import { Pagination } from "../models/pagination";
import { Promo } from "../models/promo";
import { PromoRequest } from "../interfaces/promoRequest.model";
import { PromoPhoto } from "../v2/promotions/interfaces/promo-photo";

@Injectable({
  providedIn: "root",
})
export class PromoService {
  private static PROMOS_PATH = global.API_URL + "/api/promos";
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }

  public byId(id: number): Observable<Promo> {
    this.httpOptions = {
      headers: this.httpHeaders,
    };
    return this.http
      .get<Promo>(PromoService.PROMOS_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Promo>(response);
        })
      );
  }

  public index(promoRequest: PromoRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (promoRequest.getUserId())
      httpParams = httpParams.set("userId", String(promoRequest.getUserId()));

    if (promoRequest.getType() !== null)
      httpParams = httpParams.set("type", String(promoRequest.getType()));

    if (typeof promoRequest.getActiva() === "boolean")
      httpParams = httpParams.set("activa", String(promoRequest.getActiva()));

    if (promoRequest.getClienteId())
      httpParams = httpParams.set(
        "clienteId",
        String(promoRequest.getClienteId())
      );

    if (promoRequest.getAdministracionId())
      httpParams = httpParams.set(
        "administracionId",
        String(promoRequest.getAdministracionId())
      );

    if (promoRequest.getCliente())
      httpParams = httpParams.set("cliente", String(promoRequest.getCliente()));

    if (promoRequest.getAdministracion())
      httpParams = httpParams.set(
        "administracion",
        String(promoRequest.getAdministracion())
      );

    if (promoRequest.getComunidadAutonoma())
      httpParams = httpParams.set(
        "comunidadAutonoma",
        String(promoRequest.getComunidadAutonoma())
      );

    if (promoRequest.getCiudad())
      httpParams = httpParams.set("ciudad", String(promoRequest.getCiudad()));

    if (promoRequest.getIdiomas())
      httpParams = httpParams.set("idiomas", String(promoRequest.getIdiomas()));

    if (promoRequest.getPreferencias())
      httpParams = httpParams.set(
        "preferencias",
        String(promoRequest.getPreferencias())
      );

    if (promoRequest.getLocal())
      httpParams = httpParams.set("local", String(promoRequest.getLocal()));

    if (promoRequest.getLocalCiudad())
      httpParams = httpParams.set(
        "localCiudad",
        String(promoRequest.getLocalCiudad())
      );

    if (promoRequest.getPerPage())
      httpParams = httpParams.set("perPage", String(promoRequest.getPerPage()));

    if (promoRequest.getPage())
    httpParams = httpParams.set("page", String(promoRequest.getPage()));

    if (promoRequest.getOrderBy())
      httpParams = httpParams.set("orderBy", promoRequest.getOrderBy());

    if (promoRequest.getSortBy())
      httpParams = httpParams.set("sortBy", promoRequest.getSortBy());

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(PromoService.PROMOS_PATH, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Pagination>(response);
        })
      );
  }

  public create(promo: Promo): Observable<Promo> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set("idiomas", String(true));
    httpParams = httpParams.set("local", String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<Promo>(PromoService.PROMOS_PATH, promo, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Promo>(response);
        })
      );
  }

  public update(promo: Promo): Observable<Promo> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set("local", String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<Promo>(
        PromoService.PROMOS_PATH + `/${promo.id}`,
        promo,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public desactivar(type: number): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.put<any>(
      PromoService.PROMOS_PATH + `/desactivar/${type}`,
      {},
      this.httpOptions
    );
  }

  public duplicate(promo: Promo): Observable<Promo> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set("idiomas", String(true));
    httpParams = httpParams.set("local", String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<Promo>(
        PromoService.PROMOS_PATH + "/duplicate",
        promo,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public delete(promo: Promo): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(PromoService.PROMOS_PATH + `/${promo.id}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public show(id: number, promoRequest: PromoRequest): Observable<Promo> {
    let httpParams: HttpParams = new HttpParams();

    if (promoRequest.getPhotos())
      httpParams = httpParams.set("photos", String(promoRequest.getPhotos()));

    if (promoRequest.getPerPage())
      httpParams = httpParams.set("perPage", String(promoRequest.getPerPage()));

    if (promoRequest.getOrderBy()) {
      httpParams = httpParams.set("orderBy", promoRequest.getOrderBy());
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Promo>(PromoService.PROMOS_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((promo: any) => {
          return objToCamelCase<Promo>(promo);
        })
      );
  }

  public photos(promo: Promo): Observable<Promo> {
    this.httpOptions = {
      headers: this.httpHeaders.delete("Content-Type"),
      params: {},
    };

    const formData: FormData = new FormData();

    promo.photos.map((element: PromoPhoto, index: number) => {
      if (element.id) {
        formData.append(`photos[${index}][id]`, String(element.id));
      }
      if (element.imagen) {
        formData.append(`photos[${index}][imagen]`, element.imagen);
      }
    });

    return this.http.post<Promo>(
      PromoService.PROMOS_PATH + `/${promo.id}/photos`,
      formData,
      this.httpOptions
    );
  }

  public removePhoto(promo: Promo, promoPhoto: PromoPhoto): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.delete<any>(
      PromoService.PROMOS_PATH + `/${promo.id}/photos/${promoPhoto.id}`,
      this.httpOptions
    );
  }

  public statusKyc(clienteId: number): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.get<any>(
      global.API_URL + `/api/onboarding/merchant/check/kyc/by-customer-id/${clienteId}`,
      this.httpOptions
    );

  }
}
