import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cupon } from '../../../models/cupon';
import { Plan } from '../../../models/plan';
import { CuponService } from '../../../services/cupon.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-promotional-coupon',
  templateUrl: './promotional-coupon.component.html',
  styleUrls: ['./promotional-coupon.component.css']
})
export class PromotionalCouponComponent implements OnInit {

  @Input() public coupon: Cupon;
  public plan: Plan;
  public formPromocional: FormGroup;

  constructor(
    private modalService: NgbActiveModal,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private couponService: CuponService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {

    this.formPromocional = this.formBuilder.group({
      codigoPromocional: ['', Validators.required],
    });
  }

  verifyPromotional() {
    this.spinner.show();

    const code = this.formPromocional.get('codigoPromocional');

    this.couponService.byCode(code.value)
    .subscribe(
      (data) => {

        if ( data.hasOwnProperty('error') && data.error === 1 ) {
          this.notificationService.errorTranslate(data.message);
          return false;
        } else {
          this.coupon = data;
          this.spinner.hide();
          this.confirm(data);
        }

      },
      (e) => {
        this.notificationService.error(e.error);
        this.spinner.hide();
      });

  }



  close() {
    this.modalService.dismiss({});
  }

  confirm(cupon) {
    this.modalService.close(cupon);
  }

}
