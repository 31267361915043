import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxGalleryModule } from 'ngx-gallery';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';
import { EspacesQrComponent } from '../v2/espaces/espaces-qr/espaces-qr.component';
import { EspacesCustomersComponent } from '../v2/espaces/espaces-customers/espaces-customers.component';
import { LocalsAvailableComponent } from '../v2/promotions/locals-available/locals-available.component';
import { PhotosComponent } from '../v2/photos/photos.component';
import { ImageFieldComponent } from '../v2/common/image-field/image-field.component';
import { ImageCropperComponent } from '../v2/common/image-cropper/image-cropper.component';
import { WizardHeaderComponent } from '../v2/common/wizard-header/wizard-header.component';
import { WizardFooterComponent } from '../v2/common/wizard-footer/wizard-footer.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { InvitationsComponent } from '../v2/invitations/invitations.component';
import { SalesAgentComponent } from '../v2/espaces/sales-agents/sales-agent.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule,
        NgbModule.forRoot(),
        NgxSliderModule,
        NgxQRCodeModule,
        CKEditorModule,
        TranslateModule,
        NgxGalleryModule,
        AngularCropperjsModule,
        NgxDropzoneModule
    ],
    declarations: [
        LocalsAvailableComponent,
        EspacesQrComponent,
        EspacesCustomersComponent,
        PhotosComponent,
        InvitationsComponent,
        ImageFieldComponent,
        ImageCropperComponent,
        WizardHeaderComponent,
        WizardFooterComponent,
        SalesAgentComponent
    ],
    entryComponents: [
        LocalsAvailableComponent,
        PhotosComponent,
        InvitationsComponent
    ],
    exports: [
        TranslateModule,
        ImageFieldComponent,
        ImageCropperComponent,
        WizardHeaderComponent,
        WizardFooterComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
