import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { EventoService } from '../services/evento.service';
import { EventInvitationsService } from '../services/event-invitations.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-confirma-invitacion',
  templateUrl: './confirma-invitacion.component.html',
  styleUrls: ['./confirma-invitacion.component.scss']
})
export class ConfirmaInvitacionComponent implements OnInit {

  year: any;
  formInvitacion: FormGroup;
  idEvento: any;
  event: any;

  constructor(private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private eventoService: EventoService,
    private eventInvitationsService: EventInvitationsService) {
  }

  ngOnInit() {

    this.formInvitacion = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      evento_id: ['', Validators.required]
    });
    this.year = new Date().getFullYear();
    this.route.params.subscribe(params => {
      this.idEvento = atob(params['id']);
      this.eventoService.byId(this.idEvento).subscribe((data: any) => {
        this.event = data;
      });
      this.formInvitacion.patchValue({ evento_id: this.idEvento });
    });
  }

  confirm(formValue: any) {
    this.spinner.show();
    this.eventInvitationsService.confirm(formValue).subscribe((data: any) => {
      this.spinner.hide();
      if (+data.error === 0) {
        this.notificationService.success('Recibiras un email con las instrucciones a seguir para asistir al evento.')
        this.formInvitacion.reset();
      } else {
        this.spinner.hide();
        this.notificationService.error(data.message);
      }
    },(e) => this.notificationService.error(e));
  }

}
