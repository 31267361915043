import * as global from '../../../services/global.service';
import { Component, OnInit, ViewChild, Input, AfterContentInit, AfterViewInit, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersWizardTermsComponent } from '../customers-wizard-terms/customers-wizard-terms.component';
import { NotificationService } from '../../../services/notification.service';
import { PlanService } from '../../../services/plan.service';
import { PlanRequest } from '../../../models/plan-request.model';
import { Plan } from '../../../models/plan';
import { Compra } from '../interfaces/compra';
import { FormaPago } from '../interfaces/forma-pago';
import { AuthenticationService } from '../../../services/authentication.service';
import { Events } from '../../../services/events.service';
import { PromotionalCouponComponent } from '../../components/promotional-coupon/promotional-coupon.component';
import { Cupon } from '../../../models/cupon';
import { User } from '../../../models/user';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { Local } from '../../../models/local';
import { LocalService } from '../../../services/local.service';
import { Pagination } from '../../../models/pagination';
import { HttpErrorResponse } from '@angular/common/http';
import { SlotService } from '../../../services/slot.service';


@Component({
  selector: 'app-customers-payments',
  templateUrl: './customers-payments.component.html',
  styleUrls: ['./customers-payments.component.scss'],
})
export class CustomersPaymentsComponent implements OnInit, DoCheck, AfterContentInit {
  amount = ''; // TPV: Stores amount to be passed to tpv, as integer, no formatting, just money numbers in string
  @Input() public plan: Plan;
  @Input() public stepVisible = 1;
  @Input() public optionPurchase = 0;
  @Input() public register = false;
  @Input() public localWantBuy: Local;
  @Input() public convertFlag: boolean = false;
  @Input() public firstTime: boolean = false;
  @ViewChild(CustomersWizardTermsComponent, {
    read: CustomersWizardTermsComponent,
  })

  // Variables del Componente
  public wizardTerms: CustomersWizardTermsComponent;
  public currencyPipe: CurrencyPipe;
  public formaPagos: Array<FormaPago>;
  public orderForm: FormGroup;
  public readonly IVA: number = 21;
  public paymentStatus = 0;
  public buying: boolean;
  public coupon: Cupon;
  public user: User;
  public planActual: Plan;
  public planes: Plan[];
  private localsActiveWithoutSlotsAsigned: Local[];

  // Cantidad de SLOTS
  public slots;

  // Cantidades BASE e IVA
  public quantityPriceSpaces = 0;
  public orderQuantityPrice = 0;
  public orderPayWithTax = 0;
  public orderPayBase = 0;
  public orderPayIva = 0;

  // Datos a enviar al Iframe del TPV
  public product = null;
  public order: Compra;

  public documentsRead = {
    masterServiceAgreement: false,
    dataProcessingAgreement: false,
    readAndConfirmTerms: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private slotsService: SlotService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private localService: LocalService,
    private planService: PlanService,
    private modalService: NgbModal,
    private event: Events,
  ) {

    this.currencyPipe = new CurrencyPipe('es');
    this.formaPagos = [];
    this.buying = false;
  }

  ngOnInit() {
    const planRequest: PlanRequest = new PlanRequest();
    planRequest.setOrderBy('ASC');

    this.planService
      .index(planRequest)
      .toPromise()
      .then(
        (pagination: any) => {
          this.planes = pagination.data;
        },
        (error: HttpErrorResponse) => {
        }
      );

    if(this.localWantBuy){
      this.slotsService.refresh();
    }
    
    // Obtenemos los datos de usuario logueado
    this.user = this.authenticationService.currentUserValue.user;

    if (this.firstTime) {
      this.stepVisible = 1;
    } else {
      this.getLocalsActiveWithoutSlotsAsigned()
        .then(() => this.stepVisible = 2)
        .catch(() => this.stepVisible = 2);
    }

    // Formulario de compra
    this.orderForm = this.formBuilder.group({
      cantidad: [null, [Validators.required]],
      cantidadLocales: [1, [Validators.required]],
      formaPagosId: [1, Validators.required],
      comentario: ['Compra plan desde el Panel'],
      planId: [this.plan.id],
    });
  }

  ngDoCheck() {
    // Si tenemos los planes YA...
    if (this.planes) {
      // Obtenemos el plan Actual
      if(this.localWantBuy){
        this.planActual = this.planes.find((e) => e.id == this.localWantBuy.planId);
      }

      // Comprobamos si tiene SLOTS
      if((this.slotsService.slot && this.slotsService.slot.slots_available_pro > 0) || (this.localWantBuy && this.localWantBuy.planId == 2)){
        if(this.planActual == undefined){
          this.planActual = this.planes.find((e) => e.id == 2);
        }
        this.orderQuantityPrice = this.orderForm.value.cantidadLocales * (this.plan.moMinimo - this.planActual.moMinimo);
      } else{
        this.orderQuantityPrice = this.orderForm.value.cantidadLocales * (this.plan.moMinimo);
      }

    }

  }

  ngAfterContentInit() {
    // Comprobamos el PLAN
    this.orderForm.get("cantidad").setValue(this.orderQuantityPrice);
    this.setAmountForTpv(String(this.orderPayWithTax));
  }

  verifyLocalWantBuy(localsData: Local[]) {
    localsData.map((local) => {
      if (this.localWantBuy && local.id === this.localWantBuy.id) {
        local['checked'] = true;
      }
    });
  }

  changePlan(planId: number) {

    // 
    if (this.user.planId == 2) {
      this.plan = this.planes.find((e) => e.id == planId);
      this.orderForm.get("cantidad").setValue(this.plan.moMinimo - this.planActual.moMinimo);
      this.orderForm.get("cantidadLocales").setValue(1);
      this.orderForm.get("planId").setValue(this.plan.id);
    } else {
      this.plan = this.planes.find((e) => e.id == planId);
      this.orderForm.get("cantidad").setValue(this.plan.moMinimo);
      this.orderForm.get("cantidadLocales").setValue(1);
      this.orderForm.get("planId").setValue(this.plan.id);
    }
  }

  timeoutTpv() {
    this.changeStep(this.stepVisible - 1);
  }

  customerHaveToSeeTerms() {
    // Clientes PRO / PREMIUM
    if (this.user.plan !== null && this.user.plan.id > 1) { return false; }

    // Clientes BASIC o clientes que no se le ha asignado todavia un plan
    if (this.user.plan === null || this.user.plan.id === 1) { return true; }
  }

  public changeStep(index: number, $event?: number): void {
    //
    const recalculate = () => {
      this.orderPayWithTax = this.orderQuantityPrice * (1 + (this.IVA / 100));
      this.orderPayBase = this.orderQuantityPrice;
      this.orderPayIva = this.orderPayWithTax - this.orderPayBase;

      this.orderForm.patchValue({ cantidad: this.orderQuantityPrice });
      this.setAmountForTpv(String(this.orderPayWithTax));
      if (!this.orderForm.valid) {
        this.notificationService.error(
          this.translateService.instant('Debe seleccionar una cantidad')
        );
        return;
      }
    }

    if (index < 1) {
      this.activeModal.dismiss(null);
    } else {

      if (this.stepVisible === 1) {
        const result = this.wizardTerms.acceptTerms();
        if (!result) { return; }
      }

      if (index === 1 && !this.customerHaveToSeeTerms()) {
        this.activeModal.dismiss(null);
      }

      if (index === 1 && this.optionPurchase === 1) {
        this.activeModal.dismiss();
      }

      if (index === 3) {
        recalculate();
      }

      /**
      *  COMPRA ESPACIOS
      */
      if (this.stepVisible === 2) {


        // CONTINUAR
        if (index === this.stepVisible + 1) {

          if (this.verifyLocalsAndSlots() && !this.localWantBuy) {
            this.stepVisible = 2.1;
          } else {
            this.stepVisible = 3;
          }

          return;
        }

        // VOLVER
        if (index === this.stepVisible - 1) {
          this.stepVisible = index;
          return;
        }

      }


      /**
      * SELECCIONA ESPACIOS
      */
      if (this.stepVisible === 2.1) {

        // CONTINUAR
        if (index === this.stepVisible + 1) {

          this.order = this.orderForm.value;
          this.order.recarga = false;
          this.product = global.PLATFORM_PRODUCT_TYPES.find((product) => product.id === this.plan.id).product;

          // if ( this.coupon !== undefined ) {
          //  this.order.codigo = this.coupon.codigo;
          // }

          if (this.localsActiveWithoutSlotsAsigned !== undefined) {

            this.order.localActive = this.localsActiveWithoutSlotsAsigned
              .filter((local) => local.hasOwnProperty('checked') && local['checked'])
              .map((e) => e.id);
          }

          if (moment(this.user.createdAt).isSameOrBefore(moment('2023-03-31'))) {
            this.order.codigo = environment.cupon;
          }

          recalculate();
          this.stepVisible = 3;
          return;
        }

        // VOLVER
        if (index === this.stepVisible - 1) {
          this.stepVisible = 2
          return;
        }

      }

      /**
      * CONFIRMACION PAGO
      */
      if (this.stepVisible === 3) {

        // CONTINUAR
        if (index === this.stepVisible + 1) {
          this.order = this.orderForm.value;
          this.order.recarga = false;
          this.product = global.PLATFORM_PRODUCT_TYPES.find((product) => product.id === this.plan.id).product;

          // if ( this.coupon !== undefined ) {
          //  this.order.codigo = this.coupon.codigo;
          // }

          if(this.localWantBuy){
            this.localWantBuy['checked'] = true
          }

          if (this.localsActiveWithoutSlotsAsigned !== undefined) {
            // Si compro más espacios que locales activos sin slot tengo.
            if (this.verifyLocalsAndSlots()) {
              // Cojo los locales que he seleccionado
              this.order.localActive = this.localsActiveWithoutSlotsAsigned
                .filter((local) => local.hasOwnProperty('checked') && local['checked'])
                .map((e) => e.id);
            } else {
              // Cojo todos los locales
              this.order.localActive = this.localsActiveWithoutSlotsAsigned
                .map((e) => e.id);
            }
          }

          if (moment(this.user.createdAt).isSameOrBefore(moment('2023-03-31'))) {
            this.order.codigo = environment.cupon;
          }

          this.stepVisible = index;
          return;
        }

        // VOLVER
        if (index === this.stepVisible - 1) {

          if (this.verifyLocalsAndSlots() && !this.localWantBuy) {
            this.stepVisible = 2.1;
          } else {
            this.stepVisible = 2;
          }

          return;
        }

      }


      /**
      * PAGO
      */
      if (this.stepVisible === 4) {

        this.event.publish('stopCheckPaymenyt');

        // CONTINUAR
        if (index === this.stepVisible + 1) {

          if ($event) {
            this.paymentStatus = $event;
          }

        }

        // VOLVER
        if (index === this.stepVisible - 1) {
          this.stepVisible = index;
        }

      }

      /**
      * STATUS
      */
      if (this.stepVisible === 5) {

        // CONTINUAR
        if (index === this.stepVisible + 1) {
          this.stepVisible = index;
        }

        // VOLVER
        if (index === this.stepVisible - 1) {
          this.stepVisible = index;
          if ($event) {
            this.paymentStatus = $event;
          }
        }

      }

      this.stepVisible = index;

    }
  }

  public finishStep(): void {
    const compra: Compra = this.orderForm.value;
    this.activeModal.close(compra);
  }

  public management(cantidad: number): void {
    //
    let cantidadLocales = this.orderForm.get('cantidadLocales').value

    //
    if (+cantidadLocales + cantidad >= 1) {

      this.orderForm.get('cantidadLocales').setValue(+cantidadLocales + cantidad);
      this.orderForm.get('cantidad').setValue(this.orderQuantityPrice);

      this.setAmountForTpv(String(this.orderPayWithTax))
    }
  }

  /**
   * TPV: Stores amount as money number, caring user input
   * ToDo RE-FACTORIZATION: this component must send amount as-is, no matter what.
   * ToDo RE-FACTORIZATION: move this to src/app/v2/tpv-integration/tpv-integration.component.ts
   * ToDo RE-FACTORIZATION: change setAmountFromInput() accordingly
   * @param amount
   * @param mustHaveDecimals
   * @private
   */
  private setAmountForTpv(amount: string, mustHaveDecimals: boolean = true): void {
    // sanitize amount
    this.amount = String(Number(amount).toFixed(2));
  }

  /**
   * TPV: Helper function:
   * Parse user input amount to get just a number in quotes:
   * No formatting nor currency, decimals come in as comma (,) should go out as dot (.)
   * NOTE about decimals and integer: It makes a truncate, not a round
   * ToDo RE-FACTORIZATION: this component must send amount as-is, no matter what.
   * ToDo RE-FACTORIZATION: move this to src/app/v2/tpv-integration/tpv-integration.component.ts
   * ToDo RE-FACTORIZATION: change setAmountFromInput() accordingly
   * @param amount
   * @param mustReturnDecimals
   */
  convertStringWithCommaSeparatorToNumber(amount: string, mustReturnDecimals: boolean = false): string {
    // Remove non-numeric characters except commas
    const cleanedString = amount.replace(/[^0-9,]/g, '');

    // Replace comma with dot as the decimal separator
    const stringWithDot = cleanedString.replace(/,/g, '.');

    // Split the string by dot
    const [integerPart, decimalPart] = stringWithDot.split('.');

    // If there is no decimal part, append ".00" and we're all set
    if (!decimalPart) {
      return mustReturnDecimals ? integerPart + '.00' : integerPart;
    }

    // If there is only one digit in the decimal part, append a zero at the end and we're all set
    if (decimalPart.length === 1) {
      return mustReturnDecimals ? integerPart + '.' + decimalPart + '0' : integerPart;
    }

    // If there are two or more digits in the decimal part, keep only the first two
    return mustReturnDecimals ? integerPart + '.' + decimalPart.substring(0, 2) : integerPart;
  }

  openCouponPromotional() {
    const modalPromotionalCoupon = this.modalService.open(PromotionalCouponComponent, {
      size: 'lg',
      windowClass: 'wizard-two',
      backdrop: 'static',
      keyboard: true,
      backdropClass: 'light-backdrop wizard-two',
    });

    modalPromotionalCoupon.result.then(
      (result) => {
        this.coupon = result;
      },
      (e) => console.log('CANCEL->', e));

  }

  verifyLocalsAndSlots() {

    // Si tiene locales activos sin slots asignados el botón se ve **BASIC
    if (this.localsActiveWithoutSlotsAsigned !== undefined
      && this.localsActiveWithoutSlotsAsigned.length > +this.orderForm.get('cantidadLocales').value) {

      return true;

    } else {
      // Si todos sus locales activos tienen slots el botón se OCULTA **PRO
      return false;
    }
  }

  getLocalsActiveWithoutSlotsAsigned() {
    return new Promise((resolve, reject) => {
      this.localService.haveSlots(0, this.plan.id)
        .subscribe(
          (response: Pagination) => {

            if (response.hasOwnProperty('error') && response['error'] === 1) {
              this.notificationService.error(response['message']);
              reject('KO');
            } else {

              this.localsActiveWithoutSlotsAsigned = response.data;
              this.verifyLocalWantBuy(response.data);

              if (this.plan !== undefined) {
                resolve(response.data);
              } else {
                reject('KO');
              }
            }

          },
          (e) => {
            this.notificationService.error(e.error);
          });
    });
  }
}
