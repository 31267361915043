import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Panel',
    icon: 'fas fa-tachometer-alt',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Maestros',
    icon: 'fas fa-cogs',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '',
      title: 'Configuraciones',
      icon: 'mdi mdi-settings',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [{
        path: '/configurations/edit',
        title: 'Configuraciones',
        icon: 'fas fa-cogs',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/encuestas/list',
        title: 'Encuestas',
        icon: 'fas fa-cogs',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/pages/list',
        title: 'Páginas',
        icon: 'fas fa-page',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/masters/languages/list',
        title: 'Idiomas',
        icon: 'mdi mdi-flag',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/categorias/list',
        title: 'Categorias',
        icon: 'mdi mdi-hexagon',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/categoriasayuda/list',
        title: 'Categorias Ayuda',
        icon: 'mdi mdi-hexagon',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/alergenos/list',
        title: 'Alérgenos',
        icon: 'fas fa-syringe',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/estadosolicitudes/list',
        title: 'Estados Solicitudes',
        icon: 'mdi mdi-help',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/tiposoportes/list',
        title: 'Tipos de soporte',
        icon: 'fas fa-sign',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/promotipos/list',
        title: 'Tipos de promo',
        icon: 'fas fa-sign',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/tiposespacios/list',
        title: 'Tipos de espacios',
        icon: 'fas fa-map-marker-alt',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/informacionoficial/phones',
        title: 'Teléfonos',
        icon: 'fas fa-map-marker-alt',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/informacionoficial/links',
        title: 'Enlaces de interés',
        icon: 'fas fa-map-marker-alt',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/informacionoficial/informacionturistica',
        title: 'Puntos de información turistica',
        icon: 'fas fa-map-marker-alt',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      }
      ]
    },
    {
      path: '/users/list',
      title: 'Usuarios',
      icon: 'mdi mdi-account',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/users/go-to-pro',
      title: 'Pasar a Pro',
      icon: 'mdi mdi-account',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/users/go-to-premium',
      title: 'Pasar a Pemium',
      icon: 'mdi mdi-account',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/users/transfer-space',
      title: 'Traspasar Espacio',
      icon: 'mdi mdi-account',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/usersdemo/list',
      title: 'Usuarios para demos',
      icon: 'mdi mdi-account',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/preferences/list',
      title: 'Preferencias',
      icon: 'mdi mdi-heart',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/puntosinformacion/list',
      title: 'Puntos información',
      icon: 'fas fa-map-marker-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/cupones/list',
      title: 'Cupones',
      icon: 'fas fa-ticket',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/caracteristicas/list',
      title: 'Caracteristicas Loc.',
      icon: 'fas fa-map-marker-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/textoslegales/list',
      title: 'Textos legales',
      icon: 'fas fa-map-marker-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/ciudades/list',
      title: 'Ciudades',
      icon: 'fas fa-map-marker-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }
    ]
  },
  {
    path: '',
    title: 'Admon. Pública',
    icon: 'fas fa-university',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/publicadmon/list',
      title: 'Administración Pública',
      icon: 'mdi mdi-account',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }],
  },
  {
    path: '',
    title: 'Centro de ayuda',
    icon: 'fas fa-question-circle',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/questions/list',
        title: 'FAQ',
        icon: 'mdi mdi-help',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/helpcenter/list',
        title: 'Vídeos de ayuda',
        icon: 'mdi mdi-help',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [],
      },
    ]
  },
  {
    path: '',
    title: 'Clientes',
    icon: 'fas fa-users',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/customers/list',
      title: 'Clientes',
      icon: 'fas fa-users',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/invitaciones/list',
      title: 'Invitaciones',
      icon: 'fas fa-ticket-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }
    /*{
      path: '/patrocinadores/list',
      title: 'Patrocinadores',
      icon: 'fas fa-handshake',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/groupscustomers/list',
      title: 'Grupos de clientes',
      icon: 'fas fa-tags',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }*/],
  },
  {
    path: '',
    title: 'Locales',
    icon: 'fas fa-store',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/locals/list',
      title: 'Locales',
      icon: 'fas fa-store',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/cartadigital/list',
      title: 'Carta digital',
      icon: 'fas fa-map-marker-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/locals/pegalist',
      title: 'Listado de peticiones',
      icon: 'fas fa-map-marker-alt',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }]
  },
  {
    path: '',
    title: 'Promociones',
    icon: 'fas fa-qrcode',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/promotions/list/0',
      title: 'Promociones APP',
      icon: 'fas fa-percent',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/promotions/list/1',
      title: 'Promociones QR',
      icon: 'fas fa-qrcode',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
  /*{
    path: '/promotions/list/2',
    title: 'Promociones Push',
    icon: 'fas fa-percent',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },*/]
  },
  {
    path: '/tarjetas/list',
    title: 'Tarjetas',
    icon: 'fas fa-tags',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/pedidos/list',
    title: 'Pedidos',
    icon: 'fas fa-tags',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/eventos/list',
    title: 'Eventos',
    icon: 'fas fa-calendar',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Rutas de la tapa',
    icon: 'fas fa-beer',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/routes/managers/',
      title: 'Gestores',
      icon: 'fas fa-percent',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    },
    {
      path: '/routes/list/',
      title: 'Rutas',
      icon: 'fas fa-percent',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }]
  },
  {
    path: '',
    title: 'Agendas Culturales',
    icon: 'fas fa-qrcode',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/agendas/list',
      title: 'Lista Agendas Culturales',
      icon: 'fas fa-percent',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }]
  },
  {
    path: '',
    title: 'Experiencias',
    icon: 'fas fa-qrcode',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/experiencias/list',
      title: 'Lista Experiencias',
      icon: 'fas fa-percent',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [],
    }]
  },
];

export const ROUTES_3: RouteInfo[] = [
  {
    path: '/routes/list/',
    title: 'Rutas de la tapa',
    icon: 'fas fa-beer',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/editores/list/',
    title: 'Editores',
    icon: 'fas fa-users',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
];

export const ROUTES_4: RouteInfo[] = [
  {
    path: '/stats/list',
    title: 'Estadisticas',
    icon: 'fas fa-tags',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  }, {
    path: '/customers/me',
    title: 'Mi empresa',
    icon: 'fas fa-tags',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/locals/me',
    title: 'Mis Locales',
    icon: 'fas fa-map-marker-alt',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/cartadigital/list',
    title: 'Carta digital',
    icon: 'mdi mdi-help',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/mypromos/list/0',
    title: 'Promociones APP',
    icon: 'fas fa-percent',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/mypromos/list/1',
    title: 'Promociones QR',
    icon: 'fas fa-qrcode',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/mypromos/list/2',
    title: 'Promociones Push',
    icon: 'fas fa-percent',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/mypromos/list/3',
    title: 'Promociones NFC',
    icon: 'fas fa-percent',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
];

export const ROUTES_5: RouteInfo[] = [
  {
    path: '/routes/list/',
    title: 'Rutas de la tapa',
    icon: 'fas fa-beer',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  }
];
