import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/pagination';
import { LocalSubzonaRequest } from '../interfaces/localSubzonaRequest.model';
import { LocalSubzona } from '../models/localSubzona';
import * as global from './global.service';
import { objsToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class LocalSubzonaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(localSubzonaRequest: LocalSubzonaRequest) {
    
    let httpParams: HttpParams = new HttpParams();

    if (localSubzonaRequest.getZonaId()) {
      httpParams = httpParams.set('zonaId', String(localSubzonaRequest.getZonaId()));
    }

    if (localSubzonaRequest.getZonas()) {
      httpParams = httpParams.set('zonas', String(localSubzonaRequest.getZonas()));
    }

    if (localSubzonaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(localSubzonaRequest.getPerPage()));
    }

    if (localSubzonaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(localSubzonaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/localssubzone`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(localSubzona: LocalSubzona) {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<LocalSubzona>(global.API_URL + '/api/localssubzone', localSubzona, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(localSubzona: LocalSubzona) {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<LocalSubzona>(global.API_URL + '/api/localssubzone/' + localSubzona.id, localSubzona, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(localSubzona: LocalSubzona) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/localssubzone/' + localSubzona.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
