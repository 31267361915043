import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as global from './global.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { Version } from '../models/version';
import { VersionRequest } from '../interfaces/versionRequest.model';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public data = {
    version: '2.3.4',
    versionNumber: 20304,
    created_at : '2023-04-04'
  };

  private httpOptions: object;
  private httpHeaders: HttpHeaders;


  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }
  public index(versionRequest?: VersionRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (versionRequest && versionRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(versionRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/versions`, this.httpOptions).pipe(
      map((response: any) => {

        response.data = objsToCamelCase<Version>(response.data);

        return response;
      })
    );
  }

  public create(version: Version) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Version>(global.API_URL + '/api/versions', version, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(version: Version) {
    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<Version>(global.API_URL + '/api/versions/' + version.id, version, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(version: Version) {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/versions/' + version.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public itemShow() {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.get<any>(global.API_URL + '/api/versions/lastitem', this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public needUpdate(){

    const formData: FormData = new FormData();

    formData.append("version_panel", String(this.data.versionNumber));

    this.httpOptions = { headers: this.httpHeaders };

    return this.http.post(global.API_URL + '/api/configuration/version-panel', formData);
  }

}
