import {camelCase, map, isObject, chain, isArray} from 'lodash';

export const objToCamelCase = <T>(obj: any): T => {
  return chain(obj)
    .mapKeys((value, key) => camelCase(key))
    .mapValues((value) => {
      if (isArray(value))
        return objsToCamelCase(value);
      else if (isObject(value))
        return objToCamelCase(value);
      else
        return value;
    })
    .value();
};

export const objsToCamelCase = <T>(objs: any[]): T[] => {
  if (isArray(objs))
    return map(objs, (obj) => objToCamelCase(obj));
  else if (isObject(objs))
    return objToCamelCase(objs);
  else
    return objs;
};

export const verifyLanguageFields = <T>(languages: any[], mandatoryFields: string[], allLanguageFields: string[]): T[] => {
  const faultLanguages: any = [];
  for (let i = 1; i < languages.length; i++) {
      let someFieldFilled = false;
      const currentLang = languages[i];
      for (const field of allLanguageFields) {
        if (currentLang.hasOwnProperty(field) && currentLang[field] !== null && currentLang[field] !== undefined && currentLang[field] !== '') {
            someFieldFilled = true;
            break;
        }
      }
      if(someFieldFilled){
        for (const field of mandatoryFields) {
          if (!currentLang.hasOwnProperty(field) || currentLang[field] === null || currentLang[field] === undefined || currentLang[field] === '') {
            
            faultLanguages.push(currentLang.idioma);
            break;
          }
        }
      }
  }

  return faultLanguages;
}

export const getLanguagesFilled = <T>(languages: any[], mandatoryFields: string[]): T[] => {
  const languagesFilled: any = [];
  languages.forEach((language: any) => {
    if (language[mandatoryFields[0]] !== null && language[mandatoryFields[0]] !== undefined && 
      language[mandatoryFields[0]] !== '') {
      languagesFilled.push(language);
    } else {
      if(language['id']){
        language.delete = 1;
        languagesFilled.push(language);
      }
    }
  });

  return languagesFilled;
}
