import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { Observable } from 'rxjs';
import { VersionService } from '../services/version.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class VersionGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private version: VersionService,
    private notification : NotificationService){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    /*this.version.needUpdate()
      .subscribe( (d:any) => {
        if ( d.hasOwnProperty('error') && d.error === 1){
          this.notification.versionCheck();
        }
      });*/
    
    return true;
  }
  
}