import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as global from '../../../services/global.service';
import { LocalSubzonaService } from '../../../services/localSubzona.service';
import { LocalSubzona } from '../../../models/localSubzona';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-espaces-subzones',
  templateUrl: './espaces-subzones.component.html',
  styleUrls: ['./espaces-subzones.component.css']
})
export class EspacesSubzonesComponent implements OnInit {

  @Input()
  public subzone: LocalSubzona;
  public form: FormGroup;
  public submited: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private notification: NotificationService,
    private localSubzonaService: LocalSubzonaService) {

    this.submited = false;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [this.subzone.id ? this.subzone.id : null],
      nombre: [this.subzone.nombre, Validators.required],
      descripcion: [this.subzone.descripcion],
      cover: [null],
      coverUrl: [this.subzone.cover ? global.API_URL + this.subzone.cover : null],
      localId: [this.subzone.localId, Validators.required],
      zonaId: [this.subzone.zonaId, Validators.required],
    });

  }

  public preview(event: any): void {

    const files = event.target.files;

    if (files.length === 0) {
      return;
    }

    if (files[0].size > 2000000) {
      this.notification.error('No pueden subirse archivos de mayor tamaño a 2 mb.');
      return;
    }

    if (files[0].type.match(/image\/*/) == null) {
      this.notification.error('Solo se permiten subir imagenes.');
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {

      this.form.patchValue({ 'cover': reader.result });
    };

  }

  public save(): void {

    this.submited = true;

    if (this.subzone.id) {

      this.localSubzonaService.update(this.form.value).subscribe(
        (localSubZona: any) => {

          this.submited = false;
          this.activeModal.close(localSubZona);

          this.notification.success('Subzona editada correctamente');

        },
        (error: HttpErrorResponse) => {

        });

    } else {

      this.localSubzonaService.create(this.form.value).subscribe(
        (localSubZona: any) => {

          this.submited = false;
          this.activeModal.close(localSubZona);

          this.notification.success('Subzona creada correctamente');
        },
        (error: HttpErrorResponse) => {

        });
    }
  }


}
