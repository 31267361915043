import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { ComunidadAutonomaRequest } from '../interfaces/comunidadAutonomaRequest.model';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class ComunidadAutonomaService {

  private static COMUNIDADES_PATH = global.API_URL + '/api/comunidades';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(comunidadAutonomaRequest: ComunidadAutonomaRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (comunidadAutonomaRequest.getComunidadAutonomaId()) {
      httpParams = httpParams.set('comunidadautonomaId', String(comunidadAutonomaRequest.getComunidadAutonomaId()));
    }

    if (comunidadAutonomaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(comunidadAutonomaRequest.getPerPage()));
    }

    if (comunidadAutonomaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(comunidadAutonomaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(ComunidadAutonomaService.COMUNIDADES_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

}
