import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as global from "../services/global.service";
import { objToCamelCase } from "../helpers/object.helper";
import { Events } from "./events.service";
import { Authentication } from "../models/authentication";
import { Administracion } from "../models/administracion";
import { Cliente } from "../models/cliente";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Authentication>;
  private currentCustomerSubject: BehaviorSubject<Cliente>;
  private currentAdminSubject: BehaviorSubject<Administracion>;
  public currentUser: Observable<Authentication>;
  public currentAdmin: Observable<Administracion>;
  public currentCustomer: Observable<Cliente>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private event: Events
  ) {
    this.currentUserSubject = new BehaviorSubject<Authentication>(
      JSON.parse(localStorage.getItem("currentUserunBlockAdmin"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentCustomerSubject = new BehaviorSubject<Cliente>(
      JSON.parse(localStorage.getItem("currentCustomerunBlockAdmin"))
    );
    this.currentCustomer = this.currentCustomerSubject.asObservable();
    this.currentAdminSubject = new BehaviorSubject<Administracion>(
      JSON.parse(localStorage.getItem("currentAdminunBlockAdmin"))
    );
    this.currentAdmin = this.currentAdminSubject.asObservable();
  }

  public isAuthenticated(val: string): boolean {
    if (val !== "" && val != null) {
      if (val.split(".").length === 3) {
        try {
          const base64Url = val.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const exp = JSON.parse(window.atob(base64)).exp;
          // JWT with an optonal expiration claims
          if (exp) {
            const isExpired = Math.round(new Date().getTime() / 1000) >= exp;
            if (isExpired) {
              // FAIL: Expired token
              return false;
            } else {
              // PASS: Non-expired token
              return true;
            }
          }
        } catch (e) {
          // PASS: Non-JWT token that looks like JWT
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public get currentUserValue(): Authentication {
    return this.currentUserSubject.value;
  }

  public get currentCustomerValue(): Cliente {
    return this.currentCustomerSubject.value;
  }

  public get currentAdminValue(): Administracion {
    return this.currentAdminSubject.value;
  }

  public login(email: string, password: string): Observable<void> {
    return this.http
      .post<any>(global.API_URL + "/api/auth/backlogin", { email, password })
      .pipe(
        map((response: any) => {
          let authentication: Authentication =
            objToCamelCase<Authentication>(response);

          // login successful if there's a jwt token in the response
          if (authentication.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (!authentication.user.changePassword) {
              localStorage.setItem(
                "currentUserunBlockAdmin",
                JSON.stringify(authentication)
              );
              this.currentUserSubject.next(authentication);
            } else {
              this.currentUserSubject.next(authentication);
            }

            if (authentication.user.cliente) {
              localStorage.setItem(
                "currentCustomerunBlockAdmin",
                JSON.stringify(authentication.user.cliente)
              );
              this.currentCustomerSubject.next(authentication.user.cliente);
              this.event.publish("checkuser", {});
            }

            if (authentication.user.administracion) {
              localStorage.setItem(
                "currentAdminunBlockAdmin",
                JSON.stringify(authentication.user.administracion)
              );
              this.currentAdminSubject.next(authentication.user.administracion);
            }
          }
        })
      );
  }

  public updateUser(user: Authentication): void {
    this.currentUserSubject.next(user);
    localStorage.setItem("currentUserunBlockAdmin", JSON.stringify(user));
  }

  public updateCustomer(customer: Cliente): void {
    this.currentCustomerSubject.next(customer);
    localStorage.setItem("currentCustomerunBlockAdmin", JSON.stringify(customer));
  }

  public updateAdmin(admin: Administracion) {
    this.currentAdminSubject.next(admin);
    localStorage.setItem("currentAdminunBlockAdmin", JSON.stringify(admin));
  }

  public logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUserunBlockAdmin");
    localStorage.removeItem("currentCustomerunBlockAdmin");
    localStorage.removeItem("currentAdminunBlockAdmin");
    localStorage.removeItem("wizard");
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }
}
