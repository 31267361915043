import * as ClassicEditorBuild from "@ckeditor/ckeditor5-build-classic";
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from "../../../services/authentication.service";
import { NotificationService } from "../../../services/notification.service";
import { TarjetaSponsor } from "../../tarjetas/models/tarjetaSponsor";
import { OptionProductService } from "../../../services/option-product.service";
import { OptionProduct } from "../../../models/OptionProduct";
import { TranslationService } from "../../../services/translation.service";
import { IdiomaRequest } from "../../../interfaces/idiomaRequest.model";
import { IdiomaService } from "../../../services/idioma.service";
import { Idioma } from "../../../models/idioma";
import { getLanguagesFilled } from "../../../helpers/object.helper";

enum Fields {
  nombre = 1,
}

@Component({
  selector: 'app-option-product',
  templateUrl: './option-product.component.html',
  styleUrls: ['./option-product.component.css']
})
export class OptionProductComponent implements OnInit {

  @Input() public producto: any;

  @Input() public option;

  public form: FormGroup;
  public submitted = false;
  public codeError: boolean = true;
  public step: number = 1;
  public lastStep: number = 1;
  public finalStep: number = 1;
  public mandatoryLangFields = ['nombre'];
  public langFields = ['nombre'];

  public configEditor: object = {
    placeholder: "Descripción",
  };

  public classicEditorBuild: ClassicEditorBuild = ClassicEditorBuild;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private optionProductService: OptionProductService,
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private googleTranslate: TranslationService,
    private idiomaService: IdiomaService
  ) {}

  public ngOnInit() {
    this.buildForm();
  }

  
  public get showContinueBtn(): boolean {
    return !this.option &&
           this.step > 0 &&
           this.step < this.finalStep;
  }

  public get showCreateBtn(): boolean {
    return !this.option &&
           this.step === this.finalStep;
  }

  public get isImageValid(): boolean {
    return !(this.submitted && this.form.controls.imagen.errors);
  }

  public showStep(step: number): void {
    this.submitted = false;

    switch (step) {
      case 2:
        this.doStepEnterSponsorInfo(step);
        break;

      default:
        this.updateStep( step);
    }
  }

  public showNextStep() {
    this.showStep( this.step + 1);
  }

  public onImageChange( imageData: string) {
    this.form.patchValue({ 'imagen': imageData });
  }

  public saveForm(): void {
    if (!this.isValidForm()) {
      this.notification.error( 'Revisa todos los pasos y rellena los campos obligatorios (*).');

      return;
    }

    this.spinner.show();

    const option = this.form.value;
    this.save( option);
  }

  public closeModal( data?: any) {
    this.activeModal.close(data);
  }

  private buildForm() {

    let idParam = null;
    let nombreParam = '';
    let precioParam = null;
    let cartaProductoParam = this.producto.id;

    if ( this.option !== undefined){

      if( this.option.hasOwnProperty('id') ){
        idParam = this.option.id;
      }

      if( this.option.hasOwnProperty('nombre') ){
        // nombreParam = this.option.nombre;
      }

      if( this.option.hasOwnProperty('cartaproductoId') ){
        cartaProductoParam = this.option.cartaproductoId;
      }

      if( this.option.hasOwnProperty('precio') ){
        precioParam = this.option.precio;
      }
    }
    this.form = this.formBuilder.group({
      id: [idParam],
      nombre: [nombreParam],
      precio: [precioParam],
      cartaproductoId: [cartaProductoParam],
      idiomas: this.formBuilder.array([])
    });

    this.getIdiomas();
  }

  private getIdiomas(){
    const idiomaRequest: IdiomaRequest = new IdiomaRequest();
    idiomaRequest.setOrderBy("ASC");

    if( this.option ){

      this.idiomaService
      .index(idiomaRequest)
      .toPromise()
      .then(
        (pagination: any) => {

          pagination.data.map((element: Idioma) => {

            const traduccion = this.option.idiomas.filter(
              (trad) => {
                return trad.idiomaId === element.id;
              }
            );

            (this.form.get("idiomas") as FormArray).push(
              new FormGroup({
                id: new FormControl(
                  traduccion.length > 0 ? traduccion[0].id : null
                ),
                codigo: new FormControl(element.codigo),
                idiomaId: new FormControl(element.id),
                idioma: new FormControl(element.nombre),
                nombre: new FormControl(
                  traduccion.length > 0 ? traduccion[0].nombre : null
                ),
              })
            );
          });

        },
        (error) => this.notification.logger(error)
      );
    }else {

      this.idiomaService
      .index(idiomaRequest)
      .toPromise()
      .then(
        (pagination: any) => {
          pagination.data.map((element: Idioma) => {
            (this.form.get("idiomas") as FormArray).push(
              new FormGroup({
                idiomaId: new FormControl(element.id),
                codigo: new FormControl(element.codigo),
                categoriaId: new FormControl(null),
                idioma: new FormControl(element.nombre),
                nombre: new FormControl(null),
                subtitulo: new FormControl(null),
              })
            );
          });
        },
        (error) => this.notification.logger(error)
      );

    }

  }

  private save( option: OptionProduct) {

    const idiomas: any = getLanguagesFilled(option.idiomas, this.mandatoryLangFields);
    option.idiomas = idiomas;

    if (this.form.value.id) {
      this.updateOption( option);
    } else {
      this.createOption( option);
    }

    this.submitted = true;
  }

  private createOption( option: OptionProduct) {
    this.optionProductService.create( option)
        .toPromise()
        .then(
          (data) => {
            this.notification.success( 'La opción se ha creado correctamente.');
            this.closeModal( data);
          },

          (error) => this.notification.error( `Ocurrió un error al guardar la opción: ${error}`)
        );
  }

  private updateOption( option: OptionProduct) {

    this.optionProductService.update( option)
        .toPromise()
        .then(
          (data) => {
            console.log(this.form.value);
            console.log(data);

            this.notification.success( 'Los datos de la opción se han actualizado correctamente.');
            this.closeModal( data);
          },

          (error) => this.notification.error( `Error al actualizar la opción: ${error}`)
        );
  }

  private doStepEnterSponsorInfo(step: number) {
    if (this.mandatoryInfoFieldsAreValid()) {
      this.updateStep(step);
    } else {
      this.showMandatoryFieldsError();
    }
  }

  private updateStep(step: number) {
    this.step = step;

    if (step > this.lastStep) {
      this.lastStep = step;
    }
  }

  private isValidForm(): boolean {
    return this.form.valid && this.allMandatoryFieldsAreValid();
  }

  private allMandatoryFieldsAreValid(): boolean {
    return this.mandatoryInfoFieldsAreValid()
  }

  private mandatoryInfoFieldsAreValid(): boolean {
    return !!this.form.value.idiomas[0].nombre && !!this.form.value.precio;
  }

  private refreshOption(option) {
    this.optionProductService.show(option)
      .toPromise()
      .then(
        (data: any) => {
          this.showStep( 1);
        },

        (error) => this.notification.error( `Error al cargar datos de la opción: ${error}`)
      );
  }

  private showMandatoryFieldsError() {
    this.notification.error( 'Los campos marcados con * son obligatorios');
  }

  public get languageFormFields(): FormArray {
    return this.form.get('idiomas') as FormArray;
  }

  public get translatedFormControls(): any[] {
    return this.languageFormFields['controls'];
  }

  public doTranslate(field, target, index) {

    const idiomas = this.form.get('idiomas').value;
    this.spinner.show();

    const obj: any = {};
    obj.source = this.form.value.idiomas[0].codigo;
    obj.target = target;

    if (field === Fields.nombre) {

      if (this.form.value.idiomas[0].nombre) {
        obj.text = this.form.value.idiomas[0].nombre;
      } else {
        this.notification.error(
          'Debes tener datos en el campo para poder traducir'
        );
        return;
      }
    }

    this.googleTranslate
      .translate(obj)
      .toPromise()
      .then(
        (res: any) => {
          if (field === Fields.nombre) {
            idiomas[index].nombre = res.text;
          }

          (this.form.get('idiomas') as FormArray).patchValue(idiomas);
          this.spinner.hide();
        },
        (error) => this.spinner.hide()
      );
  }


}
