export class PaginationRequest {

    private page: number;
    private perPage: number;
    private orderBy: string;
    private sortBy: string;
    private createdAtStart: string;
    private createdAtEnd: string;
    private id: number;
    private idiomas: boolean;

    public constructor() {
        this.page = 1;
    }

    /**
     * getPage
     * @returns number
     */
    public getPage(): number {
        return this.page;
    }

    /**
     * setPage
     */
    public setPage(page: number): void {
        this.page = page;
    }

    /**
     * getPerPage
     */
    public getPerPage(): number {
        return this.perPage;
    }

    /**
     * setPerPage
     */
    public setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    /**
     * getOrderBy
     */
    public getOrderBy(): string {
        return this.orderBy;
    }

    /**
     * setOrderBy
     */
    public setOrderBy(orderBy: string): void {
        this.orderBy = orderBy;
    }

    /**
     *
     */
    public getSortBy(): string {
        return this.sortBy;
    }

    /**
     *
     * @param sortBy
     */
    public setSortBy(sortBy: string): void {
        this.sortBy = sortBy;
    }

    /**
     * getCreatedAtStart
     */
    public getCreatedAtStart(): string {
        return this.createdAtStart;
    }

    /**
     * setCreatedAtStart
     */
    public setCreatedAtStart(createdAtStart: string): void {
        this.createdAtStart = createdAtStart;
    }

    /**
     * getCreatedAtEnd
     */
    public getCreatedAtEnd(): string {
        return this.createdAtEnd;
    }

    /**
     * setCreatedAtEnd
     */
    public setCreatedAtEnd(createdAtEnd: string): void {

        this.createdAtEnd = createdAtEnd;
    }

    /**
     * getId
     */
    public getId(): number {
        return this.id;
    }

    /**
     * setStActivo
     */
    public setId(id: number): void {
        this.id = id;
    }

    /**
     * getIdiomas
     */
     public getIdiomas(): boolean {
        return this.idiomas;
    }

    /**
     * setIdiomas
     */
    public setIdiomas(value: boolean): void {
        this.idiomas = value;
    }

}
