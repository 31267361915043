import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalesAgentService } from '../../../services/salesAgent.service';
import { SalesAgentRequest } from '../../customers/models/salesAgentRequest.model';
import { SalesAgent } from '../../../models/salesAgent';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-sales-agent',
  templateUrl: './sales-agent.component.html',
  styleUrls: ['./sales-agent.component.css']
})


export class SalesAgentComponent implements OnInit {

  @Input() public search: string;
  public salesAgents: Array<SalesAgent>;
  public selectedSalesAgent: any;  

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private salesAgentService: SalesAgentService) {

  }

  ngOnInit() {
    this.searchSalesAgent();
  }

  selectCommercial(salesAgent) {
      this.selectedSalesAgent = salesAgent;
      for (let i = 0; i < this.salesAgents.length; i++) {
        if (this.salesAgents[i].id === this.selectedSalesAgent.id) {
          this.salesAgents[i].selected = true;
        } else {
          this.salesAgents[i].selected = false;
        }
      }
  }


  public searchSalesAgent(): void {
    this.spinner.show();
    const salesAgentRequest: SalesAgentRequest = new SalesAgentRequest();

    if (this.search !== '') {
      salesAgentRequest.setName(this.search);
    }

    this.salesAgentService.search(salesAgentRequest).toPromise().then(
      (pagination: any) => {
        if (pagination) {
          this.salesAgents = pagination;
        }
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
      
  }

  public selected(): void {
    if (this.selectedSalesAgent) {
      const obj: any = {};
      obj.salesAgent = this.selectedSalesAgent;
      this.activeModal.close(obj);
    } else {
      this.notification.error('No has seleccionado ningun comercial');
    }
  }

}
