import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdministracionCategoria } from '../../../models/administracion-categoria';
import { AdministracionCategoriaService } from '../../../services/administracionCategoria.service';
import { IdiomaRequest } from '../../../interfaces/idiomaRequest.model';
import { IdiomaService } from '../../../services/idioma.service';
import { Idioma } from '../../../models/idioma';
import { NotificationService } from '../../../services/notification.service';


@Component({
  selector: 'app-espaces-categories',
  templateUrl: './espaces-categories.component.html',
  styleUrls: ['./espaces-categories.component.css']
})
export class EspacesCategoriesComponent implements OnInit {

  @Input()
  public category: AdministracionCategoria;
  public form: FormGroup;
  public submited: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private idiomaService: IdiomaService,
    private notification: NotificationService,
    private admonService: AdministracionCategoriaService) {

    this.submited = false;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      nombre: [null],
      AdministracionId: [null, Validators.required],
      idiomas: this.formBuilder.array([])
    });
    const idiomaRequest: IdiomaRequest = new IdiomaRequest();
    idiomaRequest.setOrderBy('ASC');

    if (this.category) {
      this.form.patchValue(this.category);
      this.idiomaService.index(idiomaRequest).toPromise().then(
        (pagination: any) => {
          pagination.data.map((element: Idioma) => {
            const traduccion = this.category.idiomas.filter((trad) => trad.idiomaId === element.id);
            (this.form.get('idiomas') as FormArray).push(
              new FormGroup({
                id: new FormControl(traduccion.length > 0 ? traduccion[0].id : null),
                idiomaId: new FormControl(element.id),
                codigo: new FormControl(element.codigo),
                idioma: new FormControl(element.nombre),
                nombre: new FormControl(traduccion.length > 0 ? traduccion[0].nombre : null )
              }));
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        });
    } else {
      this.idiomaService.index(idiomaRequest).toPromise().then(
        (pagination: any) => {
          pagination.data.map((element: Idioma) => {
            (this.form.get('idiomas') as FormArray).push(
              new FormGroup({
                id: new FormControl(null),
                idiomaId: new FormControl(element.id),
                codigo: new FormControl(element.codigo),
                idioma: new FormControl(element.nombre),
                nombre: new FormControl(null)
              }));
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        });
    }
  }

  public save(): void {
    this.submited = true;
    this.form.value.nombre = this.form.value.idiomas[0].nombre;
    if (this.category.id) {
      this.admonService.update(this.form.value).toPromise().then(
        (category: any) => {

          this.submited = false;
          this.activeModal.close(category);

          this.notification.success('Sección actualizada correctamente');
        },
        (error: HttpErrorResponse) => {
          this.submited = false;
        });
    } else {
      this.admonService.create(this.form.value).toPromise().then(
        (category: any) => {
          this.submited = false;
          this.activeModal.close(category);
          this.notification.success('Sección creada correctamente');
        },
        (error: HttpErrorResponse) => {
          this.submited = false;
        });
    }
  }

}
