import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import toastr from 'toastr';
import { User } from '../../../models/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { Local } from '../../../models/local';
import { LocalService } from '../../../services/local.service';
import { LocalRequest } from '../../../interfaces/localRequest.model';
import { NotificationService } from '../../../services/notification.service';
import { Role } from '../../utils/role';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-locals-available',
  templateUrl: './locals-available.component.html',
  styleUrls: ['./locals-available.component.css']
})
export class LocalsAvailableComponent implements OnInit {
  @Input()
  public localName: string;

  @Input()
  public title: string;

  @Input()
  public online: number;

  @Input()
  public clienteId: number;

  @Input()
  public rutaId: number;

  public form: FormGroup;
  public locales: Array<Local>;

  private user: User;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private localService: LocalService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.user = this.auth.currentUserValue.user;
    this.form = this.buildForm();

    const localRequest: LocalRequest = new LocalRequest();
    this.spinner.show();

    if (this.user.rolId === Role.ADMINISTRATOR) {
      this.localService.index(localRequest).subscribe(
        ({ data }: any) => {
          this.locales = data;
          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          toastr.error(null, 'No se ha podido buscar los espacios', { positionClass: 'toast-top-center' });
        });
    } else if (this.user.rolId === Role.GROUP_ADMIN) {
      localRequest.setRutaId(this.rutaId);
      localRequest.setActivo(true);

      this.localService.index(localRequest).subscribe(
        ({ data }: any) => {
          this.locales = data;
          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          toastr.error(null, 'No se ha podido buscar los espacios', { positionClass: 'toast-top-center' });
        });
    } else {
      localRequest.setUserId(this.user.id);

      this.localService.index(localRequest).subscribe(
        ({ data }: any) => {
          this.locales = data;
          this.spinner.hide();

          this.locales.filter( (item, i) => {
            if (item.nombre === this.localName) {
              this.locales.splice(i, 1);
            }
          });
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          toastr.error(null, 'No se ha podido buscar los espacios', { positionClass: 'toast-top-center' });
        });
    }
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      localId: [null, Validators.required],
      clienteId: [null, Validators.required]
    });
  }

  public searchLocalChange(search: HTMLInputElement) {
    const localRequest: LocalRequest = new LocalRequest();
    this.spinner.show();

    localRequest.setNombre(search.value);
    if (this.user.rolId !== Role.ADMINISTRATOR){
      localRequest.setClienteId(this.clienteId);
    }

    this.localService.index(localRequest).subscribe(
      ({ data }: any) => {
        this.locales = data;
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        toastr.error(null, 'No se ha podido buscar los locales', { positionClass: 'toast-top-center' });
      });
  }

  public selectLocal( local: Local) {
    this.form.get('localId').setValue( local.id);
    this.form.get('clienteId').setValue( local.clienteId);
  }

  public selected() {
    if (this.form.valid) {
      this.activeModal.close( this.form.value);
    }
  }

  public closeModal() {
    this.activeModal.dismiss();
  }
}
