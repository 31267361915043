import { PaginationRequest } from "./paginationRequest.model";

export class PromoRequest extends PaginationRequest {
  private userId: number;
  private clienteId: number;
  private administracionId: number;
  private cliente: boolean;
  private administracion: boolean;
  private comunidadAutonoma: boolean;
  private ciudad: boolean;
  private preferencias: boolean;
  private local: boolean;
  private localCiudad: boolean;
  private type: number;
  private photos: boolean;
  private activa: boolean;

  constructor() {
    super();
  }

  /**
   *
   * @returns number
   */
  public getUserId(): number {
    return this.userId;
  }

  /**
   *
   */
  public setUserId(userId: any): void {
    this.userId = userId;
  }

  /**
   *
   */
  public getClienteId(): number {
    return this.clienteId;
  }

  /**
   *
   */
  public setClienteId(clienteId: number): void {
    this.clienteId = clienteId;
  }

  /**
   *
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   *
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

  /**
   *
   */
  public getCliente(): boolean {
    return this.cliente;
  }

  /**
   *
   */
  public setCliente(cliente: boolean): void {
    this.cliente = cliente;
  }

  /**
   *
   */
  public getAdministracion(): boolean {
    return this.administracion;
  }

  /**
   *
   */
  public setAdministracion(administracion: boolean): void {
    this.administracion = administracion;
  }

  /**
   *
   */
  public getComunidadAutonoma(): boolean {
    return this.comunidadAutonoma;
  }

  /**
   *
   */
  public setComunidadAutonoma(comunidadAutonoma: boolean): void {
    this.comunidadAutonoma = comunidadAutonoma;
  }

  /**
   *
   */
  public getCiudad(): boolean {
    return this.ciudad;
  }

  /**
   *
   */
  public setCiudad(ciudad: boolean): void {
    this.ciudad = ciudad;
  }

  /**
   *
   */
  public getPreferencias(): boolean {
    return this.preferencias;
  }

  /**
   *
   */
  public setPreferencias(preferencias: boolean): void {
    this.preferencias = preferencias;
  }

  /**
   *
   */
  public getLocal(): boolean {
    return this.local;
  }

  /**
   *
   */
  public setLocal(local: boolean): void {
    this.local = local;
  }

  /**
   *
   */
  public getLocalCiudad(): boolean {
    return this.localCiudad;
  }

  /**
   *
   */
  public setLocalCiudad(localCiudad: boolean): void {
    this.localCiudad = localCiudad;
  }

  /**
   *
   * @returns
   */
  public getType(): number {
    return this.type;
  }

  /**
   *
   */
  public setType(type: number): void {
    this.type = type;
  }

  /**
   *
   * @return boolean
   */
  public getPhotos(): boolean {
    return this.photos;
  }

  /**
   *
   * @param photos
   */
  public setPhotos(photos: boolean): void {
    this.photos = photos;
  }

  public getActiva(): boolean {
    return this.activa;
  }

  public setActiva(activa: boolean): void {
    this.activa = activa;
  }
}
