import * as global from '../../../services/global.service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { objsToCamelCase } from '../../../helpers/object.helper';
import { TarjetaRequest } from '../interfaces/tarjetaRequest.model';

export abstract class AbstractTarjetaService {
  protected httpOptions: object;
  protected httpHeaders: HttpHeaders;

  constructor(protected http: HttpClient, protected apiPath: string) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  protected buildParams( request: TarjetaRequest): HttpParams {
    let result = new HttpParams();

    const paramNames = [
      'perPage',
      'orderBy',
      'idiomas',
      'userId',
      'clienteId',
      'promos',
      'productos',
      'patrocinadores',
      'invitations'
    ];

    paramNames.forEach( (param: string|string[]) => {
      let getterName: string;
      let realParam: string;

      if (typeof param === 'string') {
        getterName = param;
        realParam = param;
      } else {
        getterName = param[0];
        realParam = param[1];
      }

      const getter = this.buildGetter( getterName);
      const value = request[getter]();

      if (value) {
        result = result.set( realParam, String( value));
      }
    });

    return result;
  }

  protected buildOptions( httpParams?: HttpParams|Object, excludedHeader?: string): object {
    let headers = this.httpHeaders;

    if (excludedHeader) {
      headers = headers.delete( excludedHeader);
    }

    return {
      headers,
      params: httpParams || {}
    };
  }

  protected byIdUrl( id: number): string {
    return `${this.baseUrl()}/${id}`;
  }

  protected indexUrl(): string {
    return this.baseUrl();
  }

  protected postUrl(): string {
    return this.baseUrl();
  }

  protected putUrl( id: number): string {
    return `${this.baseUrl()}/${id}`;
  }

  protected deleteUrl( id: number): string {
    return `${this.baseUrl()}/${id}`;
  }

  protected baseUrl(): string {
    return `${global.API_URL}/api/${this.apiPath}`;
  }

  protected processResponse( request): Promise<any> {
    return request.pipe(
        map( (response: any) => objsToCamelCase( response))
      )
      .toPromise();
  }

  private buildGetter( name: string): string {
    return 'get' + name.charAt( 0).toUpperCase() + name.substring( 1);
  }

  private getEffectiveParam( param: string|object): string {
    if (typeof param === 'string') {
      return param;
    } else {
      return
    }
  }
}
