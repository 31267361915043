import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';
import { ConfirmaInvitacionComponent } from './confirma-invitacion/confirma-invitacion.component';
import { VersionGuard } from './guards/version.guard';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'stats/list',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        redirectTo: 'stats/list',
        pathMatch: 'full'
      },
      {
        path: 'configurations',
        loadChildren: './v2/configuraciones/configuraciones.module#ConfiguracionesModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'espaces',
        loadChildren: './v2/espaces/espaces.module#EspacesModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'eventos',
        loadChildren: './v2/events/events.module#EventosModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'notificaciones',
        loadChildren: './v2/notifications/notifications.module#NotificationsModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'stats',
        loadChildren: './stats/stats.module#StatsModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'masters',
        loadChildren: './v2/masters/masters.module#MastersModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'promotions',
        loadChildren: './v2/promotions/promotions.module#PromotionsModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'routes',
        loadChildren: './v2/rutas/rutas.module#RutasModule'
      },
      {
        path: 'tarjetas',
        loadChildren: './v2/tarjetas/tarjetas.module#TarjetasModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'pedidos',
        loadChildren: './v2/pedidos/pedidos.module#PedidosModule',
      },
      {
        path: 'digitalmenu',
        loadChildren: './v2/digitalmenu/digitalmenu.module#DigitalMenuModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'controlrequest',
        loadChildren: './v2/controlrequest/controlrequest.module#ControlRequestModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'users',
        loadChildren: './v2/users/users.module#UsuariosModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'destination',
        loadChildren: './v2/destination/destination.module#DestinationModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'helpcenter',
        loadChildren: './v2/helpCenter/helpcenter.module#HelpCenterModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'campaigns',
        loadChildren: './campaign/campaign.module#CampaignModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'onboarding',
        loadChildren: './v2/onboarding/onboarding.module#OnboardingModule'
      },
      {
        path: 'saldos',
        loadChildren: './v2/saldos/saldos.module#SaldosModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'administrations',
        loadChildren: './administrations/administrations.module#AdministrationsModule',
        canActivate: [ VersionGuard ]
      },
      {
        path: 'gestor-errores',
        loadChildren: './gestorerrores/gestorerrores.module#GestorErroresModule',
        canActivate: [ VersionGuard ]
      },
    ]
  },
  {
    path: 'login',
    loadChildren: './v2/entry/entry.module#EntryModule',
  },
  {
    path: 'forgot',
    loadChildren: './v2/forgot/forgot.module#ForgotModule',
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    loadChildren: './v2/customers/customers.module#CustomersModule'
  },
  {
    path: 'confirmacion-evento/:id',
    component: ConfirmaInvitacionComponent,
  },
  {
    path: 'plan',
    loadChildren: './v2/plan/plan.module#PlanModule',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule { }
