import * as global from './global.service';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { objsToCamelCase, objToCamelCase } from '../helpers/object.helper';
import { Pagination } from '../models/pagination';
import { Cliente } from '../models/cliente';
import { CustomersRequest } from '../v2/customers/models/customersRequest.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private static CUSTOMERS_PATH = global.API_URL + '/api/customers';

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public show(id: number): Observable<Cliente> {

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<Cliente>(CustomerService.CUSTOMERS_PATH + `/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Cliente>(response);
      })
    );
  }

  public index(customersRequest: CustomersRequest): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    if (customersRequest.getUserId())
      httpParams = httpParams.set('userId', String(customersRequest.getUserId()));

    if (customersRequest.getPerPage())
      httpParams = httpParams.set('perPage', String(customersRequest.getPerPage()));

    if (customersRequest.getOrderBy())
      httpParams = httpParams.set('orderBy', customersRequest.getOrderBy());

    if (customersRequest.getSortBy())
      httpParams = httpParams.set('sortBy', customersRequest.getSortBy());

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(CustomerService.CUSTOMERS_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public search(customersRequest: CustomersRequest): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    if (customersRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(customersRequest.getPerPage()));
    }

    if (customersRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(customersRequest.getOrderBy()));
    }

    if (customersRequest.getNombre()) {
      httpParams = httpParams.set('nombre', String(customersRequest.getNombre()));
    }

    if (customersRequest.getLocalNombre()) {
      httpParams = httpParams.set('localNombre', String(customersRequest.getLocalNombre()));
    }

    if (customersRequest.getUser()) {
      httpParams = httpParams.set('user', String(customersRequest.getUser()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(CustomerService.CUSTOMERS_PATH + `/search`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public byUserId(id: number): Observable<Cliente> {
    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<Cliente>(CustomerService.CUSTOMERS_PATH + `/user/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Cliente>(response);
      })
    );
  }

  public create(customer: Cliente): Observable<Cliente> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.post<Cliente>(CustomerService.CUSTOMERS_PATH, customer, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Cliente>(response);
      })
    );
  }

  public update(customer: Cliente): Observable<Cliente> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.put<Cliente>(CustomerService.CUSTOMERS_PATH + `/${customer.id}`, customer, this.httpOptions)
      .pipe(
        map((response: any) => objToCamelCase<Cliente>(response))
      );
  }

  public delete(customer: Cliente): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(CustomerService.CUSTOMERS_PATH + `/${customer.id}`, this.httpOptions)
      .pipe(
        map((response: any) => objsToCamelCase(response))
      );
  }

}
