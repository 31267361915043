import * as global from "../../../services/global.service";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../environments/environment";
import { EspacesSubzonesComponent } from "../espaces-subzones/espaces-subzones.component";
import { EspacesZonesComponent } from "../espaces-zones/espaces-zones.component";
import { EspacesComponent } from "../espaces/espaces.component";
import { PeticionesComponent } from "../peticiones/peticiones.component";
import { EspacesCustomersComponent } from "../espaces-customers/espaces-customers.component";
import { PhotosComponent } from "../../photos/photos.component";
import { EspacesQrComponent } from "../espaces-qr/espaces-qr.component";
import { EspacesEnlacesComponent } from "../espaces-enlaces/espaces-enlaces.component";
import { LocalService } from "../../../services/local.service";
import { LocalZonaService } from "../../../services/localZona.service";
import { LocalSubzonaService } from "../../../services/localSubzona.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { NotificationService } from "../../../services/notification.service";
import { IdiomaService } from "../../../services/idioma.service";
import { ModalManagerService } from "../../../services/modal-manager.service";
import { EspaceEnlaceService } from "../services/espaceEnlace.service";
import { PlanService } from "../../../services/plan.service";
import { BusinessEspacio } from "../../../models/business_espacio";
import { Local } from "../../../models/local";
import { LocalZona } from "../../../models/localZona";
import { LocalSubzona } from "../../../models/localSubzona";
import { Promo } from "../../../models/promo";
import { Idioma } from "../../../models/idioma";
import { IdiomaRequest } from "../../../interfaces/idiomaRequest.model";
import { LocalRequest } from "../../../interfaces/localRequest.model";
import { EspaceEnlace } from "../interfaces/espaceEnlace";
import { User } from "../../../models/user";
import { Plan } from "../../../models/plan";
import { PlanRequest } from "../../../models/plan-request.model";
import { Events } from "../../../services/events.service";
import { Slot } from "../../../models/slot";
import { SlotService } from "../../../services/slot.service";
import { CustomersPaymentsComponent } from "../../customers/customers-payments/customers-payments.component";
import { PlanBasicInfoComponent } from "../../planes/plan-basic-info/plan-basic-info.component";
import { PlanBuyLocalsAskComponent } from "../../planes/plan-buy-locals-ask/plan-buy-locals-ask.component";
import { PlanUpgradeComponent } from "../../planes/plan-upgrade/plan-upgrade.component";
import { HttpErrorResponse } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { Pagination } from "../../../models/pagination";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { ConvertSpaceToModalComponent } from "../../components/convert-space-to-modal/convert-space-to-modal.component";

@Component({
  selector: "app-espaces-list",
  templateUrl: "./espaces-list.component.html",
  styleUrls: ["./espaces-list.component.css"],
})
export class EspacesListComponent implements OnInit {
  private languages: Idioma[];
  private espaceEnlace: EspaceEnlace;

  public menuOpen = false;
  public modalText: string;
  public modalRef: NgbModalRef;
  public locals: Array<Local>;
  public local: Local;
  public localZona: LocalZona;
  public localSubzona: LocalSubzona;
  public selectedCustomer: any;
  public selectedAdmin: any;
  public searchterm: string;
  public searchlocal: string;
  public haszones = false;
  public apiUrl: string;
  public confirm_title = "";
  public confirm_text = "";
  public modal_eliminar_local = false;
  public modal_desactivar_local = false;
  public modal_activar_local = false;
  public modal_eliminar_enlace = false;
  public promos: Array<Promo>;
  public type: number;
  public isAdmin = false;
  public clienteid = false;
  public isDestination = false;
  public basicCustomer = true;
  public form: FormGroup;
  public formOrdenar: FormGroup;
  public isOrderAsc = true;
  public showFilterOrder = false;
  public espaciosMostrados: Array<Local>;
  public activa;
  public desactiva;
  public cantidadActivas = 0;
  public user: User;
  public planes: Plan[];

  public configModal: NgbModalOptions = {
    centered: true,
    windowClass: "newModal",
    backdrop: "static",
    keyboard: false,
  };

  public localsData: Pagination;

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private localService: LocalService,
    private auth: AuthenticationService,
    private currentRoute: ActivatedRoute,
    private idiomaService: IdiomaService,
    private localZonaService: LocalZonaService,
    private localSubzonaService: LocalSubzonaService,
    private espaceEnlaceService: EspaceEnlaceService,
    private notification: NotificationService,
    private modalManager: ModalManagerService,
    private formBuilder: FormBuilder,
    private planService: PlanService,
    private event: Events,
    private slotService: SlotService,
    private sanitized: DomSanitizer,
    private notificationService: NotificationService
  ) {
    this.locals = [];
    this.slotService.getSlots();
  }

  public ngOnInit() {
    this.event.subscribe("reviewSlots", () => {
      this.fillLocals();
      this.slotService.refresh();
      this.user = this.auth.currentUserValue.user;
    });
    this.user = this.auth.currentUserValue.user;
    this.form = this.formBuilder.group({
      nombre: [""],
      activo: [null],
      desactiva: [null],
    });

    this.formOrdenar = this.formBuilder.group({
      opcion: [""],
    });

    const planRequest: PlanRequest = new PlanRequest();

    planRequest.setOrderBy("ASC");

    this.planService
      .index(planRequest)
      .toPromise()
      .then(
        (pagination: any) => {
          this.planes = pagination.data;
        },
        (error: HttpErrorResponse) => {}
      );

    this.event.publish("reviewSaldo");

    if (this.auth.currentUserValue.user.rolId === 1) {
      this.isAdmin = true;
    } else {
      if (this.auth.currentAdminValue) {
        this.isDestination = true;
      }
    }

    this.apiUrl = global.API_URL;
    this.currentRoute.params.subscribe((params) => {
      this.cleanCustomer();
      this.type = +params["type"];
      if (!this.isAdmin) {
        this.fillLocals();
        this.clienteid = true;
      }
    });

    this.loadLanguages();
  }

  public fillLocals(page?: number) {
    this.spinner.show();
    const request = this.buildLocalRequest();

    request.setPerPage(6);

    if (page !== undefined) {
      request.setPage(page);
    }

    this.localService
      .index(request)
      .toPromise()
      .then(
        (pagination: any) => {
          this.localsData = pagination;
          this.locals = pagination.data;
          this.espaciosMostrados = pagination.data;

          this.espaciosMostrados.sort((a: Local, b: Local) => {
            if (a.activo && !b.activo) {
              return -1;
            }
            if (!a.activo && b.activo) {
              return 1;
            }
            return 0;
          });

          this.locals.map((local: Local) => {
            local.viewzones = false;
            local.viewpeticiones = false;
            local.viewMenu = false;
            local.planId = local.slots.reduce(
              (prev, curr) => (prev.planId > curr.planId ? prev : curr),
              { planId: 0 }
            ).planId;
            local.zonas.map((zona: LocalZona) => {
              zona.viewsubzones = false;
            });
            if (local.activo === 1) {
              this.cantidadActivas++;
            }
          });

          this.spinner.hide();
        },

        (error) => this.notification.error(error)
      );
  }

  public aplicarFiltro() {
    this.espaciosMostrados = this.locals;

    this.activa = this.form.value.activo === true ? 1 : 0;
    this.desactiva = this.form.value.desactiva === true ? 1 : 0;
    if (
      (this.desactiva === 1 && this.activa === 1) ||
      (this.desactiva === 0 && this.activa === 0)
    ) {
      this.espaciosMostrados = this.locals;
    } else {
      if (this.activa === 1) {
        this.espaciosMostrados = this.espaciosMostrados.filter(
          (tarjeta) => tarjeta.activo === this.activa
        );
      }
      if (this.desactiva === 1) {
        this.espaciosMostrados = this.espaciosMostrados.filter(
          (tarjeta) => tarjeta.activo === 0
        );
      }
    }

    if (this.form.value.nombre !== "") {
      this.espaciosMostrados = this.espaciosMostrados.filter((espacio) =>
        espacio.nombre
          .toUpperCase()
          .includes(this.form.value.nombre.toUpperCase())
      );
    }

    if (this.formOrdenar.value.opcion !== "") {
      this.espaciosMostrados = this.aplicarOrden(this.espaciosMostrados);
    }
  }

  public aplicarOrden(espacios: Local[]) {
    let aux = [];

    if (this.formOrdenar.value.opcion === "activo") {
      aux = espacios.sort((a, b) => a.activo - b.activo);

      if (!this.isOrderAsc) {
        aux.reverse();
      }
    } else if (this.formOrdenar.value.opcion === "nombre") {
      aux = espacios.sort((a, b) => a.nombre.localeCompare(b.nombre));

      if (!this.isOrderAsc) {
        aux.reverse();
      }
    }

    return aux;
  }

  public toggleOrder(value: string) {
    this.isOrderAsc = value === "ascendente";
  }

  public cleanFiltro() {
    this.form.patchValue({ nombre: "" });
    this.form.patchValue({ activa: null });
    this.formOrdenar.patchValue({ opcion: "" });
    this.aplicarFiltro();
  }

  public editZone(zone?: LocalZona, local?: Local) {
    const modalRef = this.modalService.open(EspacesZonesComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.zone = zone ? zone : { localId: local.id };

    modalRef.result.then(
      (result: LocalZona) => {
        this.fillLocals();

        if (zone) {
          const localZona: LocalZona = local.zonas.find(
            (zona) => zona.id === zone.id
          );

          localZona.nombre = result.nombre;
          localZona.descripcion = result.descripcion;
        } else {
          local.zonas.push(result);
        }
      },

      () => {}
    );
  }

  public editSubzone(zone: LocalZona, subzone?: LocalSubzona, local?: Local) {
    const modalRef = this.modalService.open(EspacesSubzonesComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.subzone = subzone
      ? subzone
      : { localId: local.id, zonaId: zone.id };

    modalRef.result.then(
      (result: LocalSubzona) => {
        this.fillLocals();

        if (subzone) {
          const localSubzona: LocalSubzona = zone.subzonas.find(
            (localSub) => localSub.id === subzone.id
          );

          localSubzona.nombre = result.nombre;
          localSubzona.descripcion = result.descripcion;
        } else {
          zone.subzonas.push(result);
        }
      },
      () => {}
    );
  }

  public addEspaceEnlace(local: Local) {
    this.modalManager.show(
      EspacesEnlacesComponent,

      () => this.fillLocals(),

      {
        local,
        languages: this.languages,
      }
    );
  }

  public editEspaceEnlace(espaceEnlace: EspaceEnlace, local: Local) {
    this.modalManager.show(
      EspacesEnlacesComponent,

      () => this.fillLocals(),

      {
        espaceEnlace,
        local,
        languages: this.languages,
      }
    );
  }

  public deleteEspaceEnlaceConfirm(
    modal: TemplateRef<any>,
    espaceEnlace: EspaceEnlace
  ) {
    this.espaceEnlace = espaceEnlace;

    this.modalRef = this.modalManager.open(modal);
    /* this.modalRef = this.modalService.open( modal, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false
    }); */
  }

  public deleteEspaceEnlace() {
    this.spinner.show();

    this.espaceEnlaceService.delete(this.espaceEnlace).then(
      () => {
        this.closeModal();
        this.fillLocals();
      },

      (error) => {
        this.closeModal();
        this.notification.error(error);
      }
    );
  }

  public showFilterandOrder() {
    this.showFilterOrder = !this.showFilterOrder;
  }

  public openSubZones(zona: LocalZona) {
    zona.viewsubzones = !zona.viewsubzones;
  }

  public openZones(local: Local) {
    const plan = this.auth.currentUserValue.user.plan;

    if ((plan && plan == null) || plan.id == 1) {
      this.planUpgrade();
      return;
    }

    if (local.planId < 2 && local.slots.length === 0) {
      this.upgradeLocal(local);
      return;
    }

    local.viewzones = !local.viewzones;
  }

  public openPeticiones(local: Local) {
    local.viewpeticiones = !local.viewpeticiones;
  }

  public toggleEnlaces(espace: Local) {
    espace.viewEnlaces = !espace.viewEnlaces;
  }

  public searchCustomers() {
    this.spinner.show();

    const modalRef = this.modalService.open(EspacesCustomersComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.search = this.searchterm;
    modalRef.componentInstance.searchlocal = this.searchlocal;

    modalRef.result.then(
      (result) => {
        this.selectedCustomer = result.customer;
        this.selectedAdmin = result.destination;
        this.fillLocals();
      },

      () => {
        this.spinner.hide();
      }
    );
  }

  public showQrEspace(local: Local, zona?: LocalZona, subzona?: LocalSubzona) {
    const modalRef = this.modalService.open(EspacesQrComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.localId = local.id;
    modalRef.componentInstance.zonaId = zona ? zona.id : null;
    modalRef.componentInstance.subzonaId = subzona ? subzona.id : null;
    modalRef.componentInstance.administracionId = this.selectedAdmin
      ? this.selectedAdmin.id
      : null;
    modalRef.componentInstance.isAdmin = this.isAdmin;

    modalRef.result.then(
      () => {},
      () => {}
    );
  }

  public async openConfirm(
    modal: TemplateRef<any>,
    modalText: string,
    local?: Local,
    localZona?: LocalZona,
    localSubzona?: LocalSubzona,
    activa?: number
  ): Promise<void> {
    this.modalText = modalText;
    let plan: Plan;

    if (!this.user.planId || this.user.planId === environment.planBasicId) {
      const planRequest: PlanRequest = new PlanRequest();
      planRequest.setConfiguracion(true);

      plan = await this.planService
        .show(environment.planBasicId, planRequest)
        .toPromise();
    } else {
      plan = this.user.plan;
    }

    if (local) {
      this.local = local;
      this.modal_eliminar_local = false;
      this.modal_activar_local = false;
      this.modal_desactivar_local = false;

      if (activa === 1) {
        this.confirm_text =
          this.translate.instant("¿Esta seguro que desea activar el espacio") +
          " " +
          local.nombre +
          "?";
        this.confirm_title = "Activar espacio";
        this.modal_activar_local = true;
        this.cantidadActivas++;
      } else if (activa === 0) {
        this.confirm_text =
          this.translate.instant(
            "¿Esta seguro que desea desactivar el espacio"
          ) +
          " " +
          local.nombre +
          "?";
        this.confirm_title = "Desactivar espacio";
        this.modal_desactivar_local = true;
        this.cantidadActivas--;
      } else {
        this.confirm_text =
          this.translate.instant("¿Esta seguro que desea eliminar el espacio") +
          " " +
          local.nombre +
          "?";
        this.confirm_title = "Eliminar espacio";
        this.modal_eliminar_local = true;
      }
    }

    if (localZona) {
      this.localZona = localZona;
    }

    if (localSubzona) {
      this.localSubzona = localSubzona;
    }

    this.modalRef = this.modalService.open(modal, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });
  }

  public openModalActivation(
    modal: TemplateRef<any>,
    local: Local,
    action: number
  ) {
    const plan = this.auth.currentUserValue.user.plan;

    this.local = local;

    if (this.isAdmin) {
      this.mostrarModalActivacion(modal, local, action);
    }
    if (plan.id === 1) {
      if (local.blocked !== 1) {
        this.mostrarModalActivacion(modal, local, action);
      } else {
        this.mostrarMensajeCompra(modal, local, action);
      }
    } else {
      this.mostrarModalActivacion(modal, local, action);
    }
  }

  mostrarMensajeCompra(
    modal?: TemplateRef<any>,
    local?: Local,
    action?: number,
    profunction?: boolean,
    premiumfunction?: boolean
  ) {
    const modalRef = this.modalService.open(PlanBuyLocalsAskComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    if (this.user.plan && this.user.plan.id === 1) {
      modalRef.componentInstance.basic = true;
      modalRef.componentInstance.funcionpro = false;
      modalRef.componentInstance.funcionpremium = false;

      if (local) {
        modalRef.componentInstance.activa = true;
      } else {
        modalRef.componentInstance.activa = false;
      }

      if (profunction) {
        modalRef.componentInstance.funcionpro = true;
      }

      if (premiumfunction) {
        modalRef.componentInstance.funcionpremium = true;
      }
    } else if (this.user.plan && this.user.plan.id === 2) {
      modalRef.componentInstance.pro = true;

      if (local) {
        modalRef.componentInstance.activa = true;
      } else {
        modalRef.componentInstance.activa = false;
      }

      if (premiumfunction) {
        modalRef.componentInstance.funcionpremium = true;
      }
    } else if (this.user.plan && this.user.plan.id === 3) {
      modalRef.componentInstance.premium = true;

      if (local) {
        modalRef.componentInstance.activa = true;
      } else {
        modalRef.componentInstance.activa = false;
      }
    }

    modalRef.result.then(
      (result: any) => {
        if (result > 0) {
          if (
            this.user.plan !== undefined &&
            this.user.plan.id > 1 &&
            local !== undefined &&
            (local.slots.length === 0 || local.planId < this.user.plan.id)
          ) {
            this.upgradeLocal(local);
            return;
          }

          const customerPaymentsModal = this.modalService.open(
            CustomersPaymentsComponent,
            {
              size: "lg",
              windowClass: "plan-upgrade",
              backdrop: "static",
              keyboard: true,
              backdropClass: "light-backdrop plan-upgrade",
            }
          );
          const plan = this.planes.find((p: Plan) => p.id === 2);
          customerPaymentsModal.componentInstance.optionPurchase = 1;
          customerPaymentsModal.componentInstance.plan = plan;
          customerPaymentsModal.result.then((data) => {
            if (data) {
              if (data.planId > 1) {
                this.slotService.updateSlotsProNumber(data.cantidadLocales);
              } else if (data.planId > 2) {
                this.slotService.updateSlotsPremiumNumber(data.cantidadLocales);
              }
              if (modal) {
                this.mostrarModalActivacion(modal, local, action);
              } else {
                const espacesModal = this.modalService.open(
                  EspacesComponent,
                  this.configModal
                );

                espacesModal.componentInstance.selectedCustomer =
                  this.selectedCustomer;
                espacesModal.componentInstance.selectedAdmin =
                  this.selectedAdmin;
                espacesModal.componentInstance.type = this.type;

                espacesModal.result.then(() => {
                  this.fillLocals();
                  this.slotService.refresh();
                });
              }
            }
          });
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  mostrarModalActivacion(
    modal: TemplateRef<any>,
    local: Local,
    action: number
  ) {
    if (action === 1) {
      this.confirm_text =
        this.translate.instant("¿Esta seguro que desea activar el espacio") +
        " " +
        local.nombre +
        "?";
      this.confirm_title = "Activar espacio";
      this.modal_activar_local = true;
      this.modal_desactivar_local = false;
      this.cantidadActivas++;
    } else if (action === 0) {
      this.confirm_text =
        this.translate.instant("¿Esta seguro que desea desactivar el espacio") +
        " " +
        local.nombre +
        "?";
      this.confirm_title = "Desactivar espacio";
      this.modal_desactivar_local = true;
      this.modal_activar_local = false;
      this.cantidadActivas--;
    }

    this.modalRef = this.modalService.open(modal, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });
  }

  public deleteZone() {
    this.localZonaService
      .delete(this.localZona)
      .toPromise()
      .then(
        () => {
          this.local.zonas.splice(this.local.zonas.indexOf(this.localZona), 1);
          this.localZona = null;
          this.modalRef.close();
          this.notification.success("La zona se ha eliminado correctamente.");
        },

        (error) => this.notification.error(error)
      );
  }

  public deleteSubzone() {
    this.spinner.show();

    this.localSubzonaService
      .delete(this.localSubzona)
      .toPromise()
      .then(
        () => {
          const localZona: LocalZona = this.local.zonas.find(
            (zona) => zona.id === this.localSubzona.zonaId
          );
          localZona.subzonas.splice(
            localZona.subzonas.indexOf(this.localSubzona),
            1
          );
          this.localSubzona = null;
          this.modalRef.close();
          this.notification.success(
            "La subzona se ha eliminado correctamente."
          );
        },

        (error) => this.notification.error(error)
      );
  }

  public isActive() {
    this.spinner.show();

    this.localService
      .update({ id: this.local.id, activo: this.local.activo ? 0 : 1 })
      .toPromise()
      .then(
        (local: any) => {
          this.locals[this.locals.indexOf(this.local)].activo = local.activo;
          this.spinner.hide();
          this.modalRef.close();
          this.slotService.decrease();
          this.modalService.dismissAll();
        },
        (e) => {
          this.modalRef.close();
          this.spinner.hide();
          this.notification.error(e.error.error.activo[0]);
        }
      );
  }

  public eliminarLocal() {
    this.localService
      .delete(this.local)
      .toPromise()
      .then(
        () => {
          this.locals.splice(this.locals.indexOf(this.local), 1);
          this.modalRef.close();
          this.notification.success(
            "El espacio ha sido eliminado correctamente."
          );
        },

        (error) => this.notification.error(error)
      );
  }

  public showEditLocal(local: Local) {
    const modalRef = this.modalService.open(EspacesComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.local = local;
    modalRef.componentInstance.type = this.type;

    modalRef.result.then(
      () => this.fillLocals(),
      () => {}
    );
  }

  public openNewLocal() {
    // Usuario BASIC
    if (this.user.planId === 1) {
      // const nonblocked = this.locals.filter((loc: Local) => loc.blocked !== 1).length;

      const espacesModal = this.modalService.open(
        EspacesComponent,
        this.configModal
      );
      espacesModal.componentInstance.selectedCustomer = this.selectedCustomer;
      espacesModal.componentInstance.selectedAdmin = this.selectedAdmin;
      espacesModal.componentInstance.type = this.type;
      espacesModal.result.then(() => this.fillLocals());

      return false;
      // NO BORRAR
      /*if (nonblocked < 2) {
        const espacesModal = this.modalService.open(EspacesComponent, this.configModal);

        espacesModal.componentInstance.selectedCustomer = this.selectedCustomer;
        espacesModal.componentInstance.selectedAdmin = this.selectedAdmin;
        espacesModal.componentInstance.type = this.type;
        espacesModal.result.then(() => this.fillLocals());

        return false;

      } else {
        this.mostrarMensajeCompra();
      }*/
    } else {
      //if (this.isAdmin || this.slotService.slot.slots_available_pro > 0) {

      const espacesModal = this.modalService.open(
        EspacesComponent,
        this.configModal
      );
      espacesModal.componentInstance.selectedCustomer = this.selectedCustomer;
      espacesModal.componentInstance.selectedAdmin = this.selectedAdmin;
      espacesModal.componentInstance.type = this.type;

      espacesModal.result
        .then((local) => {
          this.fillLocals();
          this.slotService.refresh();
          // Si NO tengo espacios, el local se creará COMO BASIC
          if (this.slotService.slot.slots_available_pro === 0) {
            // Quieres dejar este ESPACIO como BASIC o PRO
            const params = {
              title: "Convertir en PRO o PREMIUM",
              msg: "¿ Deseas dejar este ESPACIO como BASIC ?",
              ko: "Dejar en BASIC",
              ok: "Actualizar",
            };

            this.showModal(
              ConfirmModalComponent,
              (callbackModal) => {
                if (callbackModal.close) {
                  this.upgradeLocal(local);
                }
              },
              params
            );
          }
        })
        .catch((e) => this.notificationService.error(e));

      /*} else {
        this.mostrarMensajeCompra();
      }*/
    }
  }

  public upgradeLocal(local: Local) {
    const params = {
      local: local,
      plan: this.user.plan,
    };

    this.showModal(
      ConvertSpaceToModalComponent,
      (d) => {
        if (d.upgrade == "PRO") {
          this.upgradeLocalToPro(local);
        }
        if (d.upgrade == "PREMIUM") {
          this.upgradeLocalToPremium(local);
        }
      },
      params
    );
  }

  public upgradeLocalToPro(local: Local) {
    if (
      local.planId == undefined ||
      local.planId == null ||
      (this.user.plan.id >= 2 && local.planId < 2)
    ) {
      if (
        this.slotService.slot !== undefined &&
        this.slotService.slot.slots_available_pro &&
        this.slotService.slot.slots_available_pro > 0
      ) {
        const params = {
          title: "Mejorar espacio a PRO",
          msg: "¿ Deseas mejorar este espacio a PRO ?",
          ok: "Actualizar",
          ko: "Cancelar",
        };

        this.showModal(
          ConfirmModalComponent,
          (d) => {
            if (d.close) {
              this.slotService.upgradeLocal(local.id, 2).subscribe(
                (data) => {
                  this.notificationService.success(data["message"]);
                  this.fillLocals();
                  this.slotService.refresh();
                },
                (e) => {
                  this.notificationService.error(e["message"]);
                  this.slotService.refresh();
                }
              );
            }
          },
          params
        );
      } else {
        // Si no tengo SLOTS disponibles -> TENGO QUE COMPRAR ESPACIOS
        const modalRef = this.modalService.open(CustomersPaymentsComponent, {
          size: "lg",
          windowClass: "plan-upgrade",
          backdrop: "static",
          keyboard: true,
          backdropClass: "light-backdrop plan-upgrade",
        });

        const plan = this.planes.find((p: Plan) => p.id === 2);
        modalRef.componentInstance.plan = plan;
        if (this.user.plan !== undefined && this.user.plan.id > 1) {
          modalRef.componentInstance.stepVisible = 2;
        }
        modalRef.componentInstance.convertFlag = true;
        modalRef.componentInstance.localWantBuy = local;

        /*modalRef.result.then(
          (result: any) => {
            this.fillLocals();
            this.slotService.refresh();
          },
          (reason) => { }
        );*/

        modalRef.result.then(
          (result: any) => {
            this.slotService.upgradeLocal(local.id, 2).subscribe(
              (data) => {
                this.notificationService.success(data["message"]);
                this.fillLocals();
                this.slotService.refresh();
              },
              (e) => {
                this.notificationService.error(e["message"]);
                this.slotService.refresh();
              }
            );
          },
          (reason) => {}
        );
      }
    }
  }

  public upgradeLocalToPremium(local: Local) {
    if (
      (local.planId == undefined ||
      local.planId == null) ||
      (this.user.plan.id >= 2 && local.planId < 3)
    ) {
      if (
        this.slotService.slot &&
        this.slotService.slot.slots_available_premium &&
        this.slotService.slot.slots_available_premium > 0
      ) {
        const params = {
          title: "Mejorar espacio a PREMIUM",
          msg: "¿ Deseas mejorar este espacio a PREMIUM ?",
          ok: "Actualizar",
          ko: "Cancelar",
        };

        this.showModal(
          ConfirmModalComponent,
          (d) => {
            if (d.close) {
              this.slotService.upgradeLocal(local.id, 3).subscribe(
                (data) => {
                  this.notificationService.success(data["message"]);
                  this.fillLocals();
                  this.slotService.refresh();
                },
                (e) => {
                  this.notificationService.error(e["message"]);
                  this.slotService.refresh();
                }
              );
            }
          },
          params
        );
      } else {
        // Si no tengo SLOTS disponibles -> TENGO QUE COMPRAR ESPACIOS
        const modalRef = this.modalService.open(CustomersPaymentsComponent, {
          size: "lg",
          windowClass: "plan-upgrade",
          backdrop: "static",
          keyboard: true,
          backdropClass: "light-backdrop plan-upgrade",
        });

        const plan = this.planes.find((p: Plan) => p.id === 3);
        modalRef.componentInstance.plan = plan;
        if (this.user.plan !== undefined && this.user.plan.id > 1) {
          modalRef.componentInstance.stepVisible = 2;
        }
        modalRef.componentInstance.convertFlag = true;
        modalRef.componentInstance.localWantBuy = local;

        /*modalRef.result.then(
          (result: any) => {
            this.fillLocals();
            this.slotService.refresh();
          },
          (reason) => { }
        );*/

        modalRef.result.then(
          (result: any) => {
            this.slotService.upgradeLocal(local.id, 3).subscribe(
              (data) => {
                this.notificationService.success(data["message"]);
                this.fillLocals();
                this.slotService.refresh();
              },
              (e) => {
                this.notificationService.error(e["message"]);
                this.slotService.refresh();
              }
            );
          },
          (reason) => {}
        );
      }
    }
  }

  public openPeticionesComponent(peticion: BusinessEspacio) {
    const modalRef = this.modalService.open(PeticionesComponent, {
      centered: true,
      windowClass: "newModal",
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.peticion = peticion;

    modalRef.result.then(
      () => this.fillLocals(),
      () => {}
    );
  }

  public cleanCustomer() {
    this.selectedCustomer = null;
    this.searchlocal = "";
    this.searchterm = "";
    this.selectedAdmin = null;
    this.locals = [];
    this.espaciosMostrados = [];
  }

  public gallery(local: Local) {
    const modalRef = this.modalService.open(PhotosComponent, {
      centered: true,
      windowClass: "modal-custom",
      backdrop: true,
      keyboard: true,
      size: "lg",
    });

    modalRef.componentInstance.espace = local;

    modalRef.result.then(
      () => {},
      () => {}
    );
  }

  public viewMenu(event: Event, objeto: any) {
    this.espaciosMostrados.forEach((espacio: Local) => {
      espacio.viewMenu = false;
      espacio.zonas.forEach((zona: LocalZona) => {
        zona.viewMenu = false;
        zona.subzonas.forEach(
          (subzona: LocalSubzona) => (subzona.viewMenu = false)
        );
      });
    });
    objeto.viewMenu = !objeto.viewMenu;
    this.menuOpen = objeto.viewMenu;

    event.stopPropagation();
  }

  public closeMenu() {
    if (this.menuOpen) {
      this.locals.forEach((local) => {
        local.viewMenu = false;
      });
    }
  }

  public closeModal() {
    this.modalRef.close();
  }

  private loadLanguages() {
    const idiomaRequest = new IdiomaRequest();
    idiomaRequest.setOrderBy("ASC");

    this.idiomaService
      .index(idiomaRequest)
      .toPromise()
      .then(
        (pagination: any) => (this.languages = pagination.data),
        (error) => this.notification.error(error)
      );
  }

  private buildLocalRequest(): LocalRequest {
    const result = new LocalRequest();
    result.setUserId(this.user.id);
    result.setZonaSubzonas(true);
    if (this.selectedCustomer) {
      result.setUserId(this.selectedCustomer.user.id);
    } else if (this.selectedAdmin) {
      result.setUserId(this.selectedAdmin.user.id);
    } else {
      result.setUserId(this.user.id);
    }

    if (this.isDestination) {
      if (this.type === 1) {
        result.setIsAdministracionCategoria(false);
        result.setOficinaturismo(1);
      } else if (this.type === 2) {
        result.setIsAdministracionCategoria(true);
        result.setOficinaturismo(0);
      } else {
        result.setIsAdministracionCategoria(false);
        result.setOficinaturismo(0);
      }
    }

    return result;
  }

  public controllerPage(page) {
    this.fillLocals(Utils.calculatePage(page, this.localsData));
  }

  public sanitizedAndTranslate(label) {
    const translated = this.translate.instant(label);
    const result = this.sanitized.bypassSecurityTrustHtml(translated);

    return result;
  }

  private showModal(content: any, fn: Function, options?: any) {
    const realOptions = {};

    if (options) {
      for (let prop of Object.keys(options)) {
        realOptions[prop] = options[prop];
      }
    }

    this.modalManager.show(content, (d) => fn(d), realOptions);
  }

  /**
   *
   */
  public planUpgrade(): void {
    const modalRef = this.modalService.open(CustomersPaymentsComponent, {
      size: "lg",
      windowClass: "plan-upgrade",
      backdrop: "static",
      keyboard: true,
      backdropClass: "light-backdrop plan-upgrade",
    });

    let userPlan = this.auth.currentUserValue.user.plan;

    let plan = null;
    if (userPlan.id === 1) {
      let nuevoPlanId = userPlan.id + 1;
      plan = this.planes.find((e) => e.id == nuevoPlanId);
    } else if (userPlan.id === 2) {
      let nuevoPlanId = userPlan.id + 1;
      plan = this.planes.find((e) => e.id == nuevoPlanId);
    }

    if (this.user.plan == null || this.user.plan.id == 1) {
      modalRef.componentInstance.firstTime = true;
    }

    modalRef.componentInstance.plan = plan;
    modalRef.result.then(
      (result: any) => {
        this.user = this.auth.currentUserValue.user;
        this.event.publish("reviewSlots");
      },
      (reason) => {
        this.notificationService.logger(
          "CustomersPaymentsComponent-> REASON" + reason
        );
      }
    );
    //}
  }
}
