import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Tarjeta } from '../tarjetas/models/tarjeta';
import { TarjetaService } from '../tarjetas/services/tarjeta.service';
import { NotificationService } from '../../services/notification.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css']
})

export class InvitationsComponent implements OnInit {
  @Input() public tarjeta: Tarjeta;
  @ViewChild('modalConfirm') public modalConfirm: TemplateRef<any>;

  public modalRef: NgbModalRef;
  public formEmails: FormGroup;
  selectedFile: File | null = null;
  emailstosend: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private tarjetaService: TarjetaService
  ) {
    
  }

  public ngOnInit() {
    this.formEmails = this.formBuilder.group({
      emails: [null],
      csv: [null],
    });
    this.emailstosend = '';
  }


  public openConfirm( event: Event, index: number) {
    this.modalRef = this.modalService.open( this.modalConfirm, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false
    });

    this.modalRef.result.then(
      () => {},
      () => {}
    );
  }

  upload(target: any) {
    this.selectedFile = target.files.item(0);
    const reader: FileReader = new FileReader();
      reader.readAsText(this.selectedFile, 'ISO-8859-1');
      reader.onload = (e) => {
        const csv: any = reader.result;
        let allTextLines = [];
        allTextLines = csv.split(/\r|\n|\r/);
        const totallineas = allTextLines.length;
        for (let i = 0; i < totallineas; i++) {
          this.emailstosend += allTextLines[i] + ',';
        }
      };
  }

  sendCsv() {
    this.spinner.show();
    let emails = '';
    const obj: any = {};
    if (this.selectedFile) {
      emails += this.emailstosend;
    }
    if (this.formEmails.value.emails !== '' && this.formEmails.value.emails !== null) {
        emails += this.formEmails.value.emails;
    }
    if (emails.substr(emails.length - 1) === ',') {
      emails = emails.slice(0, -1);
    }
    
    obj.emails = emails;
    obj.cardId = this.tarjeta.id;
    this.tarjetaService.invitations(obj).then(
      (data) => {
        this.notification.success('Las invitaciones se han creado correctamente.');
        this.spinner.hide();
        this.activeModal.dismiss();
      },
      (error) => {
        this.spinner.hide();
        this.notification.error('Las invitaciones no se han podido enviar.');
      }
    );
  }
}
