import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import * as global from './global.service';
import { IdiomaRequest } from '../interfaces/idiomaRequest.model';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { Idioma } from '../models/idioma';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public index(idiomaRequest?: IdiomaRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (idiomaRequest && idiomaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(idiomaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/languages`, this.httpOptions).pipe(
      map((response: any) => {

        response.data = objsToCamelCase<Idioma>(response.data);

        return response;
      })
    );
  }

  public create(idioma: Idioma) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Idioma>(global.API_URL + '/api/languages', idioma, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(idioma: Idioma) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<Idioma>(global.API_URL + '/api/languages/' + idioma.id, idioma, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(idioma: Idioma) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/languages/' + idioma.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
