import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as global from './global.service';
import { objsToCamelCase } from '../helpers/object.helper';
import { EventoRequest } from '../interfaces/eventoRequest.model';
import { Evento } from '../models/evento';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public byId(Id: number) {

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<Evento>(global.API_URL + '/api/eventos/' + Id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public index(eventoRequest: EventoRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (eventoRequest.getDestinoId()) {
      httpParams = httpParams.set('destinoId', String(eventoRequest.getDestinoId()));
    }

    if (eventoRequest.getUserId()) {
      httpParams = httpParams.set('userId', String(eventoRequest.getUserId()));
    }

    if (eventoRequest.getType()) {
      httpParams = httpParams.set('type', String(eventoRequest.getType()));
    }

    if (eventoRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(eventoRequest.getIdiomas()));
    }

    if (eventoRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(eventoRequest.getPerPage()));
    }

    if (eventoRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(eventoRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get(global.API_URL + `/api/eventos`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(evento: Evento) {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Evento>(global.API_URL + '/api/eventos', evento, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(evento: Evento) {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<Evento>(global.API_URL + '/api/eventos/' + evento.id, evento, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(evento: Evento) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/eventos/' + evento.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
