import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as global from '../../../services/global.service';
import { map } from 'rxjs/operators';
import { objToCamelCase } from '../../../helpers/object.helper';
import { CompraRequest } from '../models/compra-request.model';
import { Pagination } from '../../../models/pagination';
import { Compra } from '../interfaces/compra';

@Injectable({
  providedIn: 'root'
})
export class CompraService {

  private static COMPRAS_PATH = global.API_URL + '/api/compras';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(compraRequest: CompraRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (compraRequest.getCliente()) {
      httpParams = httpParams.set('cliente', String(compraRequest.getCliente()));
    }

    if (compraRequest.getFormaPago()) {
      httpParams = httpParams.set('formaPago', String(compraRequest.getFormaPago()));
    }

    if (compraRequest.getPlan()) {
      httpParams = httpParams.set('plan', String(compraRequest.getPlan()));
    }

    if (compraRequest.getClienteId()) {
      httpParams = httpParams.set('clienteId', String(compraRequest.getClienteId()));
    }

    if (compraRequest.getFormaPagosId()) {
      httpParams = httpParams.set('formaPagosId', String(compraRequest.getFormaPagosId()));
    }

    if (compraRequest.getPlanId()) {
      httpParams = httpParams.set('clienteId', String(compraRequest.getPlanId()));
    }

    if (compraRequest.getCreatedAtStart()) {
      httpParams = httpParams.set('createdAtStart', compraRequest.getCreatedAtStart());
    }

    if (compraRequest.getCreatedAtEnd()) {
      httpParams = httpParams.set('createdAtEnd', compraRequest.getCreatedAtEnd());
    }

    if (compraRequest.getPerPage()) {
      httpParams = httpParams.set('perPage', String(compraRequest.getPerPage()));
    }

    if (compraRequest.getOrderBy()) {
      httpParams = httpParams.set('orderBy', String(compraRequest.getOrderBy()));
    }

    if (compraRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', String(compraRequest.getSortBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(CompraService.COMPRAS_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public show(compraRequest: CompraRequest, id: number): Observable<Compra> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Compra>(`${CompraService.COMPRAS_PATH}/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Compra>(response);
      })
    );
  }

  public create(compra): Observable<Compra> {

    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Compra>(CompraService.COMPRAS_PATH, compra, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Compra>(response);
      })
    );
  }
}
