import { PaginationRequest } from './paginationRequest.model';

export class EventRequest extends PaginationRequest {

  private userId: number;
  private clienteId: number;
  private paisId: string;
  private comunidadAutonomaId: string;
  private provinciaId: string;
  private ciudadId: string;
  private administracionId: number;
  private rutaId: number;
  private activo: boolean;
  private nombre: string;
  private ciudad: boolean;
  private isAdministracionCategoria: boolean;
  private locations: boolean;
  private sponsors: boolean;
  private tickets: boolean;
  private products: boolean;
  private zones: boolean;
  private photos: boolean;

  constructor() {
    super();
    this.isAdministracionCategoria = null;
  }

  /**
   *
   */
  public getUserId(): number {
    return this.userId;
  }

  /**
   *
   */
  public setUserId(userId: number): void {
    this.userId = userId;
  }

  /**
  *
  */
  public getClienteId(): number {
    return this.clienteId;
  }

  /**
   *
   */
  public setClienteId(clienteId: number): void {
    this.clienteId = clienteId;
  }

  /**
   *
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   *
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

  /**
   *
   */
  public getRutaId(): number {
    return this.rutaId;
  }

  /**
   *
   */
  public setRutaId(rutaId: number): void {
    this.rutaId = rutaId;
  }

  /**
   *
   */
  public getActivo(): boolean {
    return this.activo;
  }

  /**
   *
   */
  public setActivo(activo: boolean): void {
    this.activo = activo;
  }

  /**
   *
   */
  public getNombre(): string {
    return this.nombre;
  }

  /**
   *
   */
  public setNombre(nombre: string): void {
    this.nombre = nombre;
  }

  /**
   *
   */
  public getCiudad(): boolean {
    return this.ciudad;
  }

  /**
   *
   */
  public setCiudad(ciudad: boolean): void {
    this.ciudad = ciudad;
  }

  /**
   *
   */
  public getPaisId(): string {
    return this.paisId;
  }

  /**
   *
   */
  public setPaisId(paisId: string): void {
    this.paisId = paisId;
  }

  /**
 *
 */
  public getProvinciaId(): string {
    return this.provinciaId;
  }

  /**
   *
   */
  public setProvinciaId(provinciaId: string): void {
    this.provinciaId = provinciaId;
  }

  /**
 *
 */
  public getComunidadAutonomaId(): string {
    return this.comunidadAutonomaId;
  }

  /**
   *
   */
  public setComunidadAutonomaId(comunidadAutonomaId: string): void {
    this.comunidadAutonomaId = comunidadAutonomaId;
  }

  /**
 *
 */
  public getCiudadId(): string {
    return this.ciudadId;
  }

  /**
   *
   */
  public setCiudadId(ciudadId: string): void {
    this.ciudadId = ciudadId;
  }

  /**
   *
   */
  public getIsAdministracionCategoria(): boolean {
    return this.isAdministracionCategoria;
  }

  /**
   *
   */
  public setIsAdministracionCategoria(value: boolean): void {
    this.isAdministracionCategoria = value;
  }

   /**
   *
   */
   public setLocations(locations: boolean) {
    this.locations = locations;
  }

  /**
   *
   */
  public getLocations(): boolean {
    return this.locations;
  }

  /**
   *
   */
  public setSponsors(sponsors: boolean) {
    this.sponsors = sponsors;
  }

  /**
   *
   */
  public getSponsors(): boolean {
    return this.sponsors;
  }

  /**
  *
  */
  public setTickets(tickets: boolean) {
    this.tickets = tickets;
  }

  /**
   *
   */
  public getTickets(): boolean {
    return this.tickets;
  }

  /**
  *
  */
  public setProducts(products: boolean) {
    this.products = products;
  }

  /**
   *
   */
  public getProducts(): boolean {
    return this.products;
  }

  /**
*
*/
  public setZones(zones: boolean) {
    this.zones = zones;
  }

  /**
   *
   */
  public getZones(): boolean {
    return this.zones;
  }

  /**
   *
   * @return boolean
   */
  public getPhotos(): boolean {
    return this.photos;
  }

  /**
   *
   * @param photos
   */
  public setPhotos(photos: boolean): void {
    this.photos = photos;
  }

}
