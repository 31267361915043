import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { ProvinciaRequest } from '../interfaces/provinciaRequest.model';
import * as global from './global.service';
import { Provincia } from '../models/provincia';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class ProvinciaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(provinciaRequest: ProvinciaRequest) {
    let httpParams: HttpParams = new HttpParams();

    if (provinciaRequest.getPaisId()) {
      httpParams = httpParams.set('paisId', String(provinciaRequest.getPaisId()));
    }

    if (provinciaRequest.getComunidadAutonomaId()) {
      httpParams = httpParams.set('comunidadautonomaId', String(provinciaRequest.getComunidadAutonomaId()));
    }

    if (provinciaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(provinciaRequest.getPerPage()));
    }

    if (provinciaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(provinciaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/provinces`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public show(id: number) {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Provincia>(global.API_URL + `/api/provinces/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
