import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as global from '../../../services/global.service';
import { LocalZonaService } from '../../../services/localZona.service';
import { LocalZona } from '../../../models/localZona';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-espaces-zones',
  templateUrl: './espaces-zones.component.html',
  styleUrls: ['./espaces-zones.component.css']
})
export class EspacesZonesComponent implements OnInit {

  @Input() public zone: LocalZona;
  public form: FormGroup;
  public submited: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private localZonaService: LocalZonaService) {

    this.submited = false;
  }

  ngOnInit() {
    
    this.form = this.formBuilder.group({
      id: [this.zone.id ? this.zone.id : null],
      nombre: [this.zone.nombre ? this.zone.nombre : null, Validators.required],
      descripcion: [this.zone.descripcion ? this.zone.descripcion : null],
      cover: [null],
      coverUrl: [this.zone.cover ? global.API_URL + this.zone.cover : null],
      localId: [this.zone.localId, Validators.required],
    });

  }

  public preview(event: any): void {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
    if (files[0].size > 2000000) {
      this.notificationService.error('No pueden subirse archivos de mayor tamaño a 2 mb.');
      return;
    }
    if (files[0].type.match(/image\/*/) == null) {
      this.notificationService.error('Solo se permiten subir imagenes.');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      this.form.patchValue({ 'cover': reader.result });
    };

  }

  public save(): void {
    this.submited = true;
    if (this.zone.id) {
      this.localZonaService.update(this.form.value).toPromise().then(
        (localZona: any) => {

          this.submited = false;
          this.activeModal.close(localZona);

          this.notificationService.success('Zona editada correctamente');
        },
        (error: HttpErrorResponse) => {
          this.submited = false;
        });
    } else {
      this.localZonaService.create(this.form.value).toPromise().then(
        (localZona: any) => {
          this.submited = false;
          this.activeModal.close(localZona);

          this.notificationService.success('Zona creada correctamente');
        },
        (error: HttpErrorResponse) => {
          this.submited = false;
        });
    }
  }

}
