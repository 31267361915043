import { PaginationRequest } from '../../../interfaces/paginationRequest.model';

export class EspacesRequest extends PaginationRequest {

  private clienteId: number;
  private administracionId: number;
  private cliente: boolean;
  private administracion: boolean;
  private photos: boolean;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getClienteId(): number {
    return this.clienteId;
  }

  /**
   * 
   */
  public setClienteId(clienteId: number): void {
    this.clienteId = clienteId;
  }

  /**
   * 
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   * 
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }
  /**
   * 
   */
  public getCliente(): boolean {
    return this.cliente;
  }

  /**
   * 
   */
  public setCliente(cliente: boolean): void {
    this.cliente = cliente;
  }

  /**
   * 
   */
  public getAdministracion(): boolean {
    return this.administracion;
  }

  /**
   * 
   */
  public setAdministracion(administracion: boolean): void {
    this.administracion = administracion;
  }

  /**
   * 
   * @return boolean
   */
  public getPhotos(): boolean {
    return this.photos;
  }

  /**
   * 
   * @param photos 
   */
  public setPhotos(photos: boolean): void {
    this.photos = photos;
  }

}