import { PaginationRequest } from './paginationRequest.model';

export class CaracteristicaRequest extends PaginationRequest {

  private localId: number;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getLocalId(): number {
    return this.localId;
  }

  /**
   * 
   */
  public setLocalId(localId: number): void {
    this.localId = localId;
  }

}