import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TarjetaRequest } from '../interfaces/tarjetaRequest.model';
import { Tarjeta } from '../models/tarjeta';
import { AbstractTarjetaService } from './abstract.service';
import { TarjetaPhoto } from '../models/tarjetaPhoto';
import { objToCamelCase } from '../../../helpers/object.helper';

@Injectable({
  providedIn: 'root',
})
export class TarjetaService extends AbstractTarjetaService {
  constructor(http: HttpClient) {
    super(http, 'tarjetas');
  }

  public byId(id: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('idiomas', 'true');

    this.httpOptions = this.buildOptions(httpParams);
    const url = this.byIdUrl(id);

    return this.processResponse(this.http.get<Tarjeta>(url, this.httpOptions));
  }

  public index(request: TarjetaRequest) {
    const httpParams = this.buildParams(request);
    this.httpOptions = this.buildOptions(httpParams);

    return this.processResponse(
      this.http.get(this.indexUrl(), this.httpOptions)
    );
  }

  public show(id: number, request: TarjetaRequest): Promise<Tarjeta> {
    let httpParams: HttpParams = new HttpParams();

    if (request.getPerPage()) {
      httpParams = httpParams.set('per_page', String(request.getPerPage()));
    }

    if (request.getOrderBy()) {
      httpParams = httpParams.set('order', String(request.getOrderBy()));
    }

    if (request.getPhotos()) {
      httpParams = httpParams.set('photos', String(request.getPhotos()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Tarjeta>(this.byIdUrl(id), this.httpOptions)
      .pipe(map((tarjeta: Tarjeta) => objToCamelCase<Tarjeta>(tarjeta)))
      .toPromise();
  }

  public create(tarjeta: Tarjeta): Promise<Tarjeta> {
    this.httpOptions = this.buildOptions({}, 'Content-Type');

    return this.http
      .post<Tarjeta>(this.postUrl(), tarjeta, this.httpOptions)
      .toPromise();
  }

  public update(tarjeta: Tarjeta): Promise<Tarjeta> {
    this.httpOptions = this.buildOptions({}, 'Content-Type');

    return this.http
      .put<Tarjeta>(this.putUrl(tarjeta.id), tarjeta, this.httpOptions)
      .toPromise();
  }

  public fillData(tarjeta: Tarjeta): FormData {
    const formData: FormData = new FormData();

    const fields = [
      'clienteId',
      'destino_id',
      'nombre',
      'descripcion',
      'descripcionCorta',
      'imagen',
      'url',
      'tipoId',
      'tipoActivacion',
      'periodoActivacion',
      'consumiciones',
      'activa',
      'condiciones',
      'precio',
      'moneda',
      'descuento',
      'tipoDescuento',
      'idiomas',
    ];

    fields.forEach((field) => {
      if (tarjeta[field]) {
        formData.append(field, String(tarjeta[field]));
      }
    });

    return formData;
  }

  public delete(tarjeta: Tarjeta): Promise<any> {
    this.httpOptions = this.buildOptions();

    return this.http
      .delete<any>(this.deleteUrl(tarjeta.id), this.httpOptions)
      .toPromise();
  }

  public photos(tarjeta: Tarjeta): Promise<Tarjeta> {
    this.httpOptions = {
      headers: this.httpHeaders.delete('Content-Type'),
      params: {},
    };

    const formData: FormData = new FormData();

    tarjeta.photos.map((photo: TarjetaPhoto, index: number) => {
      if (photo.id) {
        formData.append(`photos[${index}][id]`, String(photo.id));
      }

      if (photo.imagen) {
        formData.append(`photos[${index}][imagen]`, photo.imagen);
      }
    });

    return this.http
      .post<Tarjeta>(this.photosUrl(tarjeta.id), formData, this.httpOptions)
      .toPromise();
  }

  public invitations(emails: any): Promise<any> {
    this.httpOptions = this.buildOptions({}, 'Content-Type');

    return this.http
      .post<any>(this.postUrl() + '/eventsinvitations', emails, this.httpOptions)
      .toPromise();
  }

  public removePhoto(tarjeta: Tarjeta, photo: TarjetaPhoto): Promise<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(this.photoByIdUrl(tarjeta.id, photo.id), this.httpOptions)
      .toPromise();
  }

  public checkCodeExists(code: string): Promise<any> {
    this.httpOptions = this.buildOptions();

    return this.http
      .get<any>(this.checkCodeExistsUrl(code), this.httpOptions)
      .toPromise();
  }

  public checkCodeActivationExists(codeActivation: string): Promise<any> {
    this.httpOptions = this.buildOptions();

    return this.http
      .get<any>(
        `${this.baseUrl()}/check-code-activation/${codeActivation}`,
        this.httpOptions
      )
      .toPromise();
  }

  private checkCodeExistsUrl(code: string) {
    return `${this.baseUrl()}/check-code/${code}`;
  }

  private photosUrl(tarjetaId: number): string {
    return `${this.baseUrl()}/${tarjetaId}/photos`;
  }

  private photoByIdUrl(tarjetaId: number, photoId: number): string {
    return `${this.photosUrl(tarjetaId)}/${photoId}`;
  }
}
