import * as global from '../../../services/global.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, interval, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../../models/pagination';
import { objsToCamelCase, objToCamelCase } from '../../../helpers/object.helper';
import { AuthenticationService } from '../../../services/authentication.service';
import { switchMap } from 'rxjs/operators';

// Notificactions
import { UbnmNotification } from '../interfaces/ubnmNotification';
import { UbnmNotificationRequest } from '../models/ubnmNotificationRequest';

@Injectable({
  providedIn: 'root'
})
export class UbnmNotificationService {
  private static NOTIFICATIONS_PATH = global.API_URL + '/api/nm-notifications';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  private notificationsSubject = new BehaviorSubject<any>({});
  public notifications$: Observable<any> = this.notificationsSubject.asObservable();

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

  }

  /**
   *
   */
  public byId(id: number) {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('idiomas', 'true');

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<UbnmNotification>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/' + id,
        this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  /**
   *
   * @param NotificationsRequest
   * @returns
   */
  /*public search(ubnmNotificationRequest: UbnmNotificationRequest) {
    let httpParams: HttpParams = new HttpParams();

    if (ubnmNotificationRequest.getPerPage()) {
      httpParams = httpParams.set(
        'per_page',
        String(ubnmNotificationRequest.getPerPage())
      );
    }

    if (ubnmNotificationRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(ubnmNotificationRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get(
        UbnmNotificationService.NOTIFICATIONS_PATH + `/search`,
        this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }*/

  /**
   *
   * @returns
   * @param ubnmNotificationRequest
   */
  public index(ubnmNotificationRequest: UbnmNotificationRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (ubnmNotificationRequest.getUserId()) {
      httpParams = httpParams.set('userId', String(ubnmNotificationRequest.getUserId()));
    }

    if (ubnmNotificationRequest.getClienteId()) {
      httpParams = httpParams.set(
        'clienteId',
        String(ubnmNotificationRequest.getClienteId())
      );
    }

    if (ubnmNotificationRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(ubnmNotificationRequest.getIdiomas()));
    }

    if (ubnmNotificationRequest.getPerPage()) {
      httpParams = httpParams.set(
        'per_page',
        String(ubnmNotificationRequest.getPerPage())
      );
    }

    if (ubnmNotificationRequest.getPage()) {
      httpParams = httpParams.set(
        'page',
        String(ubnmNotificationRequest.getPage())
      );
    }

    if (ubnmNotificationRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(ubnmNotificationRequest.getOrderBy()));
    }

    if (ubnmNotificationRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', ubnmNotificationRequest.getSortBy());
    }

    if (ubnmNotificationRequest.getIsHide()) {
      httpParams = httpParams.set('isHide', String(ubnmNotificationRequest.getIsHide()));
    }

    if (ubnmNotificationRequest.getType()) {
      httpParams = httpParams.set('type', ubnmNotificationRequest.getType());
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get(
      UbnmNotificationService.NOTIFICATIONS_PATH,
      this.httpOptions
    ).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  /**
   *
   * @returns
   * @param ubnmNotification
   */
  public create(ubnmNotification: UbnmNotification): Observable<UbnmNotification> {
    return this.http
      .post<UbnmNotification>(
        UbnmNotificationService.NOTIFICATIONS_PATH,
        ubnmNotification,
        this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<UbnmNotification>(response);
        })
      );
  }

  /**
   *
   * @returns
   * @param ubnmNotification
   */
  public update(ubnmNotification: UbnmNotification): Observable<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<UbnmNotification>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/' + ubnmNotification.id,
        ubnmNotification,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<UbnmNotification>(response);
        })
      );
  }

  /**
   *
   */
  public acceptNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    /*ubnmNotification.isAccept = 1;
    ubnmNotification.isDecline = 0;
    ubnmNotification.isRead = 1;*/

    return this.http
      .put<UbnmNotification>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/accept/from-user-id',
        ubnmNotification,
        this.httpOptions
      )
      .toPromise();
  }

  /**
   *
   */
  public declineNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    /*ubnmNotification.isDecline = 1;
    ubnmNotification.isAccept = 0;
    ubnmNotification.isRead = 1;*/

    return this.http
      .put<any>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/decline/from-user-id',
        ubnmNotification,
        this.httpOptions)
      .toPromise();
  }

  /**
   *
   */
  public hideNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    // ubnmNotification.isHide = 1;

    return this.http
      .put<any>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/hide/from-user-id',
        ubnmNotification,
        this.httpOptions)
      .toPromise();
  }


  /**
   *
   */
  public unhideNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    // ubnmNotification.isHide = 1;

    return this.http
      .put<any>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/hide/from-user-id',
        ubnmNotification,
        this.httpOptions)
      .toPromise();
  }

  /**
   *
   */
  public readNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    // ubnmNotification.isRead = 1

    return this.http
      .put<any>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/read/from-user-id',
        ubnmNotification,
        this.httpOptions)
      .toPromise();
  }

  /**
   *
   */
  public unreadNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<any>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/unread/from-user-id',
        ubnmNotification,
        this.httpOptions)
      .toPromise();
  }

  /**
   *
   */
  public deleteNotification(ubnmNotification: UbnmNotification): Promise<UbnmNotification> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .delete<any>(
        UbnmNotificationService.NOTIFICATIONS_PATH + '/' + ubnmNotification.id,
        this.httpOptions)
      .toPromise();
  }

  public haveNotifications() {
    this.httpOptions = {
      headers: this.httpHeaders,
    };

    //
    return this.http.get<number>(UbnmNotificationService.NOTIFICATIONS_PATH + '/quantity/from-user-id', this.httpOptions);
  }

  // Método para iniciar la verificación de notificaciones
  public startNotificationsCheck(): void {
    // Combinamos el intervalo con la llamada a haveNotifications()
    merge(interval(5000), this.haveNotifications())
      .pipe(
        switchMap(() => this.haveNotifications())
      )
      .subscribe((value) => {
        this.notificationsSubject.next(value);
      });
  }
}
