import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { CropperComponent } from 'angular-cropperjs';

const MAX_FILE_SIZE_MB = 2_000_000; // 2 MB

type Aspect = 'portrait'|'landscape';

/**
 * Uso:
 * <app-image-field class="..."
 *     [isValid]="!form.controls.imagen.errors"
 *     [image]="imageUrl || form.controls.imagen"
 *     (onChange)="onImageChange($event)"></app-image-field>
 *
 * -isValid: Para marcarla como no valida.
 * -image: Puede ser una URL (para el caso en que estamos editando)
 *         o una string Base64 (cuando se está creando un item y se selecciona una imagen).
 * -onChange: Ejecuta un handler al cambiar la imagen. El valor de $event es la string Base64
 *            de la imagen.
 */
@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {

  @ViewChild('angularCropper') angularCropper: CropperComponent;

  @Input() public type;
  @Input() public cropperNumber;
  @Input() public image;
  @Input() public enddate;
  @Input() public title;
  @Input() public description;
  @Input() public spacename;
  @Input() public aspect: Aspect = null;

  @Output() public onChange: EventEmitter<string> = new EventEmitter<string>();

  private inlineImage?: string = '/assets/images/horizontal.png';
  public imagenCropped: any;
  public imagenUrl: any = '';

  constructor( private notification: NotificationService) {}

  public preview(event: any) {
    
    const files = event.target.files;

    if (files.length === 0) {
      return;
    }

    if (files[0].size > MAX_FILE_SIZE_MB) {
      this.notification.error( 'No pueden subirse archivos de mayor tamaño a 2 mb.');
      return;
    }

    if (files[0].type.match(/image\/*/) == null) {
      this.notification.error( 'Solo se permiten subir imagenes.');
      return;
    }
    

    const reader = new FileReader();

    reader.readAsDataURL( files[0]);
    const image = new Image();

    reader.onload = () => {
      const width = image.naturalWidth;
      const height = image.naturalHeight;
      
      if (!this.isValidAspect( width, height)) {
        this.notification.error( this.invalidAspectErrorMessage);
        return;
      }
      
      this.imagenUrl =  reader.result;
    };
  }

  public getCroppedImage() {
    this.angularCropper.cropper.getCroppedCanvas().toBlob((blob) => {
      const reader = new FileReader();

      reader.readAsDataURL( blob);
      reader.onload = () => {
        this.image = reader.result as string;

        this.sendLoadEvent( reader.result as string);
      };
    }, 'image/jpeg', 0.95);
  }

  private sendLoadEvent( imageData: string) {
    this.onChange.emit( imageData);
    this.image = imageData;
  }

  private isValidAspect( width: number, height: number): boolean {
    return this.aspect === 'portrait' && width <= height ||
           this.aspect === 'landscape' && height <= width;
  }

  private get invalidAspectErrorMessage(): string {
    const expectedAspect = this.aspect === 'portrait' ? 'vertical' : 'horizontal';

    return `La imagen no tiene el formato adecuado. Busque una imagen ${expectedAspect}.`
  }

  
 
}
