import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as global from '../../../services/global.service';
import { QrService } from '../../../services/qr.service';
import { QrRequest } from '../../../interfaces/qrRequest.model';
import { Qr } from '../../../models/qr';

@Component({
  selector: 'app-espaces-qr',
  templateUrl: './espaces-qr.component.html',
  styleUrls: ['./espaces-qr.component.css']
})
export class EspacesQrComponent implements OnInit {

  @Input() public localId: number;
  @Input() public zonaId: number;
  @Input() public subzonaId: number;
  @Input() public administracionId: number;
  @Input() public isAdmin: boolean;

  public qrValue = 'https://qr.unblockthecity.com/#';
  public noimages: boolean;
  public tipoQr: Qr;
  public tiposQr: Array<Qr> = [
    {
      type: 4,
      nombre: 'QrBlanco y negro SVG',
      imagen: null
    },
    {
      type: 4,
      nombre: 'QrBlanco y negro PNG',
      imagen: null
    },
    {
      type: 1,
      nombre: 'QR blanco y negro SVG',
      imagen: null
    },
    {
      type: 1,
      nombre: 'QR blanco y negro PNG',
      imagen: null
    },
    {
      type: 2,
      nombre: 'QR color SVG',
      imagen: null
    },
    {
      type: 2,
      nombre: 'QR color PNG',
      imagen: null
    }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private _FileSaverService: FileSaverService,
    private qrService: QrService) {

  }

  ngOnInit() {

    let texto = '';
    const qrRequest: QrRequest = new QrRequest();

    if (this.localId) {

      qrRequest.setLocalId(this.localId);

      texto = texto + this.localId + ',';
    } else {
      texto = texto + '0,';
    }

    if (this.zonaId) {

      qrRequest.setZonaId(this.zonaId);

      texto = texto + this.zonaId + ',';
    } else {
      texto = texto + '0,';
    }

    if (this.subzonaId) {

      qrRequest.setSubZonaId(this.subzonaId);

      texto = texto + this.subzonaId + ',';
    } else {
      texto = texto + '0,';
    }

    if (this.administracionId) {

      qrRequest.setAdministracionId(this.administracionId);

      texto = texto + this.administracionId;
    } else {
      texto = texto + '0';
    }

    this.qrValue = this.qrValue + btoa(texto);
    
    this.qrService.index(qrRequest).subscribe(
      (pagination: any) => {
        this.noimages = true;
        for (const key in pagination.data) {

          const element: Qr = pagination.data[key];

          const tipoQr: Array<Qr> = this.tiposQr.filter((tipoQr) => tipoQr.type == element.type);

          for (const qr in tipoQr) {

            if (tipoQr[qr].nombre.indexOf('SVG') > 0 && element.imagen) {

              tipoQr[qr].imagen = global.API_URL + element.imagen;
              this.noimages = false;
            } else {

              if (tipoQr[qr].nombre.indexOf('PNG') > 0 && element.imagenpng) {
                tipoQr[qr].imagen = global.API_URL + element.imagenpng;
                this.noimages = false;
              }
            }
          }
        }
      }
    );
  }

  public viewQr(tipoQr: any): void {

    this.tipoQr = tipoQr;
  }

  public closeImage(): void {

    this.tipoQr = null;
  }

  public generateQr(tipoQrParam: Qr): void {

    const qr: any = {
      localId: this.localId,
      zonaId: this.zonaId,
      subzonaId: this.subzonaId,
      administracionId: this.administracionId,
      type: tipoQrParam.type,
      mimeType: tipoQrParam.nombre.indexOf('SVG') > 0 ? 'svg' : 'png',
      text: this.qrValue
    };

    this.qrService.create(qr).subscribe(
      (qr: any) => {

        const tipoQr = this.tiposQr.find((tipoQr) => tipoQr.type == qr.type && tipoQrParam.nombre == tipoQr.nombre);

        if (tipoQr.nombre.indexOf('SVG') > 0) {
          
          tipoQr.imagen = global.API_URL + qr.imagen;
        } else {
          tipoQr.imagen = global.API_URL + qr.imagenpng;
        }
        this.noimages = false;
      },
      (error: HttpErrorResponse) => {

      });
  }

  public regenerateQr(tipoQrParam: Qr): void {

    const qr: any = {
      localId: this.localId,
      zonaId: this.zonaId,
      subzonaId: this.subzonaId,
      administracionId: this.administracionId,
      type: tipoQrParam.type,
      mimeType: tipoQrParam.nombre.indexOf('SVG') > 0 ? 'svg' : 'png',
      text: this.qrValue
    };

    this.qrService.regenerate(qr).subscribe(
      (qr: any) => {

        const tipoQr = this.tiposQr.find((tipoQr) => tipoQr.type == qr.type && tipoQrParam.nombre == tipoQr.nombre);

        if (tipoQr.nombre.indexOf('SVG') > 0) {
          
          tipoQr.imagen = global.API_URL + qr.imagen;
        } else {
          tipoQr.imagen = global.API_URL + qr.imagenpng;
        }
        this.noimages = false;
      },
      (error: HttpErrorResponse) => {

      });
  }

  public downloadImageQr(tipoQr: any): void {

    this.qrService.downloadImageQr(tipoQr.imagen).subscribe(
      (res: any) => {

        this._FileSaverService.save(res.body, tipoQr.imagen.substring(tipoQr.imagen.lastIndexOf("/")));
      });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

}
