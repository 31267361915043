import { Component, OnInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import * as global from '../../../services/global.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { environment } from '../../../../environments/environment';
import { BusinessEspacio } from '../../../models/business_espacio';
import { BusinessEspacioService } from '../../../services/businessEspacio';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-peticiones',
  templateUrl: './peticiones.component.html',
  styleUrls: ['./peticiones.component.css']
})
export class PeticionesComponent implements OnInit {

  public form: FormGroup;
  public confirm_title: string;
  @Input() public peticion: BusinessEspacio;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private businessEspacioService: BusinessEspacioService,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private translate: TranslateService,
    ) {

  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      id: [null],
      clienteId: [null],
      clienteproId: [null],
      destinoId: [null],
      espacioId: [null],
      fechaPeticion: [null],
      respondido: [null],
      aceptado: [null],
      directo: [null],
      local: [null],
      clientePro: [null],
    });

    this.form.patchValue(this.peticion);

  }

  aceptarPeticion(estado: boolean) {
    this.form.patchValue({ respondido: 1, aceptado: estado? 1: 0});

    this.businessEspacioService.update(this.form.value).subscribe(data => {
      this.notificationService.success('La petición ha sido actualizada.');
      this.closeModal();
      //this.cerrarModalConfirm();
    });
  }
  
  closeModal() {
    this.activeModal.close();
  }

}
