import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Idioma } from '../../../models/idioma';
import { Local } from '../../../models/local';
import { NotificationService } from '../../../services/notification.service';
import { Utils } from '../../utils/utils';
import { EspaceEnlace } from '../interfaces/espaceEnlace';
import { EspaceEnlaceIdioma } from '../interfaces/espaceEnlaceIdioma';
import { EspaceEnlaceService } from '../services/espaceEnlace.service';

type SaveAction = (model: EspaceEnlace) => Promise<EspaceEnlace>;

@Component({
  selector: 'app-espaces-enlaces',
  templateUrl: './espaces-enlaces.component.html',
  styleUrls: ['./espaces-enlaces.component.css']
})
export class EspacesEnlacesComponent implements OnInit {
  @Input()
  public espaceEnlace: EspaceEnlace;

  @Input()
  public local: Local;

  @Input()
  public languages: Idioma[];

  public form: FormGroup;
  public submited: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private espaceEnlaceService: EspaceEnlaceService,
    private notification: NotificationService
  ) {
    this.submited = false;
  }

  public ngOnInit() {
    this.form = this.buildForm();

    this.addLanguageFields();

    if (this.espaceEnlace) {
      this.form.patchValue( this.espaceEnlace);
    }
  }

  public saveForm(): void {
    this.submited = true;
    this.spinner.show();

    if (this.espaceEnlace) {
      this.updateLocalEnlace();
    } else {
      this.createLocalEnlace();
    }
  }

  public closeModal( value?: any) {
    if (value) {
      this.activeModal.close( value);
    } else {
      this.activeModal.dismiss();
    }
  }

  private createLocalEnlace() {
    this.saveModel(
      (model: EspaceEnlace) => this.espaceEnlaceService.create( model),
      this.form.value,
      'Enlace creado correctamente'
    );
  }

  private updateLocalEnlace() {
    this.saveModel(
      (model: EspaceEnlace) => this.espaceEnlaceService.update( model),
      this.form.value,
      'Enlace actualizado correctamente'
    );
  }

  private saveModel( action: SaveAction, model: EspaceEnlace, successMessage: string) {
    const fixedModel = this.fixModel( model);

    action( fixedModel).then(
      (result: EspaceEnlace) => {
        this.submited = false;
        this.closeModal( result);

        this.notification.success( successMessage);
      },

      (error: any) => {
        this.submited = false;
        this.notification.error( error);
      }
    );
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      nombreEnlace: [null],
      enlace: [null],
      idiomas: this.formBuilder.array( []),
    });
  }

  private addLanguageFields() {
    this.languages.map( (idioma: Idioma) => {
      let translation = null;

      if (this.espaceEnlace) {
        translation = this.espaceEnlace.idiomas.filter(
          (translation) => translation.idiomaId === idioma.id
        );
      }

      const languageFields = this.form.get('idiomas') as FormArray;

      languageFields.push(
        new FormGroup({
          id: new FormControl( translation ? translation.id : null),
          idiomaId: new FormControl( idioma.id),
          codigo: new FormControl( idioma.codigo),
          idioma: new FormControl( idioma.nombre),
          enlace: new FormControl( translation ? translation.enlace : null),
          nombreEnlace: new FormControl( translation ? translation.nombreEnlace : null),
        }));
    });
  }

  private fixModel( model: EspaceEnlace): EspaceEnlace {
    this.fixTranslations( model);

    model.localId = this.local.id;
    model.nombreEnlace = model.idiomas[0].nombreEnlace;
    model.enlace = model.idiomas[0].enlace;

    return model;
  }

  private fixTranslations( espaceEnlace: EspaceEnlace) {
    const translations: EspaceEnlaceIdioma[] = [];

    espaceEnlace.idiomas.forEach( (translation: EspaceEnlaceIdioma) => {
      if (!Utils.isBlank( translation.nombreEnlace) && !Utils.isBlank( translation.enlace)) {
        translations.push( translation);
      }
    })

    espaceEnlace.idiomas = translations;
  }
}
