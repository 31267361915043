import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/pagination';
import { LocalRequest } from '../interfaces/localRequest.model';
import { Local } from '../models/local';
import * as global from './global.service';
import { objsToCamelCase, objToCamelCase } from '../helpers/object.helper';
import { AuthenticationService } from './authentication.service';
import { Ruta } from '../models/ruta';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  private static LOCALS_PATH = global.API_URL + '/api/locals';
  private static ROUTES_PATH = global.API_URL + '/api/routes';

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  public byId(id: number) {
    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http
      .get<Local>(LocalService.LOCALS_PATH + '/' + id, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public search(localRequest: LocalRequest) {
    let httpParams: HttpParams = new HttpParams();

    if (localRequest.getAdministracionId()) {
      httpParams = httpParams.set(
        'administracionId',
        String(localRequest.getAdministracionId())
      );
    }

    if (localRequest.getNombre()) {
      httpParams = httpParams.set('nombre', String(localRequest.getNombre()));
    }

    if (localRequest.getOficinaturismo() !== null) {
      httpParams = httpParams.set(
        'oficinaturismo',
        String(localRequest.getOficinaturismo())
      );
    }

    if (localRequest.getIsAdministracionCategoria() !== null) {
      httpParams = httpParams.set(
        'isAdministracionCategoria',
        String(localRequest.getIsAdministracionCategoria())
      );
    }

    if (localRequest.getIsGeneral() !== null) {
      httpParams = httpParams.set(
        'isGeneral',
        String(localRequest.getIsGeneral())
      );
    }

    if (localRequest.getPerPage()) {
      httpParams = httpParams.set(
        'per_page',
        String(localRequest.getPerPage())
      );
    }

    if (localRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(localRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get(LocalService.LOCALS_PATH + `/search`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public index(localRequest: LocalRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (localRequest.getPaisId()) {
      httpParams = httpParams.set('paisId', String(localRequest.getPaisId()));
    }

    if (localRequest.getComunidadAutonomaId()) {
      httpParams = httpParams.set(
        'comunidadAutonomaId',
        String(localRequest.getComunidadAutonomaId())
      );
    }

    if (localRequest.getProvinciaId()) {
      httpParams = httpParams.set(
        'provinciaId',
        String(localRequest.getProvinciaId())
      );
    }

    if (localRequest.getCiudadId()) {
      httpParams = httpParams.set(
        'ciudadId',
        String(localRequest.getCiudadId())
      );
    }

    if (localRequest.getUserId()) {
      httpParams = httpParams.set('userId', String(localRequest.getUserId()));
    }

    if (localRequest.getClienteId()) {
      httpParams = httpParams.set(
        'clienteId',
        String(localRequest.getClienteId())
      );
    }

    if (localRequest.getNombre()) {
      httpParams = httpParams.set('nombre', String(localRequest.getNombre()));
    }

    if (localRequest.getRutaId()) {
      httpParams = httpParams.set('rutaId', String(localRequest.getRutaId()));
    }

    if (localRequest.getActivo()) {
      httpParams = httpParams.set('activo', String(localRequest.getActivo()));
    }

    if (localRequest.getCiudad()) {
      httpParams = httpParams.set('ciudad', String(localRequest.getCiudad()));
    }

    if (localRequest.getPeticionesControl()) {
      httpParams = httpParams.set(
        'peticionescontrol',
        String(localRequest.getPeticionesControl())
      );
    }

    if (localRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(localRequest.getIdiomas()));
    }

    if (localRequest.getZonas()) {
      httpParams = httpParams.set('zonas', String(localRequest.getZonas()));
    }

    if (localRequest.getZonaSubzonas()) {
      httpParams = httpParams.set(
        'zonaSubzonas',
        String(localRequest.getZonaSubzonas())
      );
    }

    if (localRequest.getOficinaturismo() !== null) {
      httpParams = httpParams.set(
        'oficinaturismo',
        String(localRequest.getOficinaturismo())
      );
    }

    if (localRequest.getIsAdministracionCategoria() !== null) {
      httpParams = httpParams.set(
        'isAdministracionCategoria',
        String(localRequest.getIsAdministracionCategoria())
      );
    }

    if (localRequest.getPerPage()) {
      httpParams = httpParams.set(
        'per_page',
        String(localRequest.getPerPage())
      );
    }

    if (localRequest.getPage()) {
      httpParams = httpParams.set(
        'page',
        String(localRequest.getPage())
      );
    }

    if (localRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(localRequest.getOrderBy()));
    }

    if (localRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', localRequest.getSortBy());
    }

    if (localRequest.getPlanId()) {
      httpParams = httpParams.set('planId', String(localRequest.getPlanId()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get(LocalService.LOCALS_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public indexNotifications(route: Ruta): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get(LocalService.ROUTES_PATH + '/ruta/notificacion/' + route.id, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public indexLocalsRoute(route: Ruta): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get(LocalService.ROUTES_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public create(local: Local): Observable<Local> {
    return this.http
      .post<Local>(LocalService.LOCALS_PATH, local, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Local>(response);
        })
      );
  }

  public transfer(request): Observable<any> {

    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<any>(LocalService.LOCALS_PATH + '/traspasolocales', request, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<any>(response);
      })
    );
  }

  public update(local: Local): Observable<Local> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<Local>(
        LocalService.LOCALS_PATH + '/' + local.id,
        local,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<Local>(response);
        })
      );
  }

  public desactivar(): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.put<any>(
      LocalService.LOCALS_PATH + '/desactivar',
      {},
      this.httpOptions
    );
  }

  public delete(local: Local) {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(LocalService.LOCALS_PATH + '/' + local.id, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public haveSlots(haveSlots: number, planId?: number, page?: number){
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('have_slots',String(haveSlots));

    if( planId !== undefined ){
      httpParams = httpParams.set('plan_id',String(planId));
    }

    if( page !== undefined ){
      httpParams = httpParams.set('page', String(page));
    }

    let clientId = this.auth.currentCustomerValue.id;

    if( clientId !== undefined ){
      httpParams = httpParams.set('clienteId', String(clientId));
    }
    
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get<Pagination>(LocalService.LOCALS_PATH + '/slots', this.httpOptions);
  }
}
