import * as global from './global.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/pagination';
import { Event } from '../models/event';
import { objsToCamelCase, objToCamelCase } from '../helpers/object.helper';
import { AuthenticationService } from './authentication.service';
import { EventRequest } from '../interfaces/ubEventRequest.model';
import { EventImage } from '../v2/events/interfaces/eventImage';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private static EVENTS_PATH = global.API_URL + '/api/ubevents';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  public byId(id: number) {
    let httpParams: HttpParams = new HttpParams();
    
    httpParams = httpParams.set('idiomas', 'true');

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Event>(EventService.EVENTS_PATH + '/' + id, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public search(eventRequest: EventRequest) {
    let httpParams: HttpParams = new HttpParams();

    if (eventRequest.getAdministracionId()) {
      httpParams = httpParams.set(
        'administracionId',
        String(eventRequest.getAdministracionId())
      );
    }

    if (eventRequest.getNombre()) {
      httpParams = httpParams.set('nombre', String(eventRequest.getNombre()));
    }


    if (eventRequest.getIsAdministracionCategoria() !== null) {
      httpParams = httpParams.set(
        'isAdministracionCategoria',
        String(eventRequest.getIsAdministracionCategoria())
      );
    }

    if (eventRequest.getPerPage()) {
      httpParams = httpParams.set(
        'per_page',
        String(eventRequest.getPerPage())
      );
    }

    if (eventRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(eventRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get(EventService.EVENTS_PATH + `/search`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public index(eventRequest: EventRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (eventRequest.getPaisId()) {
      httpParams = httpParams.set('paisId', String(eventRequest.getPaisId()));
    }

    if (eventRequest.getComunidadAutonomaId()) {
      httpParams = httpParams.set(
        'comunidadAutonomaId',
        String(eventRequest.getComunidadAutonomaId())
      );
    }

    if (eventRequest.getProvinciaId()) {
      httpParams = httpParams.set(
        'provinciaId',
        String(eventRequest.getProvinciaId())
      );
    }

    if (eventRequest.getCiudadId()) {
      httpParams = httpParams.set(
        'ciudadId',
        String(eventRequest.getCiudadId())
      );
    }

    if (eventRequest.getUserId()) {
      httpParams = httpParams.set('userId', String(eventRequest.getUserId()));
    }

    if (eventRequest.getClienteId()) {
      httpParams = httpParams.set(
        'clienteId',
        String(eventRequest.getClienteId())
      );
    }

    if (eventRequest.getNombre()) {
      httpParams = httpParams.set('nombre', String(eventRequest.getNombre()));
    }

    if (eventRequest.getRutaId()) {
      httpParams = httpParams.set('rutaId', String(eventRequest.getRutaId()));
    }

    if (eventRequest.getActivo()) {
      httpParams = httpParams.set('activo', String(eventRequest.getActivo()));
    }

    if (eventRequest.getCiudad()) {
      httpParams = httpParams.set('ciudad', String(eventRequest.getCiudad()));
    }


    if (eventRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(eventRequest.getIdiomas()));
    }

    if (eventRequest.getIsAdministracionCategoria() !== null) {
      httpParams = httpParams.set(
        'isAdministracionCategoria',
        String(eventRequest.getIsAdministracionCategoria())
      );
    }

    if (eventRequest.getPerPage()) {
      httpParams = httpParams.set(
        'per_page',
        String(eventRequest.getPerPage())
      );
    }

    if (eventRequest.getPage()) {
      httpParams = httpParams.set(
        'page',
        String(eventRequest.getPage())
      );
    }

    if (eventRequest.getLocations()) {
      httpParams = httpParams.set(
        'locations',
        String(eventRequest.getLocations())
      );
    }

    if (eventRequest.getSponsors()) {
      httpParams = httpParams.set(
        'sponsors',
        String(eventRequest.getSponsors())
      );
    }

    if (eventRequest.getTickets()) {
      httpParams = httpParams.set(
        'tickets',
        String(eventRequest.getTickets())
      );
    }

    if (eventRequest.getProducts()) {
      httpParams = httpParams.set(
        'products',
        String(eventRequest.getProducts())
      );
    }

    if (eventRequest.getZones()) {
      httpParams = httpParams.set(
        'zones',
        String(eventRequest.getZones())
      );
    }

    if (eventRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(eventRequest.getOrderBy()));
    }

    if (eventRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', eventRequest.getSortBy());
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get(EventService.EVENTS_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public create(event: Event): Observable<Event> {
    return this.http
      .post<Event>(EventService.EVENTS_PATH, event, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Event>(response);
        })
      );
  }

  public transfer(request): Observable<any> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<any>(EventService.EVENTS_PATH + '/traspasoeventes', request, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<any>(response);
      })
    );
  }

  public update(event: Event): Observable<Event> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<Event>(
        EventService.EVENTS_PATH + '/' + event.id,
        event,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<Event>(response);
        })
      );
  }

  public desactivar(): Observable<any> {
    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.put<any>(
      EventService.EVENTS_PATH + '/desactivar',
      {},
      this.httpOptions
    );
  }

  public delete(event: Event) {
    const httpParams: HttpParams = new HttpParams();
    
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .delete<any>(EventService.EVENTS_PATH + '/' + event.id, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public haveSlots(haveSlots: number,page?: number){
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('have_slots',String(haveSlots));

    if( page !== undefined ){
      httpParams = httpParams.set('page',String(page));
    }

    let clientId = this.auth.currentCustomerValue.id;

    if( clientId !== undefined ){
      httpParams = httpParams.set('clienteId',String(clientId));
    }
    
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get<Pagination>(EventService.EVENTS_PATH + '/slots', this.httpOptions);
  }

  public show(id: number, eventRequest: EventRequest): Observable<Event> {
    let httpParams: HttpParams = new HttpParams();

    if (eventRequest.getPhotos())
      httpParams = httpParams.set("photos", String(eventRequest.getPhotos()));

    if (eventRequest.getPerPage())
      httpParams = httpParams.set("perPage", String(eventRequest.getPerPage()));

    if (eventRequest.getOrderBy()) {
      httpParams = httpParams.set("orderBy", eventRequest.getOrderBy());
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Event>(EventService.EVENTS_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((event: any) => {
          return objToCamelCase<Event>(event);
        })
      );
  }

  public photos(event: Event): Observable<Event> {
    this.httpOptions = {
      headers: this.httpHeaders.delete("Content-Type"),
      params: {},
    };

    const formData: FormData = new FormData();

    event.photos.map((element: EventImage, index: number) => {
      if (element.id) {
        formData.append(`photos[${index}][id]`, String(element.id));
      }
      if (element.imagen) {
        formData.append(`photos[${index}][imagen]`, element.imagen);
      }
    });

    return this.http.post<Event>(
      EventService.EVENTS_PATH + `/${event.id}/photos`,
      formData,
      this.httpOptions
    );
  }

  public removePhoto(event: Event, eventImage: EventImage): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.delete<any>(
      EventService.EVENTS_PATH + `/${event.id}/photos/${eventImage.id}`,
      this.httpOptions
    );
  }
}
