import { PaginationRequest } from './paginationRequest.model';

export class PaisRequest extends PaginationRequest {

  private comunidades: boolean;
  private provincias: boolean;

  constructor() {
    super();
  }

  public getComunidades(): boolean {

    return this.comunidades;
  }

  public setComunidades(comunidades: boolean): void {

    this.comunidades = comunidades;
  }

  public getProvincias(): boolean {

    return this.provincias;
  }

  public setProvincias(provincias: boolean): void {

    this.provincias = provincias;
  }

}