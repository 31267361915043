import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractBlacklistService } from './abstract.service';
import { objToCamelCase } from '../../../../helpers/object.helper';
import { BlacklistRequest } from '../models/blacklist-request.model';
import { BlacklistWord } from '../interfaces/blacklist-word';

@Injectable({
  providedIn: 'root'
})
export class BlacklistService extends AbstractBlacklistService {
  constructor( http: HttpClient) {
    super( http, 'blacklist');
  }

  public byId( id: number) {
    let httpParams = new HttpParams();

    this.httpOptions = this.buildOptions( httpParams);
    const url = this.byIdUrl( id);

    return this.processResponse(
      this.http.get<Event>( url, this.httpOptions)
    );
  }

  public index( request: BlacklistRequest) {
    const httpParams = this.buildParams( request);
    this.httpOptions = this.buildOptions( httpParams);

    return this.processResponse(
      this.http.get( this.indexUrl(), this.httpOptions)
    );
  }

  public show( id: number, request: BlacklistRequest): Promise<BlacklistWord> {
    let httpParams: HttpParams = new HttpParams();

    if (request.getPerPage()) {
      httpParams = httpParams.set('per_page', String(request.getPerPage()));
    }

    if (request.getOrderBy()) {
      httpParams = httpParams.set('order', String(request.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<BlacklistWord>( this.byIdUrl( id), this.httpOptions)
      .pipe(
        map( (blacklistWord: BlacklistWord) => objToCamelCase<BlacklistWord>( blacklistWord))
      )
      .toPromise();
  }

  public create( blacklistWord: BlacklistWord): Promise<BlacklistWord> {
    this.httpOptions = this.buildOptions( {}, 'Content-Type');

    return this.http.post<BlacklistWord>(
        this.postUrl(),
        blacklistWord,
        this.httpOptions
      ).toPromise();
  }

  public update( blacklistWord: BlacklistWord): Promise<BlacklistWord> {
    this.httpOptions = this.buildOptions( {}, 'Content-Type');

    return this.http.put<BlacklistWord>(
        this.putUrl( blacklistWord.id),
        blacklistWord,
        this.httpOptions
      ).toPromise();
  }

  public fillData( blacklistWord: BlacklistWord): FormData {
    const formData: FormData = new FormData();

    const fields = [
      'texto',
    ];

    fields.forEach( field => {
      if (blacklistWord[field]) {
        formData.append( field, String( blacklistWord[field]));
      }
    });

    return formData;
  }

  public delete( blacklistWord: BlacklistWord): Promise<any> {
    this.httpOptions = this.buildOptions();

    return this.http.delete<any>( this.deleteUrl( blacklistWord.id), this.httpOptions)
      .toPromise();
  }

  public containsWord( text: string): Promise<boolean> {
    this.httpOptions = this.buildOptions();
    const obj: any = {};
    obj.text = text;

    return this.processResponse(
        this.http.post<any>( this.isForbiddenWordUrl( text), obj, this.httpOptions)
      ).then( (data: any) => data.result);
  }

  private isForbiddenWordUrl( text: string): string {
    //const encodedText = encodeURIComponent( text);

   //return `${this.baseUrl()}/is-forbidden/?text=${encodedText}`;
    return `${this.baseUrl()}/is-forbidden`;
  }
}
