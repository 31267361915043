import { DeployEnvironment } from './enums/deployEnvironment.enum';

export const environment = {
  deploy: DeployEnvironment.Production,
  minBalance: 30,
  basicActivation: true,
  spainId: 67,
  planBasicId: 1,
  roleAdminId: 1,
  cupon: 'PROUPGRADE',
  whatsapp : '34654187085'
};
