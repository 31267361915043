import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Events } from '../../../services/events.service';


@Component({
  selector: 'app-plan-buy-locals-ask',
  templateUrl: './plan-buy-locals-ask.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./plan-buy-locals-ask.component.scss'],
})
export class PlanBuyLocalsAskComponent implements OnInit {

  @Input()public basic: boolean = false;
  @Input()public pro: boolean = false;
  @Input()public premium: boolean = false;
  @Input()public activa: boolean = false;
  @Input()public funcionpro: boolean = false;
  @Input()public funcionpremium: boolean = false;
  @Input()public ispromo: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private event: Events
  ) {
  }

  ngOnInit() {
  }

  exit() {
    this.activeModal.close(0);
  }

  buy(planId?: any) {
    let id = 1;
    if(planId){
      id = planId;
    }

    this.activeModal.close(id);
  }

}
