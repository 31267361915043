import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase, objToCamelCase } from '../helpers/object.helper';
import { PaisRequest } from '../interfaces/paisRequest.model';
import { Pais } from '../models/pais';

@Injectable({
  providedIn: 'root'
})
export class PaisService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(paisRequest: PaisRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (paisRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(paisRequest.getPerPage()));
    }

    if (paisRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(paisRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/countries`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase(response);
      })
    );
  }

  public show(id: number, paisRequest: PaisRequest): Observable<Pais> {

    let httpParams: HttpParams = new HttpParams();

    if (paisRequest.getComunidades()) {
      httpParams = httpParams.set('comunidades', String(paisRequest.getComunidades()));
    }

    if (paisRequest.getProvincias()) {
      httpParams = httpParams.set('provincias', String(paisRequest.getProvincias()));
    }

    if (paisRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(paisRequest.getPerPage()));
    }

    if (paisRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(paisRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pais>(global.API_URL + `/api/countries/${id}`, this.httpOptions).pipe(
      map(
        (response: any) => {
          return objToCamelCase<Pais>(response);
        })
    );
  }

}
