import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { CaracteristicaRequest } from '../interfaces/caracteristicaRequest.model';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { Caracteristica } from '../models/caracteristica';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(caracteristicaRequest: CaracteristicaRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (caracteristicaRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(caracteristicaRequest.getIdiomas()));
    }

    if (caracteristicaRequest.getLocalId()) {
      httpParams = httpParams.set('localId', String(caracteristicaRequest.getLocalId()));
    }

    if (caracteristicaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(caracteristicaRequest.getPerPage()));
    }

    if (caracteristicaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(caracteristicaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/caracteristicas`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(caracteristica: Caracteristica) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };
    return this.http.post<Caracteristica>(global.API_URL + '/api/caracteristicas', caracteristica, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(caracteristica: Caracteristica) {
    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };
    return this.http.put<Caracteristica>(global.API_URL + '/api/caracteristicas/' + caracteristica.id,
     caracteristica, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }
  public delete(caracteristica: Caracteristica) {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };
    return this.http.delete<any>(global.API_URL + '/api/caracteristicas/' + caracteristica.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }
}
