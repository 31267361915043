import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomersRequest } from '../../customers/models/customersRequest.model';
import { CustomerService } from '../../../services/customers.service';
import { Cliente } from '../../../models/cliente';
import { Administracion } from '../../../models/administracion';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-espaces-customers',
  templateUrl: './espaces-customers.component.html',
  styleUrls: ['./espaces-customers.component.css']
})


export class EspacesCustomersComponent implements OnInit {

  @Input() public search: string;
  @Input() public searchlocal: string;
  public customers: Array<Cliente>;
  public destinations: Array<Administracion>;
  public selectedcustomer: any;
  public selectedadmin: any;
  

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private customerService: CustomerService) {

  }

  ngOnInit() {
    this.searchUsers();
  }

  selectCustomer(customer, type) {
    if (type === 0) {
      this.selectedcustomer = customer;
      this.selectedadmin = null;
      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].id === this.selectedcustomer.id) {
          this.customers[i].selected = true;
        } else {
          this.customers[i].selected = false;
        }
      }
      for (let i = 0; i < this.destinations.length; i++) {
          this.destinations[i].selected = false;
      }
    } else {
      this.selectedadmin = customer;
      this.selectedcustomer = null;
      for (let i = 0; i < this.destinations.length; i++) {
        if (this.destinations[i].id === this.selectedadmin.id) {
          this.destinations[i].selected = true;
        } else {
          this.destinations[i].selected = false;
        }
      }
      for (let i = 0; i < this.customers.length; i++) {
        this.customers[i].selected = false;
    }
    }
  }


  public searchUsers(): void {
    this.spinner.show();
    const customerRequest: CustomersRequest = new CustomersRequest();

    if (this.search !== '') {
      customerRequest.setNombre(this.search);
    }
    if (this.searchlocal !== '') {
      customerRequest.setLocalNombre(this.searchlocal);
    }
    customerRequest.setUser(true);

    this.customerService.search(customerRequest).toPromise().then(
      (pagination: any) => {
        if (pagination) {
          this.customers = pagination.clientes;
          this.destinations = pagination.administraciones;
        }
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
      });
      
  }

  public selected(): void {
    if (this.selectedcustomer || this.selectedadmin) {
      const obj: any = {};
      obj.customer = this.selectedcustomer;
      obj.destination = this.selectedadmin;
      this.activeModal.close(obj);
    } else {
      this.notification.error('No has seleccionado ningun cliente');
    }
  }

}
