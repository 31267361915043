import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Events } from '../../../services/events.service';


@Component({
  selector: 'app-plan-basic-info',
  templateUrl: './plan-basic-info.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./plan-basic-info.component.scss'],
})
export class PlanBasicInfoComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
    private event: Events
  ) {
  }

  ngOnInit() {
  }

  exit() {
    localStorage.setItem('askBasicLimitations', moment().toISOString());
    this.activeModal.close();
  }

  goToPro() {
    localStorage.setItem('askBasicLimitations', moment().toISOString());
    this.activeModal.close();
    this.event.publish('upgradePlan');
  }
}
