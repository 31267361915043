import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'jquery';
import { Observable } from 'rxjs';
import { objsToCamelCase } from '../helpers/object.helper';
import { Slot } from '../models/slot';
import { AuthenticationService } from './authentication.service';
import * as global from "./global.service";

@Injectable({
  providedIn: 'root'
})
export class SlotService {

  public slot: Slot;

  private static LOCALS_PATH = global.API_URL + "/api/slots";
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }

  public setSlots(data) {
    this.slot = data;
    return data;
  }

  public updateSlotsPremiumNumber(cont: number) {
    this.slot.slots_available_premium += cont;
  }

  public updateSlotsProNumber(cont: number) {
    this.slot.slots_available_pro += cont;
  }

  public getSlots() {
    this.auth.currentUser.subscribe((d) => {
      if (d.user && d.user.planId !== null && d.user.planId !== 1) {

        if (this.slot === undefined) {
          this.show().subscribe(
            (d: Slot) => this.setSlots(d),
            (e) => console.error(e.error));
        } else {
          return this.slot;
        }
      } else {
        return undefined;
      }

    });

  }

  public refresh() {
    if (this.auth.currentUserValue.user.plan.id !== 1) {
      this.show().subscribe(
        (d: Slot) => this.setSlots(d),
        (e) => console.error(e.error));
    }
  }

  public decrease() {

    if (this.auth.currentUserValue.user.plan.id !== 1) {

      if (this.slot !== undefined) {
        if (this.slot.slots_available_pro > 0) {
          this.slot.slots_available_pro--;
        }
      } else {
        return undefined;
      }

    }

  }

  public show(): Observable<Slot> {

    this.httpOptions = { headers: this.httpHeaders };
    return this.http.post<Slot>(SlotService.LOCALS_PATH + '/number-slots-client-id', this.httpOptions);

  }

  public convertLocalToPro(idLocal: number): Observable<Slot> {

    this.httpOptions = { headers: this.httpHeaders };
    return this.http.post<Slot>(SlotService.LOCALS_PATH + '/convert-local-to-pro/' + idLocal, this.httpOptions);

  }

  // TODO
  public upgradeLocal(idLocal: number, planId: number): Observable<Slot> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = { 
      headers: this.httpHeaders, 
    };

    return this.http.post<Slot>(SlotService.LOCALS_PATH + '/upgrade-local/' + idLocal, { 'plan_id': planId }, this.httpOptions);

  }

  public convertLocalToProAdmin(compra) {
    return this.http.post(SlotService.LOCALS_PATH + '/create-slot-by-admin', compra);
  }
}
