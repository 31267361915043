import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/pagination';
import { LocalZonaRequest } from '../interfaces/locaZonaRequest.model';
import { LocalZona } from '../models/localZona';
import * as global from './global.service';
import { LocalSubzona } from '../models/localSubzona';
import { objsToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class LocalZonaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(localZonaRequest: LocalZonaRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (localZonaRequest.getLocalId()) {
      httpParams = httpParams.set('localId', String(localZonaRequest.getLocalId()));
    }

    if (localZonaRequest.getLocal()) {
      httpParams = httpParams.set('local', String(localZonaRequest.getLocal()));
    }

    if (localZonaRequest.getLocalSubzonas()) {
      httpParams = httpParams.set('localSubzonas', String(localZonaRequest.getLocalSubzonas()));
    }

    if (localZonaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(localZonaRequest.getPerPage()));
    }

    if (localZonaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(localZonaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/localszone`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(localZona: LocalZona) {

    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('subzonas', String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<LocalZona>(global.API_URL + '/api/localszone', localZona, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(localZona: LocalZona) {

    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('subzonas', String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<LocalZona>(global.API_URL + '/api/localszone/' + localZona.id, localZona, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(localZona: LocalZona) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/localszone/' + localZona.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
