import * as global from '../../../services/global.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { objsToCamelCase } from '../../../helpers/object.helper';
import { Pagination } from '../../../models/pagination';
import { Espace } from '../interfaces/espace';
import { EspacesRequest } from '../models/espacesRequest.model';
import { EspaceIdioma } from '../interfaces/espaceIdioma';
import { EspacePhoto } from '../interfaces/espacePhoto';
import { isString } from 'util';

@Injectable({
  providedIn: 'root'
})
export class EspaceService {
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public index( request: EspacesRequest): Observable<Pagination> {
    const httpParams = this.buildParams( request);
    this.httpOptions = this.buildOptions( httpParams);

    return this.processResponse(
      this.http.get( this.indexUrl(), this.httpOptions)
    );
  }

  public show( id: number, request: EspacesRequest): Observable<Espace> {
    const httpParams = this.buildParams( request);
    this.httpOptions = this.buildOptions( httpParams);

    return this.processResponse(
      this.http.get<Espace>( this.byIdUrl( id), this.httpOptions)
    );
  }

  public create( espace: Espace): Observable<Espace> {
    this.httpOptions = this.buildOptions( {}, 'Content-Type');

    return this.http.post<Espace>( this.postUrl(), espace, this.httpOptions);
  }

  public update( espace: Espace): Observable<Espace> {
    this.httpOptions = this.buildOptions( {}, 'Content-Type');

    return this.http.put<Espace>( this.putUrl( espace.id), espace, this.httpOptions);
  }

  public fillData( espace: Espace): FormData {
    const formData = new FormData();

    const fields = [
      'clienteId',
      'administracionId',
      'ciudadId',
      'clienteId',
      'descripcion',
    ];

    fields.forEach( field => {
      if (espace[field]) {
        formData.append( field, String( espace[field]));
      }
    });

    let i = 0;

    espace.idiomas.map((element: EspaceIdioma) => {
      if (element.descripcion) {
        if (element.id) {
          formData.append(`idiomas[${i}][id]`, String(element.id));
        }

        formData.append(`idiomas[${i}][idiomaId]`, String(element.idiomaId));
        formData.append(`idiomas[${i}][descripcion]`, element.descripcion);

        i++;
      }
    });

    return formData;
  }

  public delete( espace: Espace): Observable<any> {
    this.httpOptions = this.buildOptions();

    return this.http.delete<any>( this.deleteUrl( espace.id), this.httpOptions);
  }

  public photos(espace: Espace): Observable<Espace> {
    this.httpOptions = {
      headers: this.httpHeaders.delete('Content-Type'),
      params: {}
    };
    const formData: FormData = new FormData();

    espace.photos.map(
      (element: EspacePhoto, index: number) => {
        if (element.id) {
          formData.append(`photos[${index}][id]`, String(element.id));
        }

        if (element.imagen) {
          formData.append(`photos[${index}][imagen]`, element.imagen);
        }
      });

    return this.http.post<Espace>( this.photosUrl( espace.id), formData, this.httpOptions);
  }

  public removePhoto( espace: Espace, photo: EspacePhoto): Observable<any> {
    this.httpOptions = this.buildOptions();

    return this.http.delete<any>( this.photoByIdUrl( espace.id, photo.id), this.httpOptions);
  }

  private buildParams( request: EspacesRequest): HttpParams {
    let result = new HttpParams();

    const paramNames = [
      'perPage',
      'orderBy',
      'idiomas',
      'administracionId',
      'clienteId',
      'administracion',
      'photos',
    ];

    paramNames.forEach( param => {
      let paramName: string;
      let getter: string;

      if (isString( param)) {
        paramName = param;
        getter = this.buildGetter( param)
      } else {
        paramName = param[1];
        getter = this.buildGetter( param[0])
      }

      const value = request[getter]();

      if (value) {
        result = result.set( paramName, String( value));
      }
    });

    return result;
  }

  private buildOptions( httpParams?: HttpParams|Object, excludedHeader?: string): Object {
    let headers = this.httpHeaders;

    if (excludedHeader) {
      headers = headers.delete( excludedHeader);
    }

    return {
      headers,
      params: httpParams || {}
    };
  }

  private indexUrl(): string {
    return this.baseUrl();
  }

  private byIdUrl( id: number): string {
    return this.baseUrl() + `/${id}`;
  }

  private postUrl(): string {
    return this.baseUrl();
  }

  private putUrl( id: number): string {
    return this.baseUrl() + `/${id}`;
  }

  private deleteUrl( id: number): string {
    return this.baseUrl() + `/${id}`;
  }

  private photosUrl( id: number): string   {
    return this.baseUrl() + `/${id}/photos`;
  }

  private photoByIdUrl( espaceId: number, photoId: number): string {
    return this.photosUrl( espaceId) + `/${photoId}`;
  }

  private baseUrl(): string {
    return global.API_URL + `/api/locals`;
  }

  private buildGetter( name: string): string {
    return 'get' + name.charAt( 0).toUpperCase() + name.substring( 1);
  }

  private processResponse( response: Observable<any>): Observable<any> {
    return response.pipe(
        map( (response: any) => objsToCamelCase( response)
      ));
  }
}
