import { PaginationRequest } from "./paginationRequest.model";

export class RedSocialRequest extends PaginationRequest {
  private locales: boolean;

  constructor() {
    super();
  }

  /**
   *
   */
  public getLocales(): boolean {
    return this.locales;
  }

  /**
   *
   */
  public setLocales(locales: boolean): void {
    this.locales = locales;
  }
}
