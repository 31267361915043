import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionService } from '../../../services/question.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public questions: any;
  public haveTranslations = true;
  constructor(
    private modal: NgbActiveModal,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private questionService: QuestionService
  ) {
    this.getQuestions();
  }

  ngOnInit() {}

  getQuestions() {
    let data: any;
    let language: string = this.translate.currentLang;
    // Temporalmente se pone en ES
    language = 'es';

    this.spinner.show();

    this.questionService.getAll().subscribe(
      (response: any) => {
        data = response.data;
        this.questions = data.filter(questions => questions.idiomas.some(i => i.idioma.codigo === language));
        if (this.questions.length <= 0) {
          this.haveTranslations = false;
          this.questions = data;
        }
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      });
  }

  closeModal() {
    this.modal.close();
  }

}
