import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../models/pagination';
import { QrRequest } from '../interfaces/qrRequest.model';
import { Qr } from '../models/qr';
import * as global from './global.service';
import { objsToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(qrRequest: QrRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (qrRequest.getTapaId()) {
      httpParams = httpParams.set('tapaId', String(qrRequest.getTapaId()));
    }

    if (qrRequest.getRutaId()) {
      httpParams = httpParams.set('rutaId', String(qrRequest.getRutaId()));
    }

    if (qrRequest.getLocalId()) {
      httpParams = httpParams.set('localId', String(qrRequest.getLocalId()));
    }

    if (qrRequest.getZonaId()) {
      httpParams = httpParams.set('zonaId', String(qrRequest.getZonaId()));
    }

    if (qrRequest.getSubZonaId()) {
      httpParams = httpParams.set('subzonaId', String(qrRequest.getSubZonaId()));
    }

    if (qrRequest.getAdministracionId()) {
      httpParams = httpParams.set('administracionId', String(qrRequest.getAdministracionId()));
    }

    if (qrRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(qrRequest.getPerPage()));
    }

    if (qrRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(qrRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/qr`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(qr: any) {
    
    let httpParams: HttpParams = new HttpParams();
    
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Qr>(global.API_URL + '/api/qr', qr, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public regenerate(qr: any) {
    
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('regenerate', String(true));
    
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Qr>(global.API_URL + '/api/qr', qr, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public downloadImageQr(imagen: any) {
    let httpHeaders = new HttpHeaders({
      //'Access-Control-Allow-Origin': '*',
      //'Access-Control-Request-Headers': '*',
      'Content-Type': '*' 
    }); 

    return this.http.get(imagen, {
      headers: httpHeaders,
      observe: 'response',
      responseType: 'blob'
    });
  }
}
