import { PaginationRequest } from './paginationRequest.model';

export class CiudadRequest extends PaginationRequest {

  private paisId: number;
  private provinciaId: number;
  private ciudadId: number;
  private nombre: string;
  private unblock: number;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getPaisId(): number {
    return this.paisId;
  }

  /**
   * 
   */
  public setPaisId(paisId: number): void {
    this.paisId = paisId;
  }

  /**
   * 
   */
  public getProvinciaId(): number {
    return this.provinciaId;
  }

  /**
   * 
   */
  public setProvinciaId(provinciaId: number): void {
    this.provinciaId = provinciaId;
  }

  /**
   * 
   */
  public getCiudadId(): number {
    return this.ciudadId;
  }

  /**
   * 
   */
  public setCiudadId(ciudadId: number): void {
    this.ciudadId = ciudadId;
  }

  /**
   * 
   */
  public getNombre(): string {
    return this.nombre;
  }

  /**
   * 
   */
  public setNombre(nombre: string): void {
    this.nombre = nombre;
  }

  /**
   * 
   */
  public getUnblock(): number {
    return this.unblock;
  }

  /**
   * 
   */
  public setUnblock(unblock: number): void {
    this.unblock = unblock;
  }

}