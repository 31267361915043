import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { NotificationService } from '../../../services/notification.service';


export interface Documents {
  masterServiceAgreement: boolean;
  dataProcessingAgreement: boolean;
  readAndConfirmTerms: boolean;
}

@Component({
  selector: 'app-customers-wizard-terms',
  templateUrl: './customers-wizard-terms.component.html',
  styleUrls: ['./customers-wizard-terms.component.scss'],
})
export class CustomersWizardTermsComponent implements OnInit {
  public form: FormGroup;

  @Input() documentsRead: Documents;
  @Output() documentsChange = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      accept: [null, Validators.required],
    });
  }

  public acceptTerms(): boolean {
    if ( !this.documentsRead.masterServiceAgreement && !this.documentsRead.dataProcessingAgreement) {
      this.notificationService.errorTranslate('No ha leido los archivos todavía');
      return false;
    }

    if ( !this.documentsRead.masterServiceAgreement) {
      this.notificationService.errorTranslate('No ha leido el Acuerdo de servicio principal');
      return false;
    }

    if ( !this.documentsRead.dataProcessingAgreement) {
      this.notificationService.errorTranslate('No ha leido el Acuerdo de tratamiento de datos');
      return false;
    }

    if ( !this.documentsRead.readAndConfirmTerms) {
      this.notificationService.errorTranslate('Debe aceptar los términos y condiciones');
      return false;
    }
    return true;
  }

  readMasterServiceAgreement() {
    window.open('https://resources.unblockthecity.com/docs/website/2023_condiciones_uso_unBlock.pdf','_blank');
    this.documentsChange.emit(this.documentsRead);
    this.documentsRead.masterServiceAgreement = true;
  }

  readDataProcessingAgreement() {
    window.open('https://www.unblockthecity.com/privacidad','_blank');
    this.documentsChange.emit(this.documentsRead);
    this.documentsRead.dataProcessingAgreement = true;
  }
}
