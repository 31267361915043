import * as global from '../../../services/global.service';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../services/authentication.service';
import { NotificationService } from '../../../services/notification.service';
import { CompraService } from '../../customers/services/compra.service';
import { PlanService } from '../../../services/plan.service';
import { LocalService } from '../../../services/local.service';
import { LocalRequest } from '../../../interfaces/localRequest.model';
import { PlanRequest } from '../../../models/plan-request.model';
import { Plan } from '../../../models/plan';
import { Compra } from '../../customers/interfaces/compra';
import { Local } from '../../../models/local';
import { Pagination } from '../../../models/pagination';
import { User } from '../../../models/user';
import { Events } from '../../../services/events.service';
import { CustomersWizardTermsComponent } from '../../customers/customers-wizard-terms/customers-wizard-terms.component';

@Component({
  selector: 'app-plan-upgrade',
  templateUrl: './plan-upgrade.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./plan-upgrade.component.scss'],
})
export class PlanUpgradeComponent implements OnInit {
  @Input() public locales: Array<Local>;
  @ViewChild(CustomersWizardTermsComponent, {
    read: CustomersWizardTermsComponent,
  })

  // Variables del Componente
  public wizardTerms: CustomersWizardTermsComponent;
  public plan: Plan;
  public currencyPipe: CurrencyPipe;
  public stepVisible = 1;
  public formImporte: FormGroup;
  public readonly IVA: number = 21;
  public paymentStatus = 0;
  // Datos a enviar al Iframe del TPV
  public product = '';
  public amount = ''; // TPV: Stores amount to be passed to tpv, as integer, no formatting, just money numbers in string
  public order: Compra;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private compraService: CompraService,
    private localService: LocalService,
    private planService: PlanService,
    private event: Events
  ) {
    this.currencyPipe = new CurrencyPipe('es');
  }

  ngOnInit() {
    this.formImporte = this.formBuilder.group({
      cantidad: [0, [Validators.required, Validators.pattern(/^[0-9\.{1}]+$/)]],
      cantidadLocales: [0, [Validators.required]],
      locales: this.formBuilder.array(
        [],
        [Validators.required, Validators.min(1), this.check]
      ),
      formaPagosId: [1, Validators.required],
      comentario: ['Compra plan desde el Panel'],
      planId: [1],
    });
    this.review();
  }

  public async review(): Promise<void> {
    const planRequest: PlanRequest = new PlanRequest();

    this.plan = await this.planService
      .show(environment.planBasicId + 1, planRequest)
      .toPromise()
      .then(
        (plan: Plan) => {
          return plan;
        },
        (error: HttpErrorResponse) => {
          this.notificationService.error(
            this.translateService.instant(
              // tslint:disable-next-line: max-line-length
              'Ocurrió un error, vuelva a intentarlo. Si persiste pongase en contacto con desarrollo@unblockthecity.com y atenderemos su problema.'
            )
          );

          return Promise.resolve({ id: null });
        }
      );

    this.formImporte.get('planId').setValue(this.plan.id);

    if (this.locales.length < 1) {
      const user: User = this.authenticationService.currentUserValue.user;
      const localRequest: LocalRequest = new LocalRequest();

      localRequest.setClienteId(user.cliente ? user.cliente.id : null);

      this.locales = await this.localService
        .index(localRequest)
        .toPromise()
        .then(
          (pagination: Pagination) => {
            return pagination.data;
          },
          (error: HttpErrorResponse) => {
            this.notificationService.error(
              this.translateService.instant(
                // tslint:disable-next-line: max-line-length
                'Ocurrió un error al consultar los locales, vuelva a intentarlo. Si persiste pongase en contacto con desarrollo@unblockthecity.com y atenderemos su problema.'
              )
            );

            return Promise.resolve([]);
          }
        );
    }

    this.locales.forEach((local: Local) => {
      (this.formImporte.get('locales') as FormArray).push(
        new FormGroup({
          id: new FormControl({
            value: local.id,
            disabled: false,
          }),
          activo: new FormControl({
            value: false,
            disabled: false,
          }),
          nombre: new FormControl({
            value: local.nombre,
            disabled: false,
          }),
          descripcion: new FormControl({
            value: local.descripcion,
            disabled: false,
          }),
          createdAt: new FormControl({
            value: local.createdAt,
            disabled: false,
          }),
        })
      );
    });
  }

  public changeStep(index: number, $event?: number): void {
    let cantidad = this.formImporte.value.cantidadLocales * this.plan.moMinimo;
    this.formImporte.patchValue({ cantidad: cantidad });
    if (index < 1) {
      this.activeModal.dismiss();
    } else {
      if (this.stepVisible === 1) {
        const result = this.wizardTerms.acceptTerms();
        if (!result) { return; }
      }
      if (index === 4) {
        cantidad = this.formImporte.value.cantidad * (1 + (this.IVA / 100));
        this.formImporte.patchValue({ cantidad: cantidad });
        this.setAmountForTpv(String(cantidad));
        if (!this.formImporte.valid) {
          this.notificationService.error(
            this.translateService.instant(
              'Debe seleccionar al menos un espacio'
            )
          );
          return;
        }
      }

      if (index === 5) {
        cantidad = this.formImporte.value.cantidadLocales * this.plan.moMinimo;
        this.formImporte.patchValue({ cantidad: cantidad });
        this.order = this.formImporte.value;
        this.product = global.PLATFORM_PRODUCT_TYPES.find((product) => product.id === this.plan.id).product;
      }

      if (this.stepVisible === 5) {
        this.event.publish('stopCheckPaymenyt');
      }

      if (index === 6 && $event) {
        this.paymentStatus = $event;
      }

      this.stepVisible = index;
    }
  }

  public finishStep(): void {
    const order: Compra = this.formImporte.value;
    this.activeModal.close(order);
  }

  public htmlToText(html: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  /**
   * isLocal
   */
  public isLocal(formGroup: FormGroup): void {
    const elements: FormGroup[] = (formGroup.parent as FormArray)
      .controls as FormGroup[];

    if (formGroup.get('activo').value) {
      this.formImporte
        .get('cantidadLocales')
        .setValue(+this.formImporte.get('cantidadLocales').value + 1);
    } else {
      this.formImporte
        .get('cantidadLocales')
        .setValue(+this.formImporte.get('cantidadLocales').value - 1);
    }

    const numero: number =
      +this.formImporte.get('cantidadLocales').value * this.plan.moMinimo;
    //const porcentaje: number = (numero * this.IVA) / 100;

    this.formImporte.get('cantidad').setValue(numero);
  }

  public check(control: AbstractControl): ValidationErrors | null {
    const elements: FormGroup[] = (control as FormArray)
      .controls as FormGroup[];

    let cantidad = 0;

    elements.forEach((element: any) => {
      if (element.contains('activo') && element.get('activo').value) {
        cantidad++;
      }
    });

    return cantidad < 1 ? { check: { value: control.value } } : null;
  }

  /**
   * TPV: Stores amount as money number, caring user input
   * ToDo RE-FACTORIZATION: this component must send amount as-is, no matter what.
   * ToDo RE-FACTORIZATION: move this to src/app/v2/tpv-integration/tpv-integration.component.ts
   * ToDo RE-FACTORIZATION: change setAmountFromInput() accordingly
   * @param amount
   * @param mustHaveDecimals
   * @private
   */
  private setAmountForTpv(amount: string, mustHaveDecimals: boolean = true): void {
    // sanitize amount
    this.amount = String(Number(amount).toFixed(2));
    console.log('sanitized amount = ', this.amount);
  }

}
