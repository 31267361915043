import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EspacesRoutes } from './espaces.routing';
import { SharedModule } from '../../shared/shared.module';
import { EspacesComponent } from './espaces/espaces.component';
import { EspacesListComponent } from './espaces-list/espaces-list.component';
import { EspacesZonesComponent } from './espaces-zones/espaces-zones.component';
import { EspacesSubzonesComponent } from './espaces-subzones/espaces-subzones.component';
import { EspacesCategoriesListComponent } from './espaces-categories-list/espaces-categories-list.component';
import { EspacesCategoriesComponent } from './espaces-categories/espaces-categories.component';
import { EspacesCustomersComponent } from './espaces-customers/espaces-customers.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { EspacesQrComponent } from './espaces-qr/espaces-qr.component';
import { PeticionesComponent } from './peticiones/peticiones.component';
import { EspacesEnlacesComponent } from './espaces-enlaces/espaces-enlaces.component';
import { ModalManagerService } from '../../services/modal-manager.service';
import { SalesAgentComponent } from './sales-agents/sales-agent.component';

@NgModule({
  declarations: [
    EspacesListComponent,
    EspacesCategoriesListComponent,
    EspacesCategoriesComponent,
    EspacesZonesComponent,
    EspacesSubzonesComponent,
    PeticionesComponent,
    EspacesEnlacesComponent,
    EspacesComponent,
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    AgmCoreModule,
    RouterModule.forChild(EspacesRoutes),
    SharedModule,
    CKEditorModule,
    TranslateModule,
    AngularCropperjsModule,
  ],

  providers: [
    NgbActiveModal,
    ModalManagerService,
  ],

  entryComponents: [
    EspacesZonesComponent,
    EspacesSubzonesComponent,
    PeticionesComponent,
    EspacesCategoriesComponent,
    EspacesCustomersComponent,
    EspacesQrComponent,
    EspacesEnlacesComponent,
    EspacesComponent,    
    SalesAgentComponent,
  ]
})
export class EspacesModule { }
