import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as global from "./global.service";
import { RedSocialRequest } from "../interfaces/red-social-request.model";
import { Pagination } from "../models/pagination";
import { RedSocial } from "../models/red-social";
import { objsToCamelCase, objToCamelCase } from "../helpers/object.helper";

@Injectable({
  providedIn: "root",
})
export class RedesSocialesService {
  private static REDES_SOCIALES_PATH = global.API_URL + "/api/redessociales";
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }

  public index(redSocialRequest: RedSocialRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (redSocialRequest.getLocales()) {
      httpParams = httpParams.set(
        "locales",
        String(redSocialRequest.getLocales())
      );
    }

    if (redSocialRequest.getPerPage()) {
      httpParams = httpParams.set(
        "perPage",
        String(redSocialRequest.getPerPage())
      );
    }

    if (redSocialRequest.getOrderBy())
      httpParams = httpParams.set("orderBy", redSocialRequest.getOrderBy());

    if (redSocialRequest.getSortBy())
      httpParams = httpParams.set("sortBy", redSocialRequest.getSortBy());

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(
        RedesSocialesService.REDES_SOCIALES_PATH,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<Pagination>(response);
        })
      );
  }

  public show(id: number, redSocialRequest?: RedSocialRequest) {
    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http
      .get<RedSocial>(
        RedesSocialesService.REDES_SOCIALES_PATH + "/" + id,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public create(redSocial: RedSocial): Observable<RedSocial> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<RedSocial>(
        RedesSocialesService.REDES_SOCIALES_PATH,
        redSocial,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<RedSocial>(response);
        })
      );
  }

  public update(redSocial: RedSocial): Observable<RedSocial> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<RedSocial>(
        RedesSocialesService.REDES_SOCIALES_PATH + "/" + redSocial.id,
        redSocial,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<RedSocial>(response);
        })
      );
  }

  public delete(redSocial: RedSocial): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(
        RedesSocialesService.REDES_SOCIALES_PATH + "/" + redSocial.id,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }
}
