import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as global from "./global.service";
import { Pagination } from "../models/pagination";
import { UserRequest } from "../interfaces/userRequest.model";
import { User } from "../models/user";
import { objToCamelCase } from "../helpers/object.helper";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private static USERS_PATH = global.API_URL + `/api/users`;
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }

  public index(userRequest: UserRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (userRequest.getStaff())
      httpParams = httpParams.set("staff", String(userRequest.getStaff()));

    if (userRequest.getName())
      httpParams = httpParams.set("name", userRequest.getName());

    if (userRequest.getEmail())
      httpParams = httpParams.set("email", userRequest.getEmail());

    if (userRequest.getAdministracionNombre())
      httpParams = httpParams.set(
        "administracionNombre",
        userRequest.getAdministracionNombre()
      );

    if (userRequest.getClienteNombre())
      httpParams = httpParams.set(
        "clienteNombre",
        userRequest.getClienteNombre()
      );

    if (userRequest.getAdministracion())
      httpParams = httpParams.set(
        "administracion",
        String(userRequest.getAdministracion())
      );

    if (userRequest.getClienteId())
      httpParams = httpParams.set(
        "clienteId",
        String(userRequest.getClienteId())
      );

    if (userRequest.getCliente())
      httpParams = httpParams.set("cliente", String(userRequest.getCliente()));

    if (userRequest.getPerPage())
      httpParams = httpParams.set("perPage", String(userRequest.getPerPage()));

    if (userRequest.getOrderBy())
      httpParams = httpParams.set("orderBy", userRequest.getOrderBy());

    if (userRequest.getSortBy())
      httpParams = httpParams.set("sortBy", userRequest.getSortBy());

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(UserService.USERS_PATH, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Pagination>(response);
        })
      );
  }

  public byId(userRequest: UserRequest, id): Observable<User> {
    let httpParams: HttpParams = new HttpParams();

    if (userRequest.getStaff()) {
      httpParams = httpParams.set("staffs", String(userRequest.getStaff()));
    }

    if (userRequest.getClienteId()) {
      httpParams = httpParams.set(
        "clienteId",
        String(userRequest.getClienteId())
      );
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };
    return this.http
      .get<User>(UserService.USERS_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public update(user: User, userRequest?: UserRequest): Observable<User> {
    let httpParams: HttpParams = new HttpParams();

    if (userRequest && userRequest.getPlan())
      httpParams = httpParams.set("plan", String(userRequest.getPlan()));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<User>(UserService.USERS_PATH + `/${user.id}`, user, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<User>(response);
        })
    );
  }

  public create(user: User): Observable<User> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<User>(UserService.USERS_PATH, user, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<User>(response);
        })
      );
  }

  public register(user) {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<User>(UserService.USERS_PATH + `/register`, user, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public delete(user: User): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http.delete<any>(
      UserService.USERS_PATH + `/${user.id}`,
      this.httpOptions
    );
  }

  public forgotPassword(email: { email: string }): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.put<any>(
      UserService.USERS_PATH + `/forgotpassword`,
      email,
      this.httpOptions
    );
  }

  public changePassword(user: User, key: string): Observable<User> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<User>(
        UserService.USERS_PATH + `/password/${key}`,
        user,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objToCamelCase<User>(response);
        })
      );
  }

  public getProUsers() {
    return this.http.get(UserService.USERS_PATH+'/pro')
  }
}
