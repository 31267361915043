import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';

const MAX_FILE_SIZE_MB = 2_000_000; // 2 MB

type Aspect = 'portrait'|'landscape';

/**
 * Uso:
 * <app-image-field class="..."
 *     [isValid]="!form.controls.imagen.errors"
 *     [image]="imageUrl || form.controls.imagen"
 *     (onChange)="onImageChange($event)"></app-image-field>
 *
 * -isValid: Para marcarla como no valida.
 * -image: Puede ser una URL (para el caso en que estamos editando)
 *         o una string Base64 (cuando se está creando un item y se selecciona una imagen).
 * -onChange: Ejecuta un handler al cambiar la imagen. El valor de $event es la string Base64
 *            de la imagen.
 */
@Component({
    selector: 'app-image-field',
    templateUrl: './image-field.component.html',
    styleUrls: ['./image-field.component.css']
})
export class ImageFieldComponent {
    @Input()
    public image;

    @Input()
    public isValid: boolean;

    @Input()
    public label: string = null;

    @Input()
    public required: boolean = false;

    @Input()
    public aspect: Aspect = null;

    @Output()
    public onChange: EventEmitter<string> = new EventEmitter<string>();

    private inlineImage?: string = null;

    constructor( private notification: NotificationService) {}

    public get hasImage(): boolean {
      return this.realImage !== null;
    }

    public get imageClass(): string {
      return this.aspect === 'portrait' ? 'vertical_image' : 'horizontal_image';
    }

    public get realImage(): string|null {
      if (this.inlineImage) {
        return this.inlineImage;
      }

      if (this.imageUrl) {
        return this.imageUrl;
      }

      if (this.image && this.image.value !== '') {
        return this.image.value;
      }

      return null;
    }

    public get imageUrl(): string|null {
      return this.isUrl( this.image) ? this.image : null;
    }

    public updateImage( event: any) {
      const files = event.target.files;

      if (files.length === 0) {
        return;
      }

      if (files[0].size > MAX_FILE_SIZE_MB) {
        this.notification.error( 'No pueden subirse archivos de mayor tamaño a 2 mb.');
        return;
      }

      if (files[0].type.match(/image\/*/) === null) {
        this.notification.error( 'Solo se permiten subir imagenes.');
        return;
      }

      const imageFile = files[0];

      const reader = new FileReader();
      reader.readAsDataURL( imageFile);
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          throw new Error( 'Imagen es un ArrayBuffer. Debe ser una string.');
        }

        const imageData: string = reader.result;

        if (this.aspect) {
          const image = new Image();
          image.onload = () => {
            const width = image.naturalWidth;
            const height = image.naturalHeight;

            if (!this.isValidAspect( width, height)) {
              this.notification.error( this.invalidAspectErrorMessage);
              return;
            }

            this.sendLoadEvent( imageData);
          };

          image.src = window.URL.createObjectURL( imageFile);

          /* setTimeout( () => {
            const width = image.naturalWidth;
            const height = image.naturalHeight;

            if (!this.isValidAspect( width, height)) {
              this.notification.error( 'La imagen no tiene el formato adecuado. Busque una imagen vertical.');
              return;
            }
          }, 0); */
        } else {
          this.sendLoadEvent( imageData);
        }
    };
  }

  private get invalidAspectErrorMessage(): string {
    const expectedAspect = this.aspect === 'portrait' ? 'vertical' : 'horizontal';

    return `La imagen no tiene el formato adecuado. Busque una imagen ${expectedAspect}.`
  }

  private sendLoadEvent( imageData: string) {
    this.onChange.emit( imageData);
    this.inlineImage = imageData;
  }

  private isUrl( value?: string): boolean {
    try {
      new URL( value);

      return true;
    } catch (e) {
      return false;
    }
  }

  private isValidAspect( width: number, height: number): boolean {
    return this.aspect === 'portrait' && width <= height ||
           this.aspect === 'landscape' && height <= width;
  }
}
