import { PaginationRequest } from './paginationRequest.model';

export class ProvinciaRequest extends PaginationRequest {

  private paisId: number;
  private comunidadautonomaId: number;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getComunidadAutonomaId(): number {
    return this.comunidadautonomaId;
  }

  /**
   * 
   */
  public setComunidadAutonomaId(comunidadAutonomaId: number): void {
    this.comunidadautonomaId = comunidadAutonomaId;
  }

  /**
   * 
   */
  public getPaisId(): number {
    return this.paisId;
  }

  /**
   * 
   */
  public setPaisId(paisId: number): void {
    this.paisId = paisId;
  }

}