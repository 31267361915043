import { PaginationRequest } from '../../../interfaces/paginationRequest.model';

export class CustomersRequest extends PaginationRequest {

  private userId: number;
  private nombre: string;
  private localNombre: string;
  private user: boolean;

  constructor() {
    super();
  }

  public getUserId(): number {
    return this.userId;
  }

  public setUserId(userId: number): void {
    this.userId = userId;
  }

  public getNombre(): string {
    return this.nombre;
  }

  public setNombre(nombre: string): void {
    this.nombre = nombre;
  }

  public getLocalNombre(): string {
    return this.localNombre;
  }

  public setLocalNombre(nombre: string): void {
    this.localNombre = nombre;
  }

  public getUser(): boolean {
    return this.user;
  }

  public setUser(user: boolean): void {
    this.user = user;
  }

}