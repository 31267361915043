import { PaginationRequest } from './paginationRequest.model';

export class AdministracionRequest extends PaginationRequest {

  private administracionId: number;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   * 
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

}