import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-wizard-footer',
  templateUrl: './wizard-footer.component.html',
  styleUrls: ['./wizard-footer.component.css']
})
export class WizardFooterComponent {
  @Input()
  public entityName: string;

  @Input()
  public showContinueBtn: boolean;

  @Input()
  public showCreateBtn: boolean;

  @Input()
  public editItem: object = null;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onContinue: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onSave: EventEmitter<any> = new EventEmitter<any>();

  public get createMessage() {
    return `Crear ${this.entityName}`;
  }

  public close() {
    this.onClose.emit();
  }

  public continue() {
    this.onContinue.emit();
  }

  public save() {
    this.onSave.emit();
  }
}
