import { PaginationRequest } from "../interfaces/paginationRequest.model";

export class PlanRequest extends PaginationRequest {
  private caracteristicas: boolean;
  private configuracion: boolean;

  constructor() {
    super();
  }

  public getCaracteristicas(): boolean {
    return this.caracteristicas;
  }

  public setCaracteristicas(caracteristicas: boolean): void {
    this.caracteristicas = caracteristicas;
  }

  public getConfiguracion(): boolean {
    return this.configuracion;
  }

  public setConfiguracion(configuracion: boolean): void {
    this.configuracion = configuracion;
  }
}
