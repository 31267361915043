import { PaginationRequest } from '../../../interfaces/paginationRequest.model';

export class SalesAgentRequest extends PaginationRequest {

  private name: string;

  constructor() {
    super();
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

}