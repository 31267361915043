import * as global from './global.service';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { objsToCamelCase, objToCamelCase } from '../helpers/object.helper';
import { Pagination } from '../models/pagination';
import { SalesAgent } from '../models/salesAgent';
import { SalesAgentRequest } from '../v2/customers/models/salesAgentRequest.model';

@Injectable({
  providedIn: 'root'
})
export class SalesAgentService {

  private static SALES_AGENT_PATH = global.API_URL + '/api/sales-agent';

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public show(id: number): Observable<SalesAgent> {

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<SalesAgent>(SalesAgentService.SALES_AGENT_PATH + `/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<SalesAgent>(response);
      })
    );
  }

  public index(SalesAgentRequest: SalesAgentRequest): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    if (SalesAgentRequest.getPerPage())
      httpParams = httpParams.set('perPage', String(SalesAgentRequest.getPerPage()));

    if (SalesAgentRequest.getOrderBy())
      httpParams = httpParams.set('orderBy', SalesAgentRequest.getOrderBy());

    if (SalesAgentRequest.getSortBy())
      httpParams = httpParams.set('sortBy', SalesAgentRequest.getSortBy());

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(SalesAgentService.SALES_AGENT_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public search(SalesAgentRequest: SalesAgentRequest): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    if (SalesAgentRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(SalesAgentRequest.getPerPage()));
    }

    if (SalesAgentRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(SalesAgentRequest.getOrderBy()));
    }

    if (SalesAgentRequest.getName()) {
      httpParams = httpParams.set('name', String(SalesAgentRequest.getName()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(SalesAgentService.SALES_AGENT_PATH + `/search`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public byUserId(id: number): Observable<SalesAgent> {
    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<SalesAgent>(SalesAgentService.SALES_AGENT_PATH + `/user/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<SalesAgent>(response);
      })
    );
  }

  public create(salesAgent: SalesAgent): Observable<SalesAgent> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.post<SalesAgent>(SalesAgentService.SALES_AGENT_PATH, salesAgent, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<SalesAgent>(response);
      })
    );
  }

  public update(salesAgent: SalesAgent): Observable<SalesAgent> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.put<SalesAgent>(SalesAgentService.SALES_AGENT_PATH + `/${salesAgent.id}`, salesAgent, this.httpOptions)
      .pipe(
        map((response: any) => objToCamelCase<SalesAgent>(response))
      );
  }

  public delete(salesAgent: SalesAgent): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(SalesAgentService.SALES_AGENT_PATH + `/${salesAgent.id}`, this.httpOptions)
      .pipe(
        map((response: any) => objsToCamelCase(response))
      );
  }

}
