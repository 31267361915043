import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as global from './global.service';
import { Pagination } from '../models/pagination';
import { objsToCamelCase, objToCamelCase } from '../helpers/object.helper';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventInvitationsService {

  private static EVENT_INVITATIONS_PATH = global.API_URL + '/api/eventinvitations';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public confirm(eventInvitation: any): Observable<any> {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<any>(EventInvitationsService.EVENT_INVITATIONS_PATH + '/confirm', eventInvitation, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<any>(response);
      })
    );
  }

  public delete(eventInvitation: any) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(EventInvitationsService.EVENT_INVITATIONS_PATH + `/${eventInvitation.id}`, this.httpOptions);
  }

}
