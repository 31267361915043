import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as global from '../services/global.service';
import { Observable } from 'rxjs';
import { OptionProduct } from '../models/OptionProduct';

@Injectable({
  providedIn: 'root'
})
export class OptionProductService {

  private OPTIONS_PATH: string = `${global.API_URL}/api/optionsproducts`;

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  index(){
    return this.http.get<any>(this.OPTIONS_PATH);
  }

  public create( option: OptionProduct): Observable<any> {
    return this.http.post(this.OPTIONS_PATH, option, this.httpOptions);
  }

  public updateState(){}

  public deletes(){}

  public updates( option: OptionProduct): Observable<any> {
    return this.http.post<any>(this.OPTIONS_PATH, option, this.httpOptions);
  }

  public show(id): Observable<any>{
    return this.http.get<any>(this.OPTIONS_PATH + '/' + id);
  }

  public update(option: OptionProduct): Observable<any>{
    return this.http.put<any>(this.OPTIONS_PATH + '/' + option.id, option, this.httpOptions);
  }

  public destroy(option: OptionProduct): Observable<any>{
    return this.http.delete<any>(this.OPTIONS_PATH + '/' + option.id);
  }

}
