// core imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// business imports
import { SafeUrlPipe } from './safe-url.pipe';
import { TpvIntegrationComponent } from './tpv-integration.component';

@NgModule({
  declarations: [
    SafeUrlPipe,
    TpvIntegrationComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    TpvIntegrationComponent
  ]
})
export class TpvIntegrationModule { }
