import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { CuponRequest } from '../interfaces/cuponRequest.model';
import { Cupon } from '../models/cupon';

@Injectable({
  providedIn: 'root'
})
export class CuponService {


  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public byCode(code: string, refer: boolean = null): Observable<any> {

    let httpParams: HttpParams = new HttpParams();

    if (refer != null) {
      httpParams = httpParams.set('refer', String(refer));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get(global.API_URL + `/api/cupones/verify-cupon/` + code, this.httpOptions);
  }

  public index(cuponRequest?: CuponRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (cuponRequest && cuponRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(cuponRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/cupones`, this.httpOptions).pipe(
      map((response: any) => {

        response.data = objsToCamelCase<Cupon>(response.data);

        return response;
      })
    );
  }

  public create(cupon: Cupon) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Cupon>(global.API_URL + '/api/cupones', cupon, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(cupon: Cupon) {

    const httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<Cupon>(global.API_URL + '/api/cupones/' + cupon.id, cupon, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(cupon: Cupon) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/cupones/' + cupon.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }}
