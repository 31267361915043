import { PaginationRequest } from "./paginationRequest.model";

export class CategoriaRequest extends PaginationRequest {
  private subcategorias: boolean;
  private subcategoriasIdiomas: boolean;

  constructor() {
    super();
  }

  /**
   *
   */
  public getSubcategorias(): boolean {
    return this.subcategorias;
  }

  /**
   *
   */
  public setSubcategorias(subcategorias: boolean): void {
    this.subcategorias = subcategorias;
  }

  public getSubcategoriasIdiomas(): boolean {
    return this.subcategoriasIdiomas;
  }

  public setSubcategoriasIdiomas(subcategoriasIdiomas: boolean): void {
    this.subcategoriasIdiomas = subcategoriasIdiomas;
  }
}
