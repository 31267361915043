import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../../../models/pagination';
import * as global from '../../../services/global.service';
import { FormaPagoRequest } from '../models/forma-pago-request.model';
import { map } from 'rxjs/operators';
import { objToCamelCase } from '../../../helpers/object.helper';
import { FormaPago } from '../interfaces/forma-pago';

@Injectable({
  providedIn: 'root'
})
export class FormaPagoService {

  private static FORMA_PAGOS_PATH = global.API_URL + '/api/formapagos';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(formaPagoRequest: FormaPagoRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (formaPagoRequest.getNombre()) {
      httpParams = httpParams.set('nombre', formaPagoRequest.getNombre());
    }

    if (formaPagoRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(formaPagoRequest.getPerPage()));
    }

    if (formaPagoRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(formaPagoRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(FormaPagoService.FORMA_PAGOS_PATH, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public show(formaPagoRequest: FormaPagoRequest, id: number): Observable<FormaPago> {
    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<FormaPago>(`${FormaPagoService.FORMA_PAGOS_PATH}/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<FormaPago>(response)
      })
    );
  }

  public update(formaPago: FormaPago): Observable<FormaPago> {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<FormaPago>(`${FormaPagoService.FORMA_PAGOS_PATH}/${formaPago.id}`, formaPago, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<FormaPago>(response);
      })
    );
  }

  public create(formaPago: FormaPago): Observable<FormaPago> {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<FormaPago>(FormaPagoService.FORMA_PAGOS_PATH, formaPago, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<FormaPago>(response);
      })
    );
  }

  public delete(formaPago: FormaPago): Observable<any> {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(`${FormaPagoService.FORMA_PAGOS_PATH}/${formaPago.id}`, this.httpOptions);
  }
}
