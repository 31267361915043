import { Component, Input, OnInit } from '@angular/core';
import { Local } from '../../../models/local';
import { NotificationService } from '../../../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Plan } from '../../../models/plan';

@Component({
  selector: 'app-select-locals',
  templateUrl: './select-locals.component.html',
  styleUrls: ['./select-locals.component.css']
})
export class SelectLocalsComponent implements OnInit {

  @Input() public locals: Local[];
  @Input() public limit: number = 0;
  @Input() public plan: Plan;

  checkedLocals;

  constructor(
    private modalService: NgbActiveModal,
    public translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.checkedLocals = this.locals.filter( (local) => local.hasOwnProperty('checked') && local['checked'] === true).length;
  }

  close() {
    this.modalService.dismiss();
  }

  async confirm() {
    const localsChecked = await this.locals.filter( (local) => local.hasOwnProperty('checked') && local['checked'] === true );
    this.modalService.close(localsChecked);
  }

  decrease(local){
    local['checked'] = false;
    this.checkedLocals--;
  }

  isLocal(local){
    if ( !local.hasOwnProperty('checked') || local['checked'] === false ){
      this.increase(local);
    } else {
      this.decrease(local);
    }

  }

  increase(local){

    if( this.checkedLocals < this.limit ){
      local['checked'] = true;
      this.checkedLocals++;
    } else {
      this.notificationService.error('No puedes seleccionar más espacios. Tú límite es ' + this.limit);
    }
  }

}
