import { PaginationRequest } from './paginationRequest.model';

export class QrRequest extends PaginationRequest {

  private clienteId: number;
  private tapaId: number;
  private rutaId: number;
  private localId: number;
  private zonaId: number;
  private subzonaId: number;
  private administracionId: number;
  private tipoQr: number;

  constructor() {
    super();
    }

  /**
   * 
   */
  public getClienteId(): number {
    return this.clienteId;
  }
  
  /**
   * 
   */
  public setClienteId(clienteId: number): void {
    this.clienteId = clienteId;
  }

  /**
   * 
   */
  public getTapaId(): number {
    return this.tapaId;
  }

  /**
   * 
   */
  public setTapaId(tapaId: number): void {
    this.tapaId = tapaId;
  }

  /**
   * 
   */
  public getRutaId(): number {
    return this.rutaId;
  }

  /**
   * 
   */
  public setRutaId(rutaId: number): void {
    this.rutaId = rutaId;
  }

  /**
   * 
   */
  public getLocalId(): number {
    return this.localId;
  }

  /**
   * 
   */
  public setLocalId(localId: number): void {
    this.localId = localId;
  }

  /**
   * 
   */
  public getZonaId(): number {
    return this.zonaId;
  }

  /**
   * 
   */
  public setZonaId(zonaId: number): void {
    this.zonaId = zonaId;
  }

  /**
   * 
   */
  public getSubZonaId(): number {
    return this.subzonaId;
  }

  /**
   * 
   */
  public setSubZonaId(subzonaId: number): void {
    this.subzonaId = subzonaId;
  }

  /**
   * 
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   * 
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

    /**
   * 
   */
     public setTipoQr(tipoQr: number): void {
      this.tipoQr = tipoQr;
    }
  
    /**
     * 
     */
     public getTipoQr(): number {
      return this.tipoQr;
    }
  

}