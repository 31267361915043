import * as global from '../../services/global.service';
import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryAction } from 'ngx-gallery';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { EspaceService } from '../espaces/services/espaces.service';
import { EspacePhoto } from '../espaces/interfaces/espacePhoto';
import { Espace } from '../espaces/interfaces/espace';
import { EspacesRequest } from '../espaces/models/espacesRequest.model';
import { PromoService } from '../../services/promo.service';
import { PromoPhoto } from '../promotions/interfaces/promo-photo';
import { Promo } from '../../models/promo';
import { PromoRequest } from '../../interfaces/promoRequest.model';
import { Tarjeta } from '../tarjetas/models/tarjeta';
import { TarjetaService } from '../tarjetas/services/tarjeta.service';
import { NotificationService } from '../../services/notification.service';
import { TarjetaRequest } from '../tarjetas/interfaces/tarjetaRequest.model';
import { TarjetaPhoto } from '../tarjetas/models/tarjetaPhoto';

import { EventService } from '../../services/ubEvents.service';
import { EventImage } from '../events/interfaces/eventImage';
import { Event } from '../../models/event';
import { EventRequest } from '../../interfaces/ubEventRequest.model';

const Messages = {
  ImageRemovedSuccessfully: 'Se ha eliminado correctamente la imagen.',

  CantLoadImages: 'No se pudo cargar las imágenes',
  CantRemoveImage: 'No se pudo eliminar la imagen',
};

interface Images {
  progress: number;
  file: File;
}

enum GalleryType {
  Espace,
  Promo,
  Card,
  Event
}

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})

export class PhotosComponent implements OnInit {
  @Input() public espace: Espace;
  @Input() public event: Event;
  @Input() public promo: Promo;
  @Input() public tarjeta: Tarjeta;
  @ViewChild('fileDropRef') public fileDropEl: ElementRef;
  @ViewChild('modalConfirm') public modalConfirm: TemplateRef<any>;

  public modalRef: NgbModalRef;
  public files: Images[];
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public galleryAction: NgxGalleryAction[];

  private galleryType: GalleryType;
  private MAX_IMAGES_UPLOADED = 10
  public restImages = this.MAX_IMAGES_UPLOADED
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private espaceService: EspaceService,
    private promoService: PromoService,
    private tarjetaService: TarjetaService,
    private eventService: EventService

  ) {
    this.files = [];

    this.galleryAction = [{
      icon: 'fa fa-close',
      onClick: this.openConfirm.bind(this)
    }];

    this.galleryOptions = this.buildGalleryOptions();
    this.galleryImages = [];
  }

  public ngOnInit() {

    this.detectGalleryType();
    this.loadImages();
  }

  /**
  * on file drop handler
  */
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.restImages++
  }

  onSelect(event) {
    const espacePhotos: Array<EspacePhoto> = [];
    const promoPhotos: Array<PromoPhoto> = [];
    const cardPhotos: Array<TarjetaPhoto> = [];
    const eventPhotos: Array<EventImage> = [];

    let photoList: Array<any>;

    switch (this.galleryType) {
      case GalleryType.Espace:
        photoList = espacePhotos;
        break;

      case GalleryType.Promo:
        photoList = promoPhotos;
        break;

      case GalleryType.Event:
        photoList = eventPhotos;
        break;

      case GalleryType.Card:
        photoList = cardPhotos;
        break;
    }

    if (event.addedFiles.length <= this.restImages) {
      this.restImages -= event.addedFiles.length
      this.files.push(...event.addedFiles);
      event.addedFiles.forEach(element => {
        photoList.push({
          imagen: element
        });
      });
    } else {
      this.notification.error('Has alcanzado el máximo de 10 imagenes');
    }

    switch (this.galleryType) {
      case GalleryType.Espace:
        this.updateEspacePhotos(photoList);
        break;

      case GalleryType.Promo:
        this.updatePromoPhotos(photoList);
        break;

      case GalleryType.Event:
        this.updateEventImages(photoList);
        break;

      case GalleryType.Card:
        this.updateCardPhotos(photoList);
        break;
    }
  }


  public openConfirm(event: Event, index: number) {
    this.modalRef = this.modalService.open(this.modalConfirm, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false
    });

    this.modalRef.result.then(
      () => {
        let promise: Promise<any>;

        switch (this.galleryType) {
          case GalleryType.Espace:
            promise = this.removeEspacePhoto(index);
            break;

          case GalleryType.Promo:
            promise = this.removePromoPhoto(index);
            break;

          case GalleryType.Event:
            promise = this.removeEventImage(index);
            break;

          case GalleryType.Card:
            promise = this.removeCardPhoto(index);
            break;
        }

        promise.then(
          () => {
            this.galleryImages.splice(index, 1);
            this.notification.success(Messages.ImageRemovedSuccessfully);
          },

          (error) => this.notification.error(Messages.CantRemoveImage, error)
        );
      },

      () => { }
    );
  }

  /**
  * Simulate the upload process
  */
  public uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        this.files = [];
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index]) {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);

              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += 5;
            }
          }
        }, 200);
      }
    }, 1000);
  }

  public get title(): string {
    switch (this.galleryType) {
      case GalleryType.Espace:
        return this.espace.nombre;

      case GalleryType.Promo:
        return this.promo.titulo;

      case GalleryType.Event:
        return this.event.idiomas[0].title;

      case GalleryType.Card:
        return this.tarjeta.nombre;
    }
  }

  private buildGalleryOptions(): Array<any> {
    return [
      {
        width: '100%',
        height: '600px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: true,
        thumbnailActions: this.galleryAction,
        imageActions: this.galleryAction
      },

      // max-width 800

      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },

      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }

  private buildImageUrl(image: string): string {
    return `${global.API_URL}${image}`;
  }

  private detectGalleryType() {
    if (this.espace) {
      this.galleryType = GalleryType.Espace;
    } else if (this.promo) {
      this.galleryType = GalleryType.Promo;
    } else if (this.event) {
      this.galleryType = GalleryType.Event;
    } else if (this.tarjeta) {
      this.galleryType = GalleryType.Card;
    } else {
      throw new Error('Tipo de galeria de imagenes desconocido. Debe asignar una de estas properties: espace, promo o tarjeta')
    }
  }

  private async loadImages() {
    this.spinner.show();

    switch (this.galleryType) {
      case GalleryType.Espace:
        await this.loadEspaceImages();
        break;

      case GalleryType.Promo:
        await this.loadPromoImages();
        break;

      case GalleryType.Event:
        await this.loadEventImages();
        break;

      case GalleryType.Card:
        await this.loadCardImages();
        break;
    }
  }

  private loadEspaceImages() {
    const espacesRequest: EspacesRequest = new EspacesRequest();
    espacesRequest.setPhotos(true);

    this.espaceService.show(this.espace.id, espacesRequest).toPromise()
      .then(
        (espace: Espace) => {
          this.espace = espace;

          espace.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.imagen as string);

            this.galleryImages.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });
          this.restImages = this.MAX_IMAGES_UPLOADED - this.galleryImages.length
          this.spinner.hide();
        },

        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  private loadPromoImages() {
    const promoRequest: PromoRequest = new PromoRequest();
    promoRequest.setPhotos(true);

    this.promoService.show(this.promo.id, promoRequest).toPromise()
      .then(
        (promo: Promo) => {
          this.promo = promo;

          promo.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.imagen as string);

            this.galleryImages.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });
          this.spinner.hide();
          this.restImages = this.MAX_IMAGES_UPLOADED - this.galleryImages.length

        },

        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  private loadEventImages() {
    const request: EventRequest = new EventRequest();
    request.setPhotos(true);

    this.eventService.show(this.event.id, request).toPromise()
      .then(
        (event: Event) => {
          this.event = event;
          event.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.image as string);

            this.galleryImages.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });
          this.spinner.hide();
          this.restImages = this.MAX_IMAGES_UPLOADED - this.galleryImages.length

        },

        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  private loadCardImages() {
    const request: TarjetaRequest = new TarjetaRequest();
    request.setPhotos(true);

    this.tarjetaService.show(this.tarjeta.id, request)
      .then(
        (tarjeta: Tarjeta) => {
          this.tarjeta = tarjeta;

          tarjeta.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.imagen as string);

            this.galleryImages.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });
          this.spinner.hide();
          this.restImages = this.MAX_IMAGES_UPLOADED - this.galleryImages.length

        },

        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  private updateEspacePhotos(photoList: Array<any>) {
    this.espace.photos = photoList;

    this.espaceService.photos(this.espace).toPromise()
      .then(
        (espace: Espace) => {
          this.espace = espace;
          const images: NgxGalleryImage[] = [];

          espace.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.imagen as string);

            images.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });

          this.galleryImages = images;
        },

        (error) => this.notification.error(Messages.CantLoadImages, error)
      );
  }

  private updatePromoPhotos(photoList: Array<any>) {
    this.promo.photos = photoList;

    this.promoService.photos(this.promo).toPromise()
      .then(
        (promo: Promo) => {
          this.promo = promo;
          const images: NgxGalleryImage[] = [];

          promo.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.imagen as string);

            images.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });

          this.galleryImages = images;
        },

        (error) => this.notification.error(Messages.CantLoadImages, error)
      );
  }


  private updateEventImages(photoList: Array<any>) {
    this.event.photos = photoList;

    this.eventService.photos(this.event).toPromise()
      .then(
        (event: Event) => {
          this.event = event;
          const images: NgxGalleryImage[] = [];

          event.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.image as string);

            images.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });

          this.galleryImages = images;
        },

        (error) => this.notification.error(Messages.CantLoadImages, error)
      );
  }

  private updateCardPhotos(photoList: Array<any>) {
    this.tarjeta.photos = photoList;

    this.tarjetaService.photos(this.tarjeta)
      .then(
        (tarjeta: Tarjeta) => {
          this.tarjeta = tarjeta;
          const images: NgxGalleryImage[] = [];

          tarjeta.photos.forEach((photo) => {
            const imageUrl = this.buildImageUrl(photo.imagen as string);

            images.push({
              small: imageUrl,
              medium: imageUrl,
              big: imageUrl
            });
          });

          this.galleryImages = images;
        },

        (error) => this.notification.error(Messages.CantLoadImages, error)
      );
  }

  private removeEspacePhoto(index: number): Promise<any> {
    const photo: EspacePhoto = this.espace.photos[index];

    return this.espaceService.removePhoto(this.espace, photo).toPromise();
    /* .then(
      () => {
        this.galleryImages.splice(index, 1);
        this.notification.success( Messages.ImageRemovedSuccessfully);
      },

      (error) => this.notification.error( Messages.CantRemoveImage, error)
    ); */
  }

  private removePromoPhoto(index: number): Promise<any> {
    const photo: PromoPhoto = this.promo.photos[index];

    return this.promoService.removePhoto(this.promo, photo).toPromise();
    /* .then(
      () => {
        this.galleryImages.splice(index, 1);
        this.notification.success( Messages.ImageRemovedSuccessfully);
      },

      (error) => this.notification.error( Messages.CantRemoveImage, error)
    ); */
  }

  private removeEventImage(index: number): Promise<any> {
    const image: EventImage = this.event.photos[index];

    return this.eventService.removePhoto(this.event, image).toPromise();
  }

  private removeCardPhoto(index: number): Promise<any> {
    const photo: TarjetaPhoto = this.tarjeta.photos[index];

    return this.tarjetaService.removePhoto(this.tarjeta, photo);
    /* .then(
      () => {
        this.galleryImages.splice( index, 1);
        this.notification.success( Messages.ImageRemovedSuccessfully);
      },

      (error) => this.notification.error( Messages.CantRemoveImage, error)
    ); */
  }


}
