export enum Role {
  ADMINISTRATOR = 1,
  USER_APP = 2,
  GROUP_ADMIN = 3,
  CLIENT = 4,
  STAFF = 5,
  DESTINATION = 6,
  CITY_MANAGER = 7,
  EDITOR = 8
}
