import * as global from "../../../services/global.service";
import { CurrencyPipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterContentInit,
  AfterViewInit,
  DoCheck,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Pagination } from "../../../models/pagination";
import { Plan } from "../../../models/plan";
import { AuthenticationService } from "../../../services/authentication.service";
import { Events } from "../../../services/events.service";
import { NotificationService } from "../../../services/notification.service";
import { Compra } from "../../customers/interfaces/compra";
import { FormaPago } from "../../customers/interfaces/forma-pago";
import { FormaPagoRequest } from "../../customers/models/forma-pago-request.model";
import { CompraService } from "../../customers/services/compra.service";
import { FormaPagoService } from "../../customers/services/forma-pago.service";
import { Console, log } from "console";

@Component({
  selector: "app-charge-balance",
  templateUrl: "./charge-balance.component.html",
  styleUrls: ["./charge-balance.component.css"],
})
export class ChargeBalanceComponent
  implements OnInit, DoCheck, AfterContentInit
{
  amount = ""; // TPV: Stores amount to be passed to tpv, as integer, no formatting, just money numbers in string
  public currencyPipe: CurrencyPipe;
  public stepVisible = 1;
  public orderForm: FormGroup;
  public readonly IVA: number = 21;
  @Input() type: number;
  public paymentStatus = 0;

  // Cantidades BASE e IVA
  public orderPayWithTax = 0;
  public orderPayBase = 0;
  public orderPayIva = 0;

  // Datos a enviar al Iframe del TPV
  public product = null;
  public order: Compra;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private formaPagoService: FormaPagoService,
    private compraService: CompraService,
    private spinner: NgxSpinnerService,
    private event: Events
  ) {
    this.currencyPipe = new CurrencyPipe("es");
  }

  ngOnInit() {
    this.orderForm = this.formBuilder.group({
      base: [
        30,
        [
          Validators.required,
          Validators.pattern(/^[0-9\.{1}]+$/),
          Validators.min(30),
        ],
      ],
      cantidad: [
        30,
        [
          Validators.required,
          Validators.pattern(/^[0-9\.{1}]+$/),
          Validators.min(30),
        ],
      ],
      cantidadLocales: [0],
      formaPagosId: [1, Validators.required],
      comentario: ["Ingreso de saldo"],
      planId: [this.type !== 5 ? 0 : 3],
    });
  }

  ngDoCheck() {
    this.orderPayBase = this.orderForm.value.cantidad;
  }

  ngAfterContentInit() {
    this.orderForm.get("cantidad").setValue(this.orderPayBase);
    this.setAmountForTpv(String(this.orderPayWithTax));
  }

  timeoutTpv() {
    this.changeStep(this.stepVisible - 1);
  }

  public changeStep(index: number, $event?: number): void {
    const recalculate = () => {
      this.orderPayWithTax = this.orderPayBase * (1 + this.IVA / 100);
      this.orderPayBase = this.orderPayBase;
      this.orderPayIva = this.orderPayWithTax - this.orderPayBase;

      this.orderForm.patchValue({ cantidad: this.orderPayBase });
      this.setAmountForTpv(String(this.orderPayWithTax));
      if (!this.orderForm.valid) {
        this.notificationService.error(
          this.translateService.instant("Debe seleccionar una cantidad")
        );
        return;
      }
    };

    if (index < 1) {
      this.activeModal.dismiss();
    } else {
      if (index === 1) {
        if (this.stepVisible === 2) {
          // VOLVER
        }
      }

      /**
       * AGREGAR CANTIDAD DE RECARGA
       */
      if (this.stepVisible === 1) {

        // CONTINUAR
        if (index === this.stepVisible + 1) {
          this.order = this.orderForm.value;
          this.order.recarga = true;
          this.product = global.PLATFORM_PRODUCT_TYPES.find(
            (product) => product.id === this.type
          ).product;
          recalculate();

          this.stepVisible = index;
          return;
        }

        // VOLVER
        if (index === this.stepVisible - 1) {
          this.stepVisible = 1;
        }
        return;
      }

      /**
       * CONFIRMACION PAGO
       */
      if (this.stepVisible === 2) {
        // CONTINUAR
        if (index === this.stepVisible + 1) {
          this.order = this.orderForm.value;
          this.order.recarga = true;
          this.product = global.PLATFORM_PRODUCT_TYPES.find(
            (product) => product.id === this.type
          ).product;
          recalculate();

          this.stepVisible = index;
          return;
        }

        // VOLVER
        if (index === this.stepVisible - 1) {
          this.stepVisible = 1;
        }
        return;
      }
    }

    /**
     * PAGO
     */
    if (this.stepVisible === 3) {
      this.event.publish("stopCheckPaymenyt");
      recalculate();

      // CONTINUAR
      if (index === this.stepVisible + 1) {
        if ($event) {
          this.paymentStatus = $event;
        }
      }

      // VOLVER
      if (index === this.stepVisible - 1) {
        this.stepVisible = index;
      }
    }

    /**
     * STATUS
     */
    if (this.stepVisible === 4) {
      // CONTINUAR
      if (index === this.stepVisible + 1) {
        this.stepVisible = index;
      }

      // VOLVER
      if (index === this.stepVisible - 1) {
        this.stepVisible = index;
        if ($event) {
          this.paymentStatus = $event;
        }
      }
    }

    //
    this.stepVisible = index;
  }

  /*public finishStep(): void {
    this.spinner.show();

    this.spinner.show();
    const compra: Compra = this.orderForm.value;

    this.compraService
      .create(compra)
      .toPromise()
      .then(
        (compra: Compra) => {
          this.event.publish("chargeBalance");
          this.activeModal.close(compra);
          this.spinner.hide();
        },
        (error: HttpErrorResponse) => {
          this.notificationService.logger("finishStep", error);

          this.notificationService.error(
            this.translateService.instant(
              "Ocurrió un error al procesar la compra, vuelva a intentarlo. Si persiste pongase en contacto con desarrollo@unblockthecity.com y atenderemos su problema."
            )
          );
        }
      );
  }*/

  public finishStep(): void {
    const compra: Compra = this.orderForm.value;
    this.activeModal.close(compra);
    this.event.publish("chargeBalance");
  }

  /**
   * TPV: Stores amount as money number, caring user input
   * ToDo RE-FACTORIZATION: this component must send amount as-is, no matter what.
   * ToDo RE-FACTORIZATION: move this to src/app/v2/tpv-integration/tpv-integration.component.ts
   * ToDo RE-FACTORIZATION: change setAmountFromInput() accordingly
   * @param amount
   * @param mustHaveDecimals
   * @private
   */
  private setAmountForTpv(
    amount: string,
    mustHaveDecimals: boolean = true
  ): void {
    // sanitize amount
    this.amount = String(Number(amount).toFixed(2));
  }

  /**
   * TPV: Helper function:
   * Parse user input amount to get just a number in quotes:
   * No formatting nor currency, decimals come in as comma (,) should go out as dot (.)
   * NOTE about decimals and integer: It makes a truncate, not a round
   * ToDo RE-FACTORIZATION: this component must send amount as-is, no matter what.
   * ToDo RE-FACTORIZATION: move this to src/app/v2/tpv-integration/tpv-integration.component.ts
   * ToDo RE-FACTORIZATION: change setAmountFromInput() accordingly
   * @param amount
   * @param mustReturnDecimals
   */
  convertStringWithCommaSeparatorToNumber(
    amount: string,
    mustReturnDecimals: boolean = false
  ): string {
    // Remove non-numeric characters except commas
    const cleanedString = amount.replace(/[^0-9,]/g, "");

    // Replace comma with dot as the decimal separator
    const stringWithDot = cleanedString.replace(/,/g, ".");

    // Split the string by dot
    const [integerPart, decimalPart] = stringWithDot.split(".");

    // If there is no decimal part, append ".00" and we're all set
    if (!decimalPart) {
      return mustReturnDecimals ? integerPart + ".00" : integerPart;
    }

    // If there is only one digit in the decimal part, append a zero at the end and we're all set
    if (decimalPart.length === 1) {
      return mustReturnDecimals
        ? integerPart + "." + decimalPart + "0"
        : integerPart;
    }

    // If there are two or more digits in the decimal part, keep only the first two
    return mustReturnDecimals
      ? integerPart + "." + decimalPart.substring(0, 2)
      : integerPart;
  }
}
