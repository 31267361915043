import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

type ElementType = 'local' | 'ruta' | 'promo' | 'menu' | 'tarjeta' | 'tapa' | 'event' | 'eventTicket' | 'eventProduct';

@Component({
  selector: 'app-activation-elements',
  templateUrl: './activation-elements.component.html',
  styleUrls: ['./activation-elements.component.css']
})
export class ActivationElementsComponent implements OnInit {

  @Input() public type: ElementType;
  title: string;
  msg: string;


  constructor(
    private modal: NgbActiveModal,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    switch (this.type) {
      case 'local':
        this.title = this.translate.instant('Activación del local');
        this.msg = this.translate.instant('El local que has creado está desactivado por defecto, ¿Quieres activarlo ya?');
        break;
      case 'promo':
        this.title = this.translate.instant('Activación de la promoción');
        this.msg = this.translate.instant('La promoción que has creado está desactivada por defecto, ¿Quieres activarla ya?');
        break;
      case 'tarjeta':
        this.title = this.translate.instant('Activación de la tarjeta');
        this.msg = this.translate.instant('La tarjeta que has creado está desactivada por defecto, ¿Quieres activarla ya?');
        break;
      case 'menu':
        this.title = this.translate.instant('Activación de la carta');
        this.msg = this.translate.instant('La carta que has creado está desactivada por defecto, ¿Quieres activarla ya?');
        break;
      case 'ruta':
        this.title = this.translate.instant('Activación de la ruta');
        this.msg = this.translate.instant('La ruta que has creado está desactivada por defecto, ¿Quieres activarla ya?');
        break;
      case 'tapa':
        this.title = this.translate.instant('Activación de la tapa');
        this.msg = this.translate.instant('La tapa que has creado está desactivada por defecto, ¿Quieres activarla ya?');
        break;
      case 'event':
        this.title = this.translate.instant('Activación del evento');
        this.msg = this.translate.instant('El evento que has creado está desactivado por defecto, ¿Quieres activarlo ya?');
        break;
      case 'eventTicket':
          this.title = this.translate.instant('Activación de la entrada');
          this.msg = this.translate.instant('La entrada que has creado está desactivada por defecto, ¿Quieres activarla ya?');
          break;
      case 'eventProduct':
        this.title = this.translate.instant('Activación del producto');
        this.msg = this.translate.instant('El producto que has creado está desactivado por defecto, ¿Quieres activarlo ya?');
        break;
      default:
        break;
    }
  }

  close(election: number){
    this.modal.close({result: election});
  }


}
