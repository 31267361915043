import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import * as global from '../../../services/global.service';
import { Local } from '../../../models/local';
import { AuthenticationService } from '../../../services/authentication.service';
import { AdministracionCategoriaService } from '../../../services/administracionCategoria.service';
import { EspacesCategoriesComponent } from '../espaces-categories/espaces-categories.component';
import { AdministracionCategoria } from '../../../models/administracion-categoria';
import { AdministracionRequest } from '../../../interfaces/administracionRequest.model';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-espaces-categories-list',
  templateUrl: './espaces-categories-list.component.html',
  styleUrls: ['./espaces-categories-list.component.css']
})
export class EspacesCategoriesListComponent implements OnInit {

  public modalText: string;
  public modalRef: NgbModalRef;
  public categories: Array<AdministracionCategoria>;
  public category: AdministracionCategoria;
  public selectedAdmin: any;
  public searchterm: string;
  public searchlocal: string;
  public apiUrl: string;
  public confirm_title = '';
  public confirm_text = '';
  public isAdmin = false;

  /**
   *
   * @param modalService
   * @param spinner
   * @param translate
   * @param admincatService
   */
  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private admincatService: AdministracionCategoriaService,
    private notification: NotificationService,
    private auth: AuthenticationService) {

    this.categories = [];
  }

  ngOnInit() {
    if (this.auth.currentUserValue.user.rolId === 1) {
      this.isAdmin = true;
    }
    this.apiUrl = global.API_URL;
    if (!this.isAdmin) {
      this.fillCategories();
    }
  }

  public fillCategories(): void {

    this.spinner.show();
    const admonRequest: AdministracionRequest = new AdministracionRequest();
    admonRequest.setAdministracionId(this.auth.currentAdminValue.id);
    admonRequest.setIdiomas(true);

    this.admincatService.index(admonRequest).toPromise().then(
      (pagination: any) => {
        this.categories = pagination.data;
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  public openConfirm(modal: TemplateRef<any>, modalText: string, category?: AdministracionCategoria): void {
    this.modalText = modalText;
    if (category) {
      this.category = category;
      this.confirm_text = this.translate.instant('¿Esta seguro que desea eliminar la categoria?') + ' ' + category.nombre + '?';
      this.confirm_title = this.translate.instant('Eliminar categoría');
    }

    this.modalRef = this.modalService.open(modal, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false
    });
  }

  public cerrarModalConfirm() {
    this.modalRef.close();
  }

  public eliminarCategoria(): void {
    this.admincatService.delete(this.category).subscribe(
      (data: any) => {
        this.categories.splice(this.categories.indexOf(this.category), 1);
        this.modalRef.close();
        this.notification.success('La categoría ha sido eliminada correctamente.');
      },
      (error: HttpErrorResponse) => {
      });
  }

  public showEditCategory(category: AdministracionCategoria): void {
    const modalRef = this.modalService.open(EspacesCategoriesComponent,
      {
        centered: true,
        windowClass: 'newModal',
        backdrop: 'static',
        keyboard: false
      });
    modalRef.componentInstance.category = category;
    modalRef.result.then(
      (result: Local) => {
        this.fillCategories();
      },
      (reason) => {

      });
  }

  public openNewCategory(): void {

    const modalRef = this.modalService.open(EspacesCategoriesComponent,
      {
        centered: true,
        windowClass: 'newModal',
        backdrop: 'static',
        keyboard: false
      });

    modalRef.componentInstance.selectedAdmin = this.selectedAdmin;
    modalRef.result.then(
      (result: Local) => {
        this.fillCategories();
      },
      (reason) => {
      });
  }

  public cleanCustomer(): void {
    this.selectedAdmin = null;
    this.categories = [];
  }

}
