import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ext-feature-avaible',
  templateUrl: './next-feature-avaible.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./next-feature-avaible.component.scss'],
})
export class NextFeatureAvaibleComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

  close() {
    this.activeModal.close(0);
  }
}
