import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as global from '../../services/global.service';
import { AuthenticationService } from '../../services/authentication.service';
import { IdiomaService } from '../../services/idioma.service';
import { CustomerService } from '../../services/customers.service';
import { DestinationService } from '../../services/destination.service';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { SaldosService } from '../../v2/saldos/services/saldos.service';
import { IdiomaRequest } from '../../interfaces/idiomaRequest.model';
import { Idioma } from '../../models/idioma';
import { User } from '../../models/user';
import { Saldo } from '../../models/saldo';
import { PlanUpgradeComponent } from '../../v2/planes/plan-upgrade/plan-upgrade.component';
import { Local } from '../../models/local';
import { LocalService } from '../../services/local.service';
import { Pagination } from '../../models/pagination';
import { LocalRequest } from '../../interfaces/localRequest.model';
import { CustomersPaymentsComponent } from '../../v2/customers/customers-payments/customers-payments.component';
import { PlanService } from '../../services/plan.service';
import { Plan } from '../../models/plan';
import { PlanRequest } from '../../models/plan-request.model';
import { Events } from '../../services/events.service';
import { ChargeBalanceComponent } from '../../v2/saldos/charge-balance/charge-balance.component';
import { environment } from '../../../environments/environment';
import { UbnmNotificationService } from '../../v2/notifications/services/ubnmNotificationService.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NextFeatureAvaibleComponent } from '../../v2/saldos/next-feature-avaible/next-feature-avaible.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements AfterViewInit, OnInit {
  private modalRef: NgbModalRef;
  private modalConf: NgbModalRef;

  private notificationsSubscription: Subscription;
  public totalNumberNotifications = 0;
  public readNumberNotifications = 0;
  public unreadNumberNotifications = 0;

  public formCustomers: FormGroup;
  public isRed = true;
  public idiomas: Array<Idioma>;
  public config: PerfectScrollbarConfigInterface = {};
  public name: string;
  public user: User;
  public showPassword: false;
  public showConfirm: false;
  public levelPass = 0;
  public emailError = false;
  public nameError = false;
  public pasError = false;
  public updateProcess = false;
  public inputTypePassword: string;
  public inputTypeConfirm: string;
  public step_wizard: any;
  public formEditUser: FormGroup;
  public formType: FormGroup;
  public total_qr = 2;
  public total_app = 2;
  public confirm_title = '';
  public confirm_text = '';
  public isVersionPrueba = false;
  public idiomasCliente = false;
  public apiURL: string;
  public apiCDN: string;
  public activeLang = 'es';
  public idioma: Idioma;
  public nameDefault = 'Español';
  public altDEfault = 'es';
  public srcDefault = '/imgs/banderas/1563185291.png';
  public showHelpMenuIdioma = false;
  public saldo: Saldo;
  public locales: Array<Local>;
  public planes: Plan[];
  public minimumBalance = false;

  /**
   * 
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private auth: AuthenticationService,
    private idiomaService: IdiomaService,
    private customerService: CustomerService,
    private destinationService: DestinationService,
    private userService: UserService,
    private localService: LocalService,
    private saldosService: SaldosService,
    private planService: PlanService,
    private ubnmNotificationService: UbnmNotificationService,
    private event: Events
  ) {

    this.user = this.auth.currentUserValue.user;
    this.idiomas = [];
    this.translate.setDefaultLang(this.activeLang);

    const planRequest: PlanRequest = new PlanRequest();
    planRequest.setIdiomas(true);
    this.planService
      .index(planRequest)
      .toPromise()
      .then(
        (pagination: Pagination) => {
          this.planes = pagination.data;
        },
        (error: HttpErrorResponse) => {
        }
      );

    if (this.user && !this.user.wizard) {
      if (
        window.location.href.includes('panel.unblockthecity.com') ||
        window.location.href.includes('oldadmin.unblockthecity.com') ||
        window.location.href.includes('localhost')
      ) {
        this.isRed = true;
      } else {
        this.isRed = false;
      }

      /*const localRequest: LocalRequest = new LocalRequest();
      localRequest.setClienteId(this.user.cliente ? this.user.cliente.id : null);
      //localRequest.setClienteId(20);

      this.localService
          .index(localRequest)
          .toPromise()
          .then(
            (pagination: Pagination) => {
              this.locales = pagination.data;
            },
            (error: HttpErrorResponse) => {
              return Promise.resolve([]);
            }
          );
*/
      this.idiomaService
        .index()
        .pipe(
          map((response: any) => {
            return response.data;
          })
        )
        .toPromise()
        .then(
          (idiomas: Array<Idioma>) => {
            this.idiomas = idiomas;
          },
          (error: HttpErrorResponse) => {
            this.notificationService.logger('idiomaService', error);
          }
        );

      if (this.user.plan && this.user.plan.id > 1 && this.user.rolId !== 1) {
        this.viewSaldo();
      }
    }
  }

  /**
   * 
   */
  public viewSaldo(): void {
    this.auth.currentUser.subscribe((auth) => {

      if (auth !== undefined && auth !== null && auth.user.rolId > 1) {
        this.saldosService
          .today()
          .toPromise()
          .then(
            (saldo: Saldo) => {
              this.saldo = saldo;
              const warning = localStorage.getItem('warningBalance');
              let difDays = -1;
              if (warning !== null) {
                difDays = moment().startOf('day').diff(moment(warning).startOf('day'), 'days');
              }

              if (this.saldo.saldoFinal <= environment.minBalance && (warning === null || difDays === 1)) {
                this.minimumBalance = true;
              } else {
                this.minimumBalance = false;
              }
            },
            (error: HttpErrorResponse) => this.notificationService.error(error)
          );

      }

    });


  }

  /**
   * 
   */
  public viewNotificationsAlert(notificationsNumber: number): void {
    // Emitimos una notificación cuando se recibe una actualización
    this.notificationService.success('Tienes nuevas notificaciones.<br> Tienes <b>' + notificationsNumber + '</b> notifiaciones pendientes.', 'Notificaciones');
  }

  /**
   * 
   */
  showHelp() {
    this.showHelpMenuIdioma = !this.showHelpMenuIdioma;
  }

  /**
   * 
   */
  public cambiarIdioma(lang) {
    this.activeLang = lang;
    this.translate.use(lang);

    if (this.idiomas[0].codigo === 'es') {
      lang = 'es';
    }
    if (this.idiomas[0].codigo === 'en') {
      lang = 'en';
    }
    if (this.idiomas[0].codigo === 'ca') {
      lang = 'ca';
    }
    if (this.idiomas[0].codigo === 'gl') {
      lang = 'gl';
    }
    if (this.idiomas[0].codigo === 'fr') {
      lang = 'fr';
    }
  }

  /**
   * 
   */
  public changecode(item: any) {
    this.srcDefault = item.bandera;
    this.nameDefault = item.nombre;
  }

  /**
   * 
   */
  fillTable() {
    const idiomaRequest: IdiomaRequest = new IdiomaRequest();
    idiomaRequest.setOrderBy('ASC');
    this.idiomaService
      .index(idiomaRequest)
      .toPromise()
      .then(
        (pagination: any) => {
          this.idiomas = pagination.data;
        },
        (error: HttpErrorResponse) => {
        }
      );
  }

  /**
   * 
   */
  ngOnInit() {

    if (!this.isAdmin()) {
      //
      this.ubnmNotificationService.startNotificationsCheck();

      //
      this.notificationsSubscription = this.ubnmNotificationService.notifications$.subscribe((value) => {
        this.unreadNumberNotifications = value.unreadNumberNotifications;
        this.readNumberNotifications = value.readNumberNotifications;

        if (this.unreadNumberNotifications > 0 && value.totalNumberNotifications > this.totalNumberNotifications) {
          this.totalNumberNotifications = value.totalNumberNotifications;

          this.viewNotificationsAlert(this.unreadNumberNotifications);
        }
      });
    }

    //
    this.event.subscribe('upgradePlan', () => {
      this.planUpgrade(true);
    });

    //
    this.event.subscribe('reviewSaldo', () => {
      if (this.user.plan && this.user.plan.titulo !== 'BASIC') {
        this.viewSaldo()
      }
    });

    this.fillTable();
    this.apiURL = global.API_URL;
    this.apiCDN = global.CDN_URL;

    if (this.user.cupon) {
      /*this.total_app = this.user.cupon.numApp;
      this.total_qr = this.user.cupon.numQr;*/
    }

    if (this.isLocalhostOrPanel(location.hostname)) {
      this.isVersionPrueba = true;
    }

    this.formEditUser = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      confirm: [''],
    });

    this.formCustomers = this.formBuilder.group({
      id: [null, Validators.required],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      ciudad: [''],
      telefono: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contacto: [''],
      cif: ['', Validators.required],
      idiomaId: [null,],
    });

    this.formType = this.formBuilder.group({
      codigo: [''],
    });

    this.inputTypePassword = 'password';
    this.inputTypeConfirm = 'password';
  }

  /**
   * 
   */
  ngOnDestroy() {
    this.notificationsSubscription ? this.notificationsSubscription.unsubscribe() : false;
  }

  public isAdmin(): boolean {
    return this.auth.currentUserValue && this.auth.currentUserValue.user.rolId === 1;
  }

  /**
   * 
   */
  ngAfterViewInit() {
    this.formEditUser.patchValue(this.user);
    this.formEditUser.patchValue({ password: '' });

    this.cdRef.detectChanges();

    const set = function () {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      const topOffset = 0;
      if (width < 1170) {
        $('#main-wrapper').addClass('mini-sidebar');
      } else {
        $('#main-wrapper').removeClass('mini-sidebar');
      }
    };

    $(window).ready(set);
    $(window).on('resize', set);

    $('.search-box a, .search-box .app-search .srh-btn').on(
      'click',
      function () {
        $('.app-search').toggle(200);
      }
    );
    $('#sidebarnav li a:not(.has_child)').on('click', function () {
      $('.left-sidebar').removeClass('visible');
    });
    $('body').trigger('resize');
  }

  isLocalhostOrPanel(url) {
    return (
      url.includes('localhost') ||
      url.includes('127.0.0.1') ||
      url.includes('panel.unblockthecity.com')
    );
  }

  clickMenu($event) {
    if ($('.left-sidebar').hasClass('visible')) {
      $('.left-sidebar').removeClass('visible');
    } else {
      $('.left-sidebar').addClass('visible');
    }
    event.stopPropagation();
  }

  /**
   * 
   */
  closeMenu() {
    if ($('.left-sidebar').hasClass('visible')) {
      $('.left-sidebar').removeClass('visible');
    }
  }

  /**
   * 
   */
  logout() {
    this.auth.logout();
  }

  /**
   * 
   */
  public open(content): void {
    if (this.auth.currentCustomerValue || this.auth.currentAdminValue) {
      this.formEditUser.patchValue({ password: '' });

      let request: Observable<Object>;

      if (this.auth.currentCustomerValue) {
        request = this.customerService.show(this.auth.currentCustomerValue.id);
      } else {
        request = this.destinationService.show(this.auth.currentAdminValue.id);
      }

      request.subscribe((response: any) => {

        this.formCustomers.patchValue({
          id: response.id,
          nombre: response.nombre,
          direccion: response.direccion,
          ciudad: response.ciudad,
          telefono: response.telefono,
          email: response.email,
          contacto: response.contacto,
          cif: response.cif,
          idiomaId: response.idiomaId,
        });

        this.modalRef = this.modalService.open(content, {
          centered: true,
          windowClass: 'newModal',
          backdrop: 'static',
          keyboard: false,
        });
      });
    }
  }

  /**
   * 
   */
  clearContent(input: string) {
    this.formEditUser.get(input).reset();
  }

  /**
   * 
   */
  abrirModalConfirm(modal) {
    this.modalConf = this.modalService.open(modal, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  /**
   * 
   */
  cerrarModalConfirm() {
    this.modalConf.close();
  }

  /**
   * 
   */
  showPasswordToggle(info: any) {
    this.showPassword = info;

    if (this.showPassword) {
      this.inputTypePassword = 'text';
    } else {
      this.inputTypePassword = 'password';
    }
  }

  /**
   * 
   */
  showConfirmToggle(info: any) {
    this.showConfirm = info;

    if (this.showConfirm) {
      this.inputTypeConfirm = 'text';
    } else {
      this.inputTypeConfirm = 'password';
    }
  }

  /**
   * 
   */
  onInputPass(value) {
    let cont = 0;

    if (value.length === 0) {
      this.levelPass = 0;
      return;
    }

    if (value.length > 7) {
      cont++;
    }

    const matches = value.match(/\d+/g);
    if (matches != null) {
      cont++;
    }

    if (/[A-Z]/.test(value)) {
      cont++;
    }

    if (cont === 0) {
      cont++;
    }
    this.levelPass = cont;
  }

  /**
   * 
   */
  closeBalanceMinimum() {
    this.minimumBalance = false;
    localStorage.setItem('warningBalance', moment().toISOString());
    this.notificationService.logger('warningBalance', localStorage.getItem('warningBalance'));
  }

  /**
   * 
   */
  saveCoupon() {
    const user: User = this.user;

    user.codigo = this.formType.get('codigo').value;

    this.userService.update(user).subscribe((data: any) => {
      if (data.error && data.error === 1) {
        this.notificationService.error(data.mensaje);
      } else {
        this.auth.updateUser(data.user);
        /*this.total_app = this.user.cupon.numApp;
        this.total_qr = this.user.cupon.numQr;*/
        this.formType.reset();
        this.notificationService.success('Codigo cupon actualizado');
      }
      this.cerrarModalConfirm();
      this.modalRef.dismiss();
    });
  }

  /**
   * 
   */
  newCoupon(formValue, modal) {
    if (formValue.codigo !== '') {
      this.confirm_title = this.translate.instant('Cupon descuento');
      // tslint:disable-next-line: max-line-length
      this.confirm_text = this.translate.instant(
        '¿Quieres asignar el cupón descuento a tu cuenta de usuario?, Cualquier cupón previo quedara sustituido por el nuevo.'
      );
      this.abrirModalConfirm(modal);
    } else {
      this.modalRef.dismiss();
    }
  }

  /**
   * 
   */
  public editCustomers(formValue: any): void {

    if (this.formCustomers.valid) {

      this.spinner.show();

      this.customerService.update(formValue).subscribe(
        (data) => {
          this.notificationService.success('Cliente actualizado correctamente');

          this.modalRef.close();

          this.customerService.byUserId(this.user.id).subscribe((data: any) => {
            if (data.cliente) {
              this.auth.updateCustomer(data.cliente);
            }
          });
          this.spinner.hide();
        },
        (error) => {
          this.notificationService.error('No se han editado los datos de cliente, vuelva a intentarlo');
          this.spinner.hide();
        }
      );
    } else {

      Object.keys(this.formCustomers.controls).forEach(key => {

        let field = this.formCustomers.get(key);

        if (field.status === 'INVALID' && field.errors !== null && 'required' in field.errors) {
          this.notificationService.error('Campo ' + key + ' requerido.*');
          return;
        }

      });

    }
  }

  /**
   * 
   */
  editUser(formValue: any) {
    this.pasError = false;
    this.emailError = false;
    this.nameError = false;

    if (!this.formEditUser.valid) {
      if (formValue.name !== '') {
        this.emailError = true;
        this.nameError = false;
      } else {
        this.emailError = true;
        this.nameError = true;
      }
    } else {
      if (formValue.password !== '') {
        if (this.levelPass >= 3) {
          if (formValue.password !== formValue.confirm) {
            this.pasError = true;
            this.updateProcess = false;
          } else {
            this.pasError = false;
            this.updateProcess = true;
          }
        } else {
          this.pasError = true;
          this.updateProcess = false;
        }
      } else {
        this.updateProcess = true;
      }
    }

    if (this.updateProcess) {
      const user: User = this.user;

      user.name = formValue.name;
      user.email = 'panel' + formValue.email;
      user.password = formValue.password;

      this.userService.update(user).subscribe(
        (data: User) => {
          if (formValue.password === '') {
            this.auth.updateUser({ ...this.auth.currentUserValue, user: data });
            this.user = this.auth.currentUserValue;
            this.modalRef.close();
            this.formEditUser.patchValue({ password: '' });
            this.formEditUser.patchValue({ confirm: '' });
          }

          this.notificationService.success('Usuario actualizado con exito');

          if (formValue.password !== '') {
            // this.auth.logout();
          }
        },
        (error) => {
          this.notificationService.error('No se ha editado el usuario, vuelva a intentarlo');
        }
      );
    }
  }

  /**
   * 
   */
  public planUpgrade(enterbasicmessage?: boolean): void {
    /*if (this.locales.length > 0) {
      const modalRef = this.modalService.open(PlanUpgradeComponent, {
        size: 'lg',
        windowClass: 'plan-upgrade',
        backdrop: 'static',
        keyboard: true,
        backdropClass: 'light-backdrop plan-upgrade',
      });

      modalRef.componentInstance.locales = this.locales;

      modalRef.result.then(
        (result: any) => {
          console.log(result);
          this.user = this.auth.currentUserValue.user;
          this.viewSaldo();
          this.event.publish('reviewSlots');
        },
        (reason) => {
          if (enterbasicmessage) {
            this.event.publish('planReview');
          }
          console.log(reason);
        }
      );

     } else {*/
    const modalRef = this.modalService.open(CustomersPaymentsComponent, {
      size: 'lg',
      windowClass: 'plan-upgrade',
      backdrop: 'static',
      keyboard: true,
      backdropClass: 'light-backdrop plan-upgrade',
    });

    let userPlan = this.auth.currentUserValue.user.plan;

    let plan = null;
    if (userPlan.id === 1) {
      let nuevoPlanId = userPlan.id + 1;
      plan = this.planes.find((e) => e.id == nuevoPlanId);
    } else if (userPlan.id === 2) {
      let nuevoPlanId = userPlan.id + 1;
      plan = this.planes.find((e) => e.id == nuevoPlanId);
    }

    if (this.user.plan == null || this.user.plan.id == 1) {
      modalRef.componentInstance.firstTime = true;
    }

    modalRef.componentInstance.plan = plan;
    modalRef.result.then(
      (result: any) => {
        this.user = this.auth.currentUserValue.user;
        this.viewSaldo();
        this.event.publish('reviewSlots');
      },
      (reason) => {
        this.notificationService.logger('CustomersPaymentsComponent-> REASON' + reason);
      }
    );
    //}
  }

  /**
   * 
   */
  public chargeBalance(type?) {
    const modalRef = this.modalService.open(ChargeBalanceComponent, {
      size: 'lg',
      windowClass: 'plan-upgrade',
      backdrop: 'static',
      keyboard: true,
      backdropClass: 'light-backdrop plan-upgrade',
    });

    modalRef.componentInstance.type = type;
    modalRef.result.then(
      (result: any) => {
        this.notificationService.logger('modalRef->result->', result);
        this.user = this.auth.currentUserValue.user;
        this.viewSaldo();
      },
      (reason) => {
        this.notificationService.logger('modalRef->reason->', reason);
      }
    );
  }

  /**
   * 
   */
  public nextFeatureAvailable() {
    const modalRef = this.modalService.open(NextFeatureAvaibleComponent, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then(
      (result: any) => {
        this.notificationService.logger('modalRef->result->', result);
        this.user = this.auth.currentUserValue.user;
      },
      (reason) => {
        this.notificationService.logger('modalRef->reason->', reason);
      }
    );
  }
}
