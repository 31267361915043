import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-wizard-header',
  templateUrl: './wizard-header.component.html',
  styleUrls: ['./wizard-header.component.css']
})
export class WizardHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public step: number;

  @Input()
  public lastStep: number;

  @Input()
  public stepNumber: number;
  
  @Input()
  public skipSteps: number;

  @Input()
  public editItem: object = null;

  @Output()
  public onSelect: EventEmitter<number> = new EventEmitter<number>();

  public get isVisible() {
    return this.lastStep >= this.stepNumber || !!this.editItem;
  }

  public doClick() {
    this.onSelect.emit( +(Number(this.stepNumber) + Number(this.skipSteps)));
  }
}
