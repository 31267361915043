import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as global from '../../../services/global.service';
import { objToCamelCase } from '../../../helpers/object.helper';
import { Pagination } from '../../../models/pagination';
import { Saldo } from '../../../models/saldo';
import { SaldoRequest } from '../models/saldo-request.model';
import { AuthenticationService } from '../../../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SaldosService {

  private static SALDOS_PATH = global.API_URL + '/api/saldos';

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private user: AuthenticationService) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public today(): Observable<Saldo> {

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<Saldo>(SaldosService.SALDOS_PATH + '/today', this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Saldo>(response);
      })
    );
  }

  public index(saldoRequest: SaldoRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (saldoRequest.getCliente()) {
      httpParams = httpParams.set('cliente', String(saldoRequest.getCliente()));
    }

    if (saldoRequest.getClienteId()) {
      httpParams = httpParams.set('clienteId', String(saldoRequest.getClienteId()));
    }

    if (saldoRequest.getCreatedAtStart()) {
      httpParams = httpParams.set('createdAtStart', saldoRequest.getCreatedAtStart());
    }

    if (saldoRequest.getCreatedAtEnd()) {
      httpParams = httpParams.set('createdAtEnd', saldoRequest.getCreatedAtEnd());
    }

    if (saldoRequest.getPerPage()) {
      httpParams = httpParams.set('perPage', String(saldoRequest.getPerPage()));
    }

    if (saldoRequest.getOrderBy()) {
      httpParams = httpParams.set('orderBy', saldoRequest.getOrderBy());
    }

    if (saldoRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', saldoRequest.getSortBy())
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(SaldosService.SALDOS_PATH, this.httpOptions).pipe(
      map((response: any) => {

        return objToCamelCase(response);
      })
    );
  }

  public addSaldo(form){

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    const formData : FormData = new FormData();

    for ( var key in form ) {
      formData.append(key, form[key]);
    }

    return this.http.post(SaldosService.SALDOS_PATH + '/add-balance', formData);
  }
}
