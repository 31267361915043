import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as global from './global.service';
import { Pagination } from '../models/pagination';
import { CiudadRequest } from '../interfaces/ciudadRequest.model';
import { objToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class CiudadService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(ciudadRequest: CiudadRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (ciudadRequest.getPaisId()) {
      httpParams = httpParams.set('paisId', String(ciudadRequest.getPaisId()));
    }

    if (ciudadRequest.getProvinciaId()) {
      httpParams = httpParams.set('provinciaId', String(ciudadRequest.getProvinciaId()));
    }

    if (ciudadRequest.getCiudadId()) {
      httpParams = httpParams.set('ciudadId', String(ciudadRequest.getCiudadId()));
    }

    if (ciudadRequest.getNombre()) {
      httpParams = httpParams.set('nombre', ciudadRequest.getNombre());
    }

    if (ciudadRequest.getUnblock()) {
      httpParams = httpParams.set('unblock', String(ciudadRequest.getUnblock()));
    }

    if (ciudadRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(ciudadRequest.getPerPage()));
    }

    if (ciudadRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(ciudadRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/cities`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase(response);
      })
    );
  }

}
