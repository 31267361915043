import { Component, OnDestroy, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(private router: Router, private render: Renderer2, public translate: TranslateService) {
    translate.addLangs(['es', 'en', 'ca', 'gl']);
    translate.setDefaultLang('es');

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (window.innerWidth < 768) {
          $('#main-wrapper').addClass('mini-sidebar');
        }
      }
    });
  }
}
