import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as global from './global.service';
import { objToCamelCase } from '../helpers/object.helper';
import { Pagination } from '../models/pagination';
import { PlanRequest } from '../models/plan-request.model';
import { Plan } from '../models/plan';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private static PLANES_PATH = global.API_URL + '/api/planes';

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  public show(id: number, planRequest: PlanRequest): Observable<Plan> {
    let httpParams: HttpParams = new HttpParams();

    if (planRequest.getCaracteristicas()) {
      httpParams = httpParams.set(
        'caracteristicas',
        String(planRequest.getCaracteristicas())
      );
    }

    if (planRequest.getConfiguracion()) {
      httpParams = httpParams.set(
        'configuracion',
        String(planRequest.getConfiguracion())
      );
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Plan>(PlanService.PLANES_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Plan>(response);
        })
      );
  }

  public index(planRequest: PlanRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (planRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(planRequest.getIdiomas()));
    }

    if (planRequest.getCaracteristicas()) {
      httpParams = httpParams.set(
        'caracteristicas',
        String(planRequest.getCaracteristicas())
      );
    }

    if (planRequest.getPerPage()) {
      httpParams = httpParams.set('perPage', String(planRequest.getPerPage()));
    }

    if (planRequest.getOrderBy()) {
      httpParams = httpParams.set('orderBy', String(planRequest.getOrderBy()));
    }

    if (planRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', String(planRequest.getSortBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(PlanService.PLANES_PATH, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public create(plan: Plan): Observable<Plan> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .post<Plan>(PlanService.PLANES_PATH, plan, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase(response);
        })
      );
  }

  public update(plan: Plan): Observable<Plan> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .put<Plan>(
        PlanService.PLANES_PATH + `/${plan.id}`,
        plan,
        this.httpOptions
      )
      .pipe(map((response: any) => objToCamelCase<Plan>(response)));
  }

  public checkLimit(activar: Object): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .put<any>(
        PlanService.PLANES_PATH + `/check-limit`,
        activar,
        this.httpOptions
      )
      .pipe(map((response: any) => objToCamelCase<any>(response)));
  }

  public delete(plan: Plan): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(PlanService.PLANES_PATH + `/${plan.id}`, this.httpOptions)
      .pipe(map((response: any) => objToCamelCase(response)));
  }
}
