import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Pagination } from '../models/pagination';
import { AdministracionCategoria } from '../administrations/interfaces/administracion-categoria';
import { Administracion } from '../administrations/interfaces/administracion';
import { AdministracionRequest } from '../interfaces/administracionRequest.model';
import * as global from './global.service';
import { objsToCamelCase } from '../helpers/object.helper';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdministracionCategoriaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(administracionRequest: AdministracionRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (administracionRequest.getAdministracionId()) {
      httpParams = httpParams.set('administracionId', String(administracionRequest.getAdministracionId()));
    }

    if (administracionRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(administracionRequest.getPerPage()));
    }

    if (administracionRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(administracionRequest.getOrderBy()));
    }

    if (administracionRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(administracionRequest.getIdiomas()));
    }


    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL
      + `/api/administration/${administracionRequest.getAdministracionId()}/categorias`, this.httpOptions).pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public create(administracion: Administracion){

    this.httpOptions = {
    headers: this.httpHeaders,
      params: {}
    };

    return this.http.post<Administracion>(global.API_URL
      + `/api/administration/${administracion.id}/categorias`, administracion, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(administracion: Administracion) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.put<Administracion>(global.API_URL +
      `/api/administration/${administracion.id}/categorias`, administracion, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(administracionCategoria: AdministracionCategoria) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + 
      `/api/administration/${administracionCategoria.administracionId}/categorias/${administracionCategoria.id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }
}
