import * as global from "../../../services/global.service";
import * as moment from "moment";
import * as ClassicEditorBuild from "@ckeditor/ckeditor5-build-classic";
import { environment } from "../../../../environments/environment";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { CropperComponent } from "angular-cropperjs";
import { AuthenticationService } from "../../../services/authentication.service";
import { AdministracionCategoriaService } from "../../../services/administracionCategoria.service";
import { AdministracionCategoria } from "../../../administrations/interfaces/administracion-categoria";
import { AdministracionRequest } from "../../../interfaces/administracionRequest.model";
import { Local } from "../../../models/local";
import { Idioma } from "../../../models/idioma";
import { Categoria } from "../../../models/categoria";
import { SubCategoria } from "../../../models/subCategoria";
import { LocalCaracteristica } from "../../../models/localCaracteristica";
import { LocalService } from "../../../services/local.service";
import { IdiomaService } from "../../../services/idioma.service";
import { CategoriaService } from "../../../services/categoria.service";
import { ComunidadAutonomaService } from "../../../services/comunidadAutonoma.service";
import { CaracteristicaService } from "../../../services/caracteristica.service";
import { PaisService } from "../../../services/pais.service";
import { NotificationService } from "../../../services/notification.service";
import { BlacklistService } from "../../masters/blacklist/services/blacklist.service";
import { TranslationService } from "../../../services/translation.service";
import { RedesSocialesService } from "../../../services/redes-sociales.service";
import { ProvinciaService } from "../../../services/provincia.service";
import { CiudadService } from "../../../services/ciudad.service";
import { RutaService } from "../../../services/ruta.service";
import { ProvinciaRequest } from "../../../interfaces/provinciaRequest.model";
import { CiudadRequest } from "../../../interfaces/ciudadRequest.model";
import { IdiomaRequest } from "../../../interfaces/idiomaRequest.model";
import { CaracteristicaRequest } from "../../../interfaces/caracteristicaRequest.model";
import { ComunidadAutonomaRequest } from "../../../interfaces/comunidadAutonomaRequest.model";
import { CategoriaRequest } from "../../../interfaces/categoriaRequest.model";
import { PaisRequest } from "../../../interfaces/paisRequest.model";
import { Ruta } from "../../../models/ruta";
import { ComunidadAutonoma } from "../../../models/comunidadAutonoma";
import { Provincia } from "../../../models/provincia";
import { Ciudad } from "../../../models/ciudad";
import { Pais } from "../../../models/pais";
import { Pagination } from "../../../models/pagination";
import { HttpErrorResponse } from "@angular/common/http";
import { RedSocialRequest } from "../../../interfaces/red-social-request.model";
import { RedSocial } from "../../../models/red-social";
import { ActivationElementsComponent } from "../../components/activation-elements/activation-elements.component";
import {
  getLanguagesFilled,
  verifyLanguageFields,
} from "../../../helpers/object.helper";

enum Fields {
  Descripcion = 1,
  //TituloLanding = 2,
  DescripcionCorta = 3,
}

@Component({
  selector: "app-espaces",
  templateUrl: "./espaces.component.html",
  styleUrls: ["./espaces.component.scss"],
})
export class EspacesComponent implements OnInit {
  private geoCoder: google.maps.Geocoder;

  @Input()
  public selectedCustomer: any;

  @Input()
  public selectedAdmin: any;

  @Input()
  public local: Local;

  @Input()
  public type: number;

  @ViewChild("searchText")
  public searchElementRef: ElementRef;

  @ViewChild("angularCropper")
  public angularCropper: CropperComponent;

  public readonly spainId: number = environment.spainId;

  public configEditor: object = {
    placeholder: "Descripción",
    toolbar: [],
  };

  public configModal: NgbModalOptions = {
    centered: true,
    windowClass: "newModal",
    backdrop: "static",
    keyboard: false,
  };

  public mandatoryLangFields = ["descripcion", "descripcioncorta"];
  public langFields = ["descripcion", "descripcioncorta"];
  public classicEditorBuild: ClassicEditorBuild = ClassicEditorBuild;
  public administracionCategorias: Array<AdministracionCategoria>;
  public form: FormGroup;
  public searchForm: FormGroup;
  public locales: Array<Local>;
  public paises: Array<Pais>;
  public comunidades: Array<ComunidadAutonoma>;
  public provincias: Array<Provincia>;
  public ciudades: Array<Ciudad>;
  public categorias: Array<Categoria>;
  public step: number;
  public permisos: boolean;
  public zoom: number;
  public submitted = false;
  public fields: Fields;
  public mainImageChanged = false;
  public subCategorias: Array<SubCategoria>;
  public step_local_max = 1;
  public geomessage = false;
  public tituloRuta: any;
  public segundoRango = false;
  public schedule: Array<any>;
  public imagenCropped: any;
  public descriptionDemoCropped: any;
  public dias: any[] = this.buildDays();
  public diasCerrado: any[];
  public formHorarioHabitual: FormGroup;
  public coverUrl: any = "";
  public imagenUrl: any = "";
  public landingUrl: any = "";
  public logolandingUrl: any = "";
  public idDestino: number;
  public hasShownGeoError = false;
  public errorEmail = false;
  public isAdmin = false;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private administracionCategoriaService: AdministracionCategoriaService,
    private localService: LocalService,
    private idiomaService: IdiomaService,
    private categoriaService: CategoriaService,
    private comunidadAutonomaService: ComunidadAutonomaService,
    private caracteristicaService: CaracteristicaService,
    private rutaService: RutaService,
    private provinciaService: ProvinciaService,
    private ciudadService: CiudadService,
    private translate: TranslateService,
    private googleTranslate: TranslationService,
    private paisService: PaisService,
    private blackListService: BlacklistService,
    private notification: NotificationService,
    private redesSocialesService: RedesSocialesService
  ) {
    this.step = 1;
    this.zoom = 13;
    this.administracionCategorias = [];
    this.locales = [];
    this.paises = [];
    this.comunidades = [];
    this.provincias = [];
    this.ciudades = [];
    this.categorias = [];
    this.subCategorias = [];
    this.schedule = [];
    this.diasCerrado = [];
    this.idDestino = 0;
  }

  public ngOnInit() {
    this.form = this.buildForm();

    if (this.type === 2) {
      if (this.selectedCustomer || this.selectedAdmin) {
        if (this.selectedCustomer) {
          this.idDestino = 0;
          // TO DO : Mensaje de error no es posible para usuarios de tipo cliente
        } else {
          this.idDestino = this.selectedAdmin.id;
        }
      } else {
        if (this.auth.currentCustomerValue) {
          this.idDestino = 0;
          // TO DO : Mensaje de error no es posible para usuarios de tipo cliente y no puede entrar en esta url
        } else if (this.auth.currentAdminValue) {
          this.idDestino = this.auth.currentAdminValue.id;
        }
      }

      const administracionRequest = new AdministracionRequest();
      administracionRequest.setAdministracionId(this.idDestino);

      this.administracionCategoriaService
        .index(administracionRequest)
        .toPromise()
        .then(
          (pagination: any) =>
            (this.administracionCategorias = pagination.data),
          (error) => this.notification.error(error)
        );
    }

    const paisRequest: PaisRequest = new PaisRequest();
    paisRequest.setPerPage(300);

    this.paisService
      .index(paisRequest)
      .toPromise()
      .then(
        (pagination: any) => (this.paises = pagination.data),
        (error) => this.notification.error(error)
      );

    const caracteristicaRequest: CaracteristicaRequest =
      new CaracteristicaRequest();
    const categoriaRequest: CategoriaRequest = new CategoriaRequest();
    const idiomaRequest: IdiomaRequest = new IdiomaRequest();
    idiomaRequest.setOrderBy("ASC");
    categoriaRequest.setSubcategorias(true);

    if (this.local) {
      this.form.patchValue({ latitud: parseFloat(this.local.latitud) });
      this.form.patchValue({ longitud: parseFloat(this.local.longitud) });
      this.localService
        .byId(this.local.id)
        .toPromise()
        .then((local: any) => {
          this.local = local;
          this.imagenCropped = global.CDN_URL + this.local.imagen;
          //this.imagenUrl = global.CDN_URL + this.local.imagen;
          this.landingUrl = global.CDN_URL + this.local.landing;
          this.logolandingUrl = global.CDN_URL + this.local.logolanding;
          this.coverUrl = global.CDN_URL + this.local.cover;
          // Se ponen a null por que vienen de base de datos con las url de donde estan guardadas,
          // y si no se ponen a null al enviar el formulario
          // se haria con estos valores y daria error la api
          this.local.imagen = null;
          this.local.logolanding = null;
          this.local.landing = null;
          this.local.cover = null;

          this.form.get("paisId").setValue(this.local.paisId);
          this.changeStepLocal(1);
          this.getComunidades(this.local.paisId);
          this.getCiudades(this.local.provinciaId);

          if (this.local.paisId === this.spainId) {
            this.getProvincias(this.local.comunidadautonomaId);
          }
          this.form.patchValue(this.local);

          if (this.local.horarioLocal && this.local.horarioLocal.length > 0) {
            this.getHorario();
          } else {
            this.initSchedule();
          }

          this.categoriaService
            .index(categoriaRequest)
            .toPromise()
            .then(
              (pagination: any) => {
                this.categorias = pagination.data;
                this.subCategorias = this.categorias.find(
                  (categoria) => categoria.id === this.local.categoriaId
                ).subcategorias;
              },

              (error) => this.notification.error(error)
            );

          this.caracteristicaService
            .index(caracteristicaRequest)
            .toPromise()
            .then(
              (pagination: any) => {
                pagination.data.map((element) => {
                  let id: number = null;

                  if (this.local.caracteristicas) {
                    const localCaracteristica: LocalCaracteristica =
                      this.local.caracteristicas.find(
                        (caracteristica) =>
                          caracteristica.caracteristicaId === element.id
                      );
                    if (localCaracteristica) {
                      id = localCaracteristica.id;
                    }
                  }
                  (this.form.get("caracteristicas") as FormArray).push(
                    new FormGroup({
                      id: new FormControl(id),
                      caracteristicaId: new FormControl(element.id),
                      titulo: new FormControl(element.titulo),
                    })
                  );
                });
              },

              (error) => this.notification.error(error)
            );

          const redSocialRequest: RedSocialRequest = new RedSocialRequest();

          this.redesSocialesService
            .index(redSocialRequest)
            .toPromise()
            .then(
              (pagination: Pagination) => {
                for (const key in pagination.data) {
                  const element: RedSocial = pagination.data[key];
                  let redSocial: RedSocial;

                  if (this.local && this.local.redesSociales) {
                    redSocial = this.local.redesSociales.find(
                      (red) => red.pivot.redSocialId === element.id
                    );
                  }

                  (this.form.get("redesSociales") as FormArray).push(
                    new FormGroup({
                      id: new FormControl(redSocial ? redSocial.id : null),
                      nombre: new FormControl(element.nombre),
                      localId: new FormControl(
                        redSocial && redSocial.pivot.localId
                          ? redSocial.pivot.localId
                          : null
                      ),
                      redSocialId: new FormControl(element.id),
                      link: new FormControl(
                        redSocial && redSocial.pivot.link
                          ? redSocial.pivot.link
                          : null
                      ),
                    })
                  );
                }
              },
              (error: HttpErrorResponse) => {
                this.notification.error(error.message);
              }
            );

          this.idiomaService
            .index(idiomaRequest)
            .toPromise()
            .then(
              (pagination: any) => {
                pagination.data.map((element: Idioma) => {
                  const traduccion = this.local.idiomas.filter(
                    (trad) => trad.idiomaId === element.id
                  );
                  (this.form.get("idiomas") as FormArray).push(
                    new FormGroup({
                      id: new FormControl(
                        traduccion.length > 0 ? traduccion[0].id : null
                      ),
                      idiomaId: new FormControl(element.id),
                      codigo: new FormControl(element.codigo),
                      idioma: new FormControl(element.nombre),
                      descripcion: new FormControl(
                        traduccion.length > 0 ? traduccion[0].descripcion : null
                      ),
                      descripcioncorta: new FormControl(
                        traduccion.length > 0
                          ? traduccion[0].descripcioncorta
                          : "",
                        [Validators.maxLength(60), Validators.minLength(0)]
                      ),
                      /*titulolanding: new FormControl(
                        traduccion.length > 0
                          ? traduccion[0].titulolanding
                          : null
                      ),*/
                    })
                  );
                });
              },
              (error) => this.notification.error(error)
            );
        });
    } else {
      this.categoriaService
        .index(categoriaRequest)
        .toPromise()
        .then(
          (pagination: any) => (this.categorias = pagination.data),
          (error) => this.notification.error(error)
        );

      this.caracteristicaService
        .index(caracteristicaRequest)
        .toPromise()
        .then(
          (pagination: any) => {
            pagination.data.map((element) => {
              (this.form.get("caracteristicas") as FormArray).push(
                new FormGroup({
                  id: new FormControl(null),
                  caracteristicaId: new FormControl(element.id),
                  titulo: new FormControl(element.titulo),
                })
              );
            });
          },

          (error) => this.notification.error(error)
        );

      const redSocialRequest: RedSocialRequest = new RedSocialRequest();

      this.redesSocialesService
        .index(redSocialRequest)
        .toPromise()
        .then(
          (pagination: Pagination) => {
            for (const key in pagination.data) {
              const element: RedSocial = pagination.data[key];

              (this.form.get("redesSociales") as FormArray).push(
                new FormGroup({
                  id: new FormControl(null),
                  nombre: new FormControl(element.nombre),
                  localId: new FormControl(null),
                  redSocialId: new FormControl(element.id),
                  link: new FormControl(null),
                })
              );
            }
          },
          (error: HttpErrorResponse) => {
            this.notification.error(error.message);
          }
        );

      this.idiomaService
        .index(idiomaRequest)
        .toPromise()
        .then(
          (pagination: any) => {
            pagination.data.map((element: Idioma) => {
              (this.form.get("idiomas") as FormArray).push(
                new FormGroup({
                  id: new FormControl(null),
                  idiomaId: new FormControl(element.id),
                  codigo: new FormControl(element.codigo),
                  idioma: new FormControl(element.nombre),
                  descripcion: new FormControl(null),
                  descripcioncorta: new FormControl("", [
                    Validators.maxLength(60),
                    Validators.minLength(0),
                  ]),
                  //titulolanding: new FormControl(null),
                })
              );
            });
          },

          (error) => this.notification.error(error)
        );

      if (this.selectedCustomer || this.selectedAdmin) {
        if (this.selectedCustomer) {
          this.form.patchValue({ clienteId: this.selectedCustomer.id });
        } else {
          this.form.patchValue({ administracionId: this.selectedAdmin.id });
        }
      } else {
        if (this.auth.currentCustomerValue) {
          this.form.patchValue({
            clienteId: this.auth.currentCustomerValue.id,
          });
        } else if (this.auth.currentAdminValue) {
          this.form.patchValue({
            administracionId: this.auth.currentAdminValue.id,
          });
        }
      }

      if (this.type === 1) {
        this.form.patchValue({ oficinaturismo: 1 });
        this.form.patchValue({ categoriaId: 23 });
        this.form.patchValue({ subcategoriaId: 41 });
      }
      this.initSchedule();
    }
    this.searchForm = this.formBuilder.group({
      nombre: [null, Validators.required],
      ciudad: [null, Validators.required],
    });

    this.formHorarioHabitual = this.formBuilder.group({
      hora_ini_turno_1: ["", Validators.required],
      hora_fin_turno_1: ["", Validators.required],
      hora_ini_turno_2: ["", Validators.required],
      hora_fin_turno_2: ["", Validators.required],
    });

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.form.controls["latitud"].setValue(place.geometry.location.lat());
          this.form.controls["longitud"].setValue(
            place.geometry.location.lng()
          );
          this.form.controls["direccion"].setValue(place.formatted_address);
        });
      });
    });
  }

  public selectCaracteristica(caracteristica: AbstractControl): void {
    if (caracteristica.get("id").value) {
      caracteristica.get("id").setValue(null);
    } else {
      caracteristica.get("id").setValue(-1);
    }
  }

  public selectAll(): void {
    this.form
      .get("caracteristicas")
      ["controls"].forEach((caracteristica: AbstractControl) => {
        caracteristica.get("id").setValue(-1);
      });
  }
  public desSelectAll(): void {
    this.form
      .get("caracteristicas")
      ["controls"].forEach((caracteristica: AbstractControl) => {
        caracteristica.get("id").setValue(null);
      });
  }
  /**
   *
   * @param cuponId
   */
  public checkHasRoute(cuponId: number): void {
    this.rutaService
      .valid(cuponId)
      .toPromise()
      .then(
        (ruta: Ruta) => this.form.patchValue({ ruta_id: ruta.id }),
        (error) => this.notification.error(error)
      );
  }

  public checkEmail(email) {
    const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    this.errorEmail = regex.test(email) ? false : true;
  }

  numberOnly(e) {
    // Accept only numerics
    const regex = new RegExp("^[0-9 ]+$");
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  public preview(event: any): void {
    const files = event.target.files;
    const name = event.target.name;

    if (files.length === 0) {
      return;
    }

    if (files[0].size > 2000000) {
      this.notification.error(
        "No pueden subirse archivos de mayor tamaño a 2 mb."
      );
      return;
    }

    if (files[0].type.match(/image\/*/) == null) {
      this.notification.error(
        "Solo se permiten subir imagenes con este formato."
      );
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      if (name === "imagen") {
        this.form.patchValue({ imagen: reader.result });
        this.imagenUrl = reader.result;
      } else {
        if (name === "landing") {
          this.form.patchValue({ landing: reader.result });
          this.landingUrl = reader.result;
        } else {
          if (name === "logolanding") {
            this.form.patchValue({ logolanding: reader.result });
            this.logolandingUrl = reader.result;
          } else {
            if (name === "cover") {
              this.form.patchValue({ cover: reader.result });
              this.coverUrl = reader.result;
            }
          }
        }
      }
    };
  }

  getCroppedImage() {
    this.angularCropper.cropper.getCroppedCanvas().toBlob(
      (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.imagenCropped = reader.result as string;
          this.form.patchValue({ imagen: reader.result });
          // Que coño es esto???????? que hace aqui???? para que sirve??????
          //this.descriptionDemoCropped = this.form.value.descripcion.substring(0, 60) + '...';
        };
      },
      "image/jpeg",
      0.95
    );
  }
  public getComunidades(id?: number | string): void {
    this.form.get("comunidadautonomaId").patchValue(null);
    this.form.get("provinciaId").patchValue(null);
    this.form.get("ciudadId").patchValue(null);
    this.comunidades = [];
    this.provincias = [];
    this.ciudades = [];
    if (id === "null") {
      return;
    }

    if (id) {
      if (+id === this.spainId) {
        const comunidadAutonomaRequest = new ComunidadAutonomaRequest();

        this.comunidadAutonomaService.index(comunidadAutonomaRequest).subscribe(
          (data: any) => (this.comunidades = data.data),
          (error) => this.notification.error(error)
        );
      } else {
        this.getProvincias(id);
      }
    }
  }

  public getProvincias(id?: number | string): void {
    this.form.get("provinciaId").patchValue(null);
    this.form.get("ciudadId").patchValue(null);
    this.provincias = [];
    this.ciudades = [];

    if (id === "null") {
      return;
    }

    if (id) {
      const provinciaRequest: ProvinciaRequest = new ProvinciaRequest();

      if (+this.form.get("paisId").value === this.spainId) {
        provinciaRequest.setComunidadAutonomaId(Number(id));
        this.provinciaService
          .index(provinciaRequest)
          .toPromise()
          .then(
            (pagination: any) => (this.provincias = pagination.data),
            (error) => this.notification.error(error)
          );
      } else {
        provinciaRequest.setPaisId(this.form.get("paisId").value);
        this.provinciaService.index(provinciaRequest).subscribe(
          (pagination: any) => (this.provincias = pagination.data),
          (error) => this.notification.error(error)
        );
      }
    }
  }

  public getCiudades(id?: number | string): void {
    this.form.get("ciudadId").patchValue(null);
    this.ciudades = [];

    if (id === "null") {
      return;
    }

    if (id) {
      const ciudadRequest: CiudadRequest = new CiudadRequest();
      ciudadRequest.setProvinciaId(Number(id));
      ciudadRequest.setPerPage(300);
      this.ciudadService.index(ciudadRequest).subscribe(
        (pagination: any) => (this.ciudades = pagination.data),
        (error) => this.notification.error(error)
      );
    }
  }

  addRango(i) {
    this.segundoRango = i === 1 ? true : false;
  }

  toggleCerrar(index) {
    this.schedule[index].cerrado = !this.schedule[index].cerrado;
    if (this.schedule[index].cerrado) {
      this.schedule[index].horas = [];
      this.schedule[index].horas.push({
        horaIni: null,
        horaFin: null,
        id: null,
      });
    }
  }

  public changeStepLocal(step: number): void {
    this.submitted = false;

    if (step === 2) {
      if (
        this.form.value.nombre &&
        this.form.value.email &&
        this.form.value.telefono &&
        this.form.value.idiomas[0].descripcion &&
        this.form.value.idiomas[0].descripcioncorta
      ) {
        const languages = verifyLanguageFields(
          this.form.value.idiomas,
          this.mandatoryLangFields,
          this.langFields
        );
        if (languages.length > 0) {
          const languagesnames = languages.map((objeto) => objeto).join(", ");
          this.notification.error(
            "Existen idiomas donde no has rellenado campos obligatorios: " +
              languagesnames
          );
        } else {
          if (this.form.value.website) {
            this.checkUrl(
              this.form.value.website,

              () => {
                if (this.type === 2) {
                  this.step = step + 1;
                } else {
                  this.step = step;
                }

                if (step > this.step_local_max) {
                  if (this.type === 2) {
                    this.step_local_max = step + 1;
                  } else {
                    this.step_local_max = step;
                  }
                }
              }
            );
          } else {
            if (this.type === 2) {
              this.step = step + 1;
            } else {
              this.step = step;
            }

            if (step > this.step_local_max) {
              if (this.type === 2) {
                this.step_local_max = step + 1;
              } else {
                this.step_local_max = step;
              }
            }
          }
        }
      } else {
        this.notification.error("Los campos marcados con * son obligatorios");
      }
    } else if (step === 5) {
      if (this.form.value.categoriaId && this.form.value.subcategoriaId) {
        this.step = step;
        if (step > this.step_local_max) {
          this.step_local_max = step;
        }
      } else {
        this.notification.error("Selecciona la categoría y subcategoría");
      }
    } else if (step === 4) {
      if (
        ((this.form.value.paisId === this.spainId &&
          this.form.value.comunidadautonomaId) ||
          this.form.value.paisId !== this.spainId) &&
        this.form.value.provinciaId &&
        this.form.value.ciudadId &&
        this.form.value.direccion
      ) {
        this.step = step;

        if (step > this.step_local_max) {
          this.step_local_max = step;
        }
      } else {
        this.notification.error("Los campos  con * son obligatorios");
      }
    } else if (step === 3) {
      if (this.form.value.latitud && this.form.value.longitud) {
        if (
          this.form.value.latitud === 39.470242 &&
          this.form.value.longitud === -0.3768 &&
          !this.hasShownGeoError
        ) {
          this.hasShownGeoError = true;
          this.notification.error(
            "¿Estas seguro que quieres mantener esta posición?"
          );
        } else {
          this.step = step;
          if (step > this.step_local_max) {
            this.step_local_max = step;
          }
        }
      } else {
        this.notification.error(
          "Debes de posicionar en el mapa la ubicación de tu espacio"
        );
      }
    } else if (step === 8) {
      if (!this.form.get("imagen").value && !this.local) {
        this.notification.error("Se debe subir una imagen principal");
      } else if (
        this.imagenCropped === null ||
        this.imagenCropped === "" ||
        this.imagenCropped === undefined
      ) {
        this.notification.error("Debes de recortar la imagen");
      } else {
        this.step = step;
        if (step > this.step_local_max) {
          this.step_local_max = step;
        }
      }
    } else if (step === 9) {
      if (
        //this.form.value.idiomas[0].titulolanding &&
        (this.form.value.logolanding || this.logolandingUrl !== "") &&
        (this.form.value.landing || this.landingUrl !== "")
      ) {
        this.step = step;
        if (step > this.step_local_max) {
          this.step_local_max = step;
        }
      } else {
        this.notification.error("Los campos marcados con * son obligatorios");
      }
    } else if (step === 7) {
      const check = this.checkSchedule();

      if (check === 1) {
        this.notification.error("Revise los formatos de horas.");
        this.spinner.hide();
        return;
      } else if (check === 2) {
        this.notification.error(
          "Repase las horas ya que algun turno no se ha rellenado"
        );
        this.spinner.hide();
        return;
      } else if (check === 3) {
        this.notification.error(
          "Repase las horas, tiene una hora de salida anterior a la entrada"
        );
        this.spinner.hide();
        return;
      }

      this.step = step;
      if (step > this.step_local_max) {
        this.step_local_max = step;
      }
    } else {
      this.step = step;
      if (step > this.step_local_max) {
        this.step_local_max = step;
      }
    }
  }

  public categoria(categoria: Categoria): void {
    this.subCategorias = categoria.subcategorias;
  }

  public subCategoria(subCategoria: SubCategoria): void {
    if (!this.local) {
      this.coverUrl = global.CDN_URL + subCategoria.cover;
      this.form.patchValue({ cover: global.CDN_URL + subCategoria.cover });
      this.form.patchValue({ coverUrl: global.CDN_URL + subCategoria.cover });
    }
  }

  public create(): void {
    if (this.form.valid) {
      if (
        !this.form.value.nombre ||
        !this.form.value.email ||
        !this.form.value.telefono ||
        !this.form.value.idiomas[0].descripcion ||
        !this.form.value.idiomas[0].descripcioncorta ||
        !this.form.value.direccion
      ) {
        this.notification.error("Rellena todos los campos obligatorios (*).");
        return;
      }

      const languages = verifyLanguageFields(
        this.form.value.idiomas,
        this.mandatoryLangFields,
        this.langFields
      );
      if (languages.length > 0) {
        const languagesnames = languages.map((objeto) => objeto).join(", ");
        this.notification.error(
          "Existen idiomas donde no has rellenado campos obligatorios: " +
            languagesnames
        );
        return;
      }

      this.submitted = true;
      this.form.patchValue({ premium: 0 });

      const check = this.checkSchedule();

      if (this.type === 2 && !this.form.value.administracionCategoriaId) {
        this.notification.error(
          "Debe seleccionar una sección para el espacio."
        );
        this.spinner.hide();
        return;
      }

      if (!this.local && this.type === 0 && this.step === 6) {
        if (!this.form.get("imagen").value) {
          this.notification.error("Se debe subir una imagen principal");
          return;
        }
      }

      if (this.local) this.spinner.show();

      if (check === 1) {
        this.notification.error("Revise los formatos de horas.");
        this.spinner.hide();
        return;
      } else if (check === 2) {
        this.notification.error(
          "Repase las horas ya que algun turno no se ha rellenado"
        );
        this.spinner.hide();
        return;
      } else if (check === 3) {
        this.notification.error(
          "Repase las horas, tiene una hora de salida anterior a la entrada"
        );
        this.spinner.hide();
        return;
      }
      this.form.patchValue({ schedule: this.schedule });

      const local: Local = this.form.value;
      const caracteristicas: Array<LocalCaracteristica> = [];
      const redesSociales: Array<RedSocial> = [];

      for (const key in local.caracteristicas) {
        if (local.caracteristicas[key].id) {
          caracteristicas.push({
            caracteristicaId: local.caracteristicas[key].caracteristicaId,
          });
        }
      }

      local.caracteristicas = caracteristicas;

      for (const key in local.redesSociales) {
        if (local.redesSociales[key].link) {
          redesSociales.push({
            redSocialId: local.redesSociales[key].redSocialId,
            link: local.redesSociales[key].link,
          });
        }
      }

      local.redesSociales = redesSociales;

      const idiomas: any = getLanguagesFilled(
        local.idiomas,
        this.mandatoryLangFields
      );
      local.idiomas = idiomas;

      local.descripcion = local.idiomas[0].descripcion;
      local.descripcioncorta = local.idiomas[0].descripcioncorta;
      //local.titulolanding = local.idiomas[0].titulolanding;

      if (local.website) {
        this.checkUrl(local.website, () => this.saveLocal(local));
      } else {
        this.saveLocal(local);
      }
    } else {
      this.notification.error(
        "Revisa todos los pasos y rellena los campos obligatorios"
      );
    }
  }

  public markerDragEnd($event: MouseEvent) {
    this.form.controls["latitud"].setValue($event.coords.lat);
    this.form.controls["longitud"].setValue($event.coords.lng);

    this.geoCoder.geocode(
      { location: { lat: $event.coords.lat, lng: $event.coords.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.form.controls["direccion"].setValue(
              results[0].formatted_address
            );
            /*this.notification.success(
              "Dirección registrada: " + results[0].formatted_address
            );*/
          } else {
            //this.notification.error("No se encontraron resultados");
          }
        } else {
          //this.notification.error("Fallo en Geocoder debido a:" + status);
        }
      }
    );
  }

  toggleCerrado(index) {
    this.schedule[index].cerrado = !this.schedule[index].cerrado;
    this.schedule[index].todashoras = false;
    this.schedule[index].turnos = false;

    this.schedule[index].horas = [];
    this.schedule[index].horas.push({ horaIni: null, horaFin: null, id: null });
  }

  toggleLabelTurnos(index) {
    this.schedule[index].turnos = !this.schedule[index].turnos;
    if (!this.schedule[index].turnos) {
      this.schedule[index].cerrado = false;
      this.schedule[index].todashoras = true;
    } else {
      this.schedule[index].cerrado = false;
      this.schedule[index].todashoras = false;
    }
  }

  toggleWholeDay(index) {
    this.schedule[index].cerrado = false;
    this.schedule[index].todashoras = !this.schedule[index].todashoras;

    if (this.schedule[index].todashoras) {
      this.schedule[index].turnos = false;
      this.schedule[index].horas = [];
      this.schedule[index].horas.push({
        horaIni: null,
        horaFin: null,
        id: null,
      });
    }
  }

  check(index) {
    if (this.schedule[index].todashoras === true) {
      this.toggleWholeDay(index);
    }
  }

  toggleTurnos(index, operation, slotindex?) {
    const reg = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";
    if (!this.schedule[index].cerrado) {
      if (operation === 1) {
        const ultimo = this.schedule[index].horas.length - 1;
        if (this.schedule[index].todashoras) {
          this.notification.error(
            "No se pueden agregar turnos, el horario está marcado para todo el día."
          );
        } else if (
          this.schedule[index].horas[ultimo].horaFin === null ||
          this.schedule[index].horas[ultimo].horaFin === "" ||
          this.schedule[index].horas[ultimo].horaIni === null ||
          this.schedule[index].horas[ultimo].horaIni === ""
        ) {
          this.notification.error(
            "No se pueden agregar mas turnos si no se han rellenado todos."
          );
        } else if (
          !this.schedule[index].horas[ultimo].horaIni.match(reg) &&
          !this.schedule[index].horas[ultimo].horaFin.match(reg)
        ) {
          this.notification.error("Revise los formatos de horas.");
        } else {
          this.schedule[index].horas.push({
            horaIni: null,
            horaFin: null,
            id: null,
          });
        }
      } else {
        if (this.schedule[index].horas.length > 1) {
          this.schedule[index].horas.splice(slotindex, 1);
        }
      }
    }
  }

  changeValue(diaIndex, horaIndex, horaIniOrFin, value) {
    this.schedule[diaIndex].horas[horaIndex][horaIniOrFin] = value;
  }

  getHorario() {
    const horarioLocal = this.local.horarioLocal;

    if (horarioLocal.length > 0) {
      let tempSchedule: any = {};
      for (let i = 0; i < this.dias.length; i++) {
        tempSchedule = {};
        tempSchedule.dia = this.dias[i].dia;
        tempSchedule.nombre = this.dias[i].nombre;
        tempSchedule.cerrado = true;
        tempSchedule.todashoras = false;
        tempSchedule.horas = [];
        let indexHoras = 0;
        for (let j = 0; j < horarioLocal.length; j++) {
          if (this.dias[i].dia === horarioLocal[j].dia) {
            tempSchedule.cerrado = horarioLocal[j].cerrado;
            tempSchedule.todashoras = horarioLocal[j].todashoras;
            tempSchedule.id = horarioLocal[j].id;
            tempSchedule.horas.push({
              horaIni: horarioLocal[j].horaIni,
              horaFin: horarioLocal[j].horaFin,
              id: horarioLocal[j].id,
            });
            indexHoras++;
          }
        }
        if (tempSchedule.horas.length === 0) {
          tempSchedule.horas.push({ horaIni: null, horaFin: null, id: null });
        }
        this.schedule.push(tempSchedule);
      }
    }
  }

  initSchedule() {
    this.schedule = [];
    this.dias.forEach((dia) => {
      this.schedule.push({
        dia: dia.dia,
        nombre: dia.nombre,
        cerrado: dia.cerrado,
        todashoras: true,
        turnos: false,
        showDaysToDuplicate: false,
        horas: [{ horaIni: null, horaFin: null, id: null }],
      });
    });
  }

  checkSchedule(): number {
    let result = 0;
    const reg = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";
    this.schedule.forEach((dia) => {
      if (dia.cerrado === false || dia.cerrado === 0) {
        let cont = 0;
        dia.horas.forEach((hora) => {
          cont++;
          if (
            (hora.horaIni === null || hora.horaIni === "") &&
            (hora.horaFin === null || hora.horaFin === "") &&
            dia.horas.length === 1
          ) {
            // dia.cerrado = true;
            if (!dia.cerrado && !dia.todashoras) {
              result = 2;
            }
          } else {
            if (
              (hora.horaIni === null || hora.horaIni === "") &&
              (hora.horaFin === null || hora.horafin === "")
            ) {
              result = 2;
            } else {
              if (
                hora.horaIni === null ||
                hora.horaIni === "" ||
                !hora.horaIni.match(reg)
              ) {
                result = 1;
              } else if (
                hora.horaFin === null ||
                hora.horaFin === "" ||
                !hora.horaFin.match(reg)
              ) {
                result = 1;
              } else if (
                moment(hora.horaIni, "HH:mm").isAfter(
                  moment(hora.horaFin, "HH::mm")
                )
              ) {
                result = 3;
              }
            }
          }
        });
      }
    });

    return result;
  }

  public doTranslate(field, target, index) {
    const idiomas = this.form.get("idiomas").value;
    this.spinner.show();

    const obj: any = {};
    obj.source = this.form.value.idiomas[0].codigo;
    obj.target = target;

    if (field === Fields.Descripcion) {
      if (this.form.value.idiomas[0].descripcion) {
        obj.text = this.form.value.idiomas[0].descripcion;
      } else {
        this.notification.error(
          "Debes tener datos en el campo para poder traducir"
        );
        return;
      }
    } else if (field === Fields.DescripcionCorta) {
      if (this.form.value.idiomas[0].descripcioncorta) {
        obj.text = this.form.value.idiomas[0].descripcioncorta;
      } else {
        this.notification.error(
          "Debes tener datos en el campo para poder traducir"
        );
        return;
      }
    } /*else if (field === Fields.TituloLanding) {
      if (this.form.value.idiomas[0].titulolanding) {
        obj.text = this.form.value.idiomas[0].titulolanding;
      } else {
        this.notification.error(
          "Debes tener datos en el campo para poder traducir"
        );
        return;
      }
    }*/

    this.googleTranslate
      .translate(obj)
      .toPromise()
      .then(
        (res: any) => {
          if (field === Fields.Descripcion) {
            idiomas[index].descripcion = res.text;
          } else if (field === Fields.DescripcionCorta) {
            idiomas[index].descripcioncorta = res.text;
          } /*else if (field === Fields.TituloLanding) {
            idiomas[index].titulolanding = res.text;
          }*/
          (this.form.get("idiomas") as FormArray).patchValue(idiomas);
          this.spinner.hide();
        },
        (error) => this.spinner.hide()
      );
  }

  private buildDays(): any[] {
    return [
      {
        dia: 0,
        nombre: this.translate.instant("Lunes"),
        cerrado: false,
        editar: false,
        selected: false,
      },
      {
        dia: 1,
        nombre: this.translate.instant("Martes"),
        cerrado: false,
        editar: false,
        selected: false,
      },
      {
        dia: 2,
        nombre: this.translate.instant("Miércoles"),
        cerrado: false,
        editar: false,
        selected: false,
      },
      {
        dia: 3,
        nombre: this.translate.instant("Jueves"),
        cerrado: false,
        editar: false,
        selected: false,
      },
      {
        dia: 4,
        nombre: this.translate.instant("Viernes"),
        cerrado: false,
        editar: false,
        selected: false,
      },
      {
        dia: 5,
        nombre: this.translate.instant("Sábado"),
        cerrado: false,
        editar: false,
        selected: false,
      },
      {
        dia: 6,
        nombre: this.translate.instant("Domingo"),
        cerrado: false,
        editar: false,
        selected: false,
      },
    ];
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      nombre: [""],
      direccion: [""],
      comunidadautonomaId: [null],
      provinciaId: [null],
      ciudadId: [null],
      telefono: [null],
      email: [""],
      website: [""],
      latitud: [39.470242],
      longitud: [-0.3768],
      clienteId: [null],
      administracionId: [null],
      imagen: [""],
      cover: [""],
      coverUrl: [""],
      categoriaId: [""],
      subcategoriaId: [""],
      rutaId: [null],
      ruta: [null],
      activo: [1],
      tipolocal: [3],
      landing: [null],
      logolanding: [null],
      //titulolanding: [null],
      caracteristicas: this.formBuilder.array([]),
      schedule: [""],
      oficinaturismo: [0],
      administracionCategoriaId: [null],
      idiomas: this.formBuilder.array([]),
      paisId: [null],
      redesSociales: this.formBuilder.array([]),
    });
  }

  private saveLocal(local: Local) {
    if (this.local || this.form.value.id) {
      local.id = this.local ? this.local.id : this.form.value.id;

      this.localService
        .update(local)
        .toPromise()
        .then(
          (localdata: any) => {
            this.notification.success(
              "Los datos del espacio se han actualizado."
            );
            this.activeModal.close(localdata);
          },

          (error) => this.notification.error(error)
        );
    } else {
      const activationModal = this.modalService.open(
        ActivationElementsComponent,
        this.configModal
      );

      activationModal.componentInstance.type = "local";
      activationModal.result.then((response) => {
        local.activo = response.result;
        this.spinner.show();
        this.localService
          .create(local)
          .toPromise()
          .then(
            (localdata: any) => {
              this.notification.success(
                "El espacio se ha creado correctamente."
              );
              this.activeModal.close(localdata);
              this.spinner.hide();
            },

            (error) => {
              this.spinner.hide();
              this.notification.error(
                "Ocurrió un error al guardar el espacio.",
                error
              );
            }
          );
      });
    }
  }

  private checkUrl(url: string, callback?: any) {
    this.blackListService.containsWord(url).then(
      (hasForbiddenWord: boolean) => {
        if (hasForbiddenWord) {
          this.notification.error("La URL contiene una palabra prohibida");
        } else if (callback) {
          callback();
        }
      },

      (error) => this.notification.error(error)
    );
  }
}
