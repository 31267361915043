import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public translate(object) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post(global.API_URL + '/api/translations', object, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
