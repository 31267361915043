import * as global from './global.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { Ruta, RutaSponsor, RutaLocal } from '../models/ruta';
import { RutaRequest } from '../interfaces/rutaRequest.model';
import { Local } from '../models/local';
import { map } from 'rxjs/operators';
import { objToCamelCase } from '../helpers/object.helper';

@Injectable({
  providedIn: 'root'
})
export class RutaService {
  private static RUTAS_PATH = global.API_URL + "/api/routes";
  private static LOCAL_PATH = global.API_URL + "/api/locals";
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public byId(id: number): Observable<Ruta> {
    this.httpOptions = {
      headers: this.httpHeaders,
    };
    return this.http
      .get<Ruta>(RutaService.RUTAS_PATH + `/${id}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objToCamelCase<Ruta>(response);
        })
      );
  }

  public index(rutaRequest: RutaRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (rutaRequest.getUserId()) {
      httpParams = httpParams.set('userId', String(rutaRequest.getUserId()));
    }

    if (rutaRequest.getClienteId()) {
      httpParams = httpParams.set(
        'clienteId',
        String(rutaRequest.getClienteId())
      );
    }

    if (rutaRequest.getIdiomas()) {
      httpParams = httpParams.set('idiomas', String(rutaRequest.getIdiomas()));
    }

    if (rutaRequest.getAdministracion()) {
      httpParams = httpParams.set('administracion', String(rutaRequest.getAdministracion()));
    }

    if (rutaRequest.getAdministracionId()) {
      httpParams = httpParams.set('administracionId', String(rutaRequest.getAdministracionId()));
    }

    if (rutaRequest.getLocalId()) {
      httpParams = httpParams.set('localId', String(rutaRequest.getLocalId()));
    }

    if (rutaRequest.getFechaPromo()) {
      httpParams = httpParams.set('fechaPromo', rutaRequest.getFechaPromo());
    }

    if (rutaRequest.getFechaFin()) {
      httpParams = httpParams.set('fechaFin', rutaRequest.getFechaFin());
    }

    if (rutaRequest.getDemo()) {
      httpParams = httpParams.set('demo', String(rutaRequest.getDemo()));
    }

    if (rutaRequest.getLatitud()) {
      httpParams = httpParams.set('latitud', String(rutaRequest.getLatitud()));
    }

    if (rutaRequest.getLongitud()) {
      httpParams = httpParams.set('longitud', String(rutaRequest.getLongitud()));
    }

    if (rutaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(rutaRequest.getPerPage()));
    }

    if (rutaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(rutaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(RutaService.RUTAS_PATH, this.httpOptions)
    .pipe(
      map((response: any) => {
        return objToCamelCase<Pagination>(response);
      })
    );
  }

  public valid(cuponId: number): Observable<Ruta> {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.get<Ruta>(global.API_URL + `/api/routes/valid/${cuponId}`, this.httpOptions);
  }

  public create(ruta: Ruta): Observable<Ruta> {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.post<Ruta>(global.API_URL + '/api/routes', ruta, this.httpOptions);
  }

  public update(ruta: Ruta): Observable<Ruta> {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.put<Ruta>(global.API_URL + '/api/routes/' + ruta.id, ruta, this.httpOptions);
  }

  public updateRutaLocal(rutaLocal: RutaLocal): Observable<Ruta> {

    this.httpOptions = {
      headers: this.httpHeaders,
    };
    return this.http.put<RutaLocal>(RutaService.LOCAL_PATH + '/changeactiverutalocal/' + rutaLocal.rutaId + '/' + rutaLocal.localId, rutaLocal, this.httpOptions);
  }

  public delete(ruta: Ruta): Observable<any> {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>( global.API_URL + '/api/routes/' + ruta.id, this.httpOptions);
  }


  //GESTION DE PATROCINADORES DE LAS RUTAS

  public createSponsor(sponsor:RutaSponsor){
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.post(global.API_URL + '/api/patrocinadoresrutas', sponsor, this.httpOptions);
  }

  public deleteSponsor(id:number){
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete(global.API_URL + '/api/patrocinadoresrutas/'+id, this.httpOptions);
  }

  public updateSponsor(sponsor:RutaSponsor){
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.put(global.API_URL + '/api/patrocinadoresrutas/'+sponsor.id,sponsor, this.httpOptions);
  }

  //GESTION DE LOCALES DE LAS RUTAS

  public addSpace(space:Local){
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.post(global.API_URL + '/api/localesrutas', space, this.httpOptions);
  }

  public deleteSpace(id:number){
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete(global.API_URL + '/api/localesrutas/'+id, this.httpOptions);
  }

  //GESTIÓN DE TAPAS DE LAS RUTAS

  public deleteTapa(id:number){
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete(global.API_URL + '/api/tapas/'+id, this.httpOptions);
  }

  public deleteSpaceFromRoute( local: number, route: number) {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete( global.API_URL + '/api/locals/' + local + '/from-route/' + route, this.httpOptions);
  }

}
