import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { QuestionRequest } from '../interfaces/questionRequest.model';
import { Question } from '../models/question';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  public byId(Id: number) {
    this.httpOptions = {
      headers: this.httpHeaders,
    };
    return this.http
      .get<Question>(global.API_URL + '/api/questions/' + Id, this.httpOptions)
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public getAll() {

    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('wizard', '1');

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http.get(global.API_URL + '/api/questions', this.httpOptions);
  }

  public index(questionRequest: QuestionRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (questionRequest.getBack()) {
      httpParams = httpParams.set('back', String(questionRequest.getBack()));
    }

    if (questionRequest.getIdiomas()) {
      httpParams = httpParams.set(
        'idiomas',
        String(questionRequest.getIdiomas())
      );
    }

    if (questionRequest.getCategoriaId()) {
      httpParams = httpParams.set(
        'categoriaId',
        String(questionRequest.getCategoriaId())
      );
    }

    if (questionRequest.getCategoria()) {
      httpParams = httpParams.set(
        'categoria',
        String(questionRequest.getCategoria())
      );
    }

    if (questionRequest.getOrderBy()) {
      httpParams = httpParams.set('orderBy', questionRequest.getOrderBy());
    }

    if (questionRequest.getSortBy()) {
      httpParams = httpParams.set('sortBy', questionRequest.getSortBy());
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(global.API_URL + `/api/questions`, this.httpOptions)
      .pipe(
        map((response: any) => {
          response.data = objsToCamelCase<Question>(response.data);

          return response;
        })
      );
  }

  getAllCategoriasAyuda() {
    return this.http.get(global.API_URL + '/api/helpcategories');
  }

  public create(question: Question) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('idiomas', String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<Question>(
        global.API_URL + '/api/questions',
        question,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public update(question: Question) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('idiomas', String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<Question>(
        global.API_URL + '/api/questions/' + question.id,
        question,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public delete(question: Question) {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(
        global.API_URL + '/api/questions/' + question.id,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }
}
