import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EspacesComponent } from '../../espaces/espaces/espaces.component';


@Component({
  selector: 'app-plan-space-go',
  templateUrl: './plan-space-go.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./plan-space-go.component.scss'],
})
export class PlanSpaceGoComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  exit() {
    localStorage.removeItem('ubwizardfinish');
    this.activeModal.close();
  }

  goToLocals() {
    this.router.navigate(['/espaces/list/0']);
    localStorage.removeItem('ubwizardfinish');
    this.activeModal.close();
    setTimeout( () => this.openNewLocal(),500);
  }

  openNewLocal(){
    const configModal: NgbModalOptions = {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false,
    };

    const espacesModal = this.modalService.open(EspacesComponent, configModal);

    espacesModal.componentInstance.type = 0;
  }


}
