import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Pagination } from '../models/pagination';
import * as global from './global.service';
import { BusinessEspacio } from '../models/business_espacio';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { BusinessEspacioRequest } from '../interfaces/businessEspacioRequest.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessEspacioService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(businessEspacioRequest: BusinessEspacioRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (businessEspacioRequest.getClienteId()) {
      httpParams = httpParams.set('clienteId', String(businessEspacioRequest.getClienteId()));
    }

    if (businessEspacioRequest.getClienteProId()) {
      httpParams = httpParams.set('clienteproId', String(businessEspacioRequest.getClienteProId()));
    }

    if (businessEspacioRequest.getDestinoId()) {
      httpParams = httpParams.set('destinoId', String(businessEspacioRequest.getDestinoId()));
    }

    if (businessEspacioRequest.getEspacioId()) {
      httpParams = httpParams.set('espacioId', String(businessEspacioRequest.getEspacioId()));
    }

    if (businessEspacioRequest.getLocal()) {
      httpParams = httpParams.set('local', String(businessEspacioRequest.getLocal()));
    }

    if (businessEspacioRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(businessEspacioRequest.getPerPage()));
    }

    if (businessEspacioRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(businessEspacioRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/businessespacio`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(peticiones: any) {

    let httpParams: HttpParams = new HttpParams();

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<any>(global.API_URL + '/api/businessespacio', peticiones, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(businessEspacio: BusinessEspacio): Observable<BusinessEspacio> {

    this.httpOptions = {
      headers: this.httpHeaders.delete('Content-Type'),
      params: {}
    };

    return this.http.put<BusinessEspacio>(global.API_URL + '/api/businessespacio/' + businessEspacio.id, businessEspacio, this.httpOptions);
  }

  public delete(businessEspacio: BusinessEspacio) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/businessespacio/' + businessEspacio.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}
