import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { ROUTES_3 } from './menu-items';
import { ROUTES_4 } from './menu-items';
import { ROUTES_5 } from './menu-items';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { Events } from '../../services/events.service';
import { User } from '../../models/user';
import { CustomersPaymentsComponent } from '../../v2/customers/customers-payments/customers-payments.component';
import { PlanBuyLocalsAskComponent } from '../../v2/planes/plan-buy-locals-ask/plan-buy-locals-ask.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Plan } from '../../models/plan';
import { PlanRequest } from '../../models/plan-request.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PlanService } from '../../services/plan.service';
import { VersionService } from '../../services/version.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  showCardsMenu = false;
  showLocalsMenu = false;
  showAdsMenu = false;
  showRouteMenu = false;
  showInfoDestinationMenu = false;
  showUnblockPayMenu = false;
  showResourcesMenu = false;
  showResourcesSeeMenu = false;
  showEventsMenu = false;
  showQueVerMenu = false;
  showQueHacerMenu = false;
  showEventsOtherMenu = false;
  clickedsubEvents = false;
  showHelpMenu = false;
  showPromocionesMenu = false;
  showDestinosMenu = false;
  showCityManagersMenu = false;
  showMaestrosMenu = false;
  showConfigsMenu = false;
  showCampaignsMenu = false;
  adminMenu = true;
  hasevents: any;
  hasruta: any;
  isPublicAdmin: any;
  isSolepropiertorship: boolean;
  public user: User;
  public sidebarnavItems: any[];
  public planes: Plan[];

  constructor(
    private auth: AuthenticationService,
    private notificationService: NotificationService,
    public versionService: VersionService,
    private modalService: NgbModal,
    private planService: PlanService,
    private event: Events
  ) {
    this.event.subscribe('checkuser', () => {
      this.checkUser();
    });

    this.event.subscribe('changePlan', () => {
      this.user = this.auth.currentUserValue.user;
    });
  }

  // End open close
  ngOnInit() {
    this.user = this.auth.currentUserValue.user;
    const planRequest: PlanRequest = new PlanRequest();
    planRequest.setOrderBy('ASC');

    this.planService
      .index(planRequest)
      .toPromise()
      .then(
        (pagination: any) => {
          this.planes = pagination.data;
        },
        (error: HttpErrorResponse) => {
        }
      );

    if (this.user && !this.user.wizard) {
      this.checkUser();

      $(function () {
        $('.sidebartoggler').on('click', function () {
          if ($('#main-wrapper').hasClass('mini-sidebar')) {
            $('body').trigger('resize');
            $('#main-wrapper').removeClass('mini-sidebar');
          } else {
            $('body').trigger('resize');
            $('#main-wrapper').addClass('mini-sidebar');
          }
        });
      });
    }
  }

  // this is for the open close
  public addExpandClass(element: any) {
    this.showMenu = element === this.showMenu ? '0' : element;
  }

  public addActiveClass(element: any) {
    this.showSubMenu = element === this.showSubMenu ? '0' : element;
  }

  public checkUser() {
    if (this.user) {
      this.adminMenu = true;
      if (this.user.rolId !== 1) {
        if (this.user.rolId === 8) {
          this.sidebarnavItems = ROUTES_5.filter(
            (sidebarnavItem) => sidebarnavItem
          );
        }

        if (this.user.rolId === 3) {
          this.sidebarnavItems = ROUTES_3.filter(
            (sidebarnavItem) => sidebarnavItem
          );
        }

        if (this.user.rolId === 4 || this.user.rolId === 6) {
          this.adminMenu = false;
          this.sidebarnavItems = ROUTES_4.filter(
            (sidebarnavItem) => sidebarnavItem
          );
        }
      } else {
        this.sidebarnavItems = ROUTES.filter(
          (sidebarnavItem) => sidebarnavItem
        );
      }

      this.isPublicAdmin = this.user.rolId === 6;

      this.hasruta = this.user.rutaId && this.user.rutaId > 0 ? 1 : 0;
      this.hasevents = this.user.cliente && this.user.cliente.hasevents === 0 ? 0 : 1;

      // Comprobamos si el cliente asociado al usuario es autónomo o empresa.
      this.isSolepropiertorship = this.user.cliente.isAutonomo === 1;
    }
  }

  public checkPlan(): boolean {
    return !this.user.planId && this.user.rolId > 1 || this.user.planId === environment.planBasicId;
  }

  public messagePlanBasic(event: Event): void {
    if (this.checkPlan()) {
      event.preventDefault();
      this.mostrarMensajeCompra();
    }

    this.close(event);
  }

  public mostrarMensajeCompra() {
    const modalRef = this.modalService.open(PlanBuyLocalsAskComponent, {
      centered: true,
      windowClass: 'newModal',
      backdrop: 'static',
      keyboard: false,
    });

    if (this.user.plan && this.user.plan.id === 1) {
      modalRef.componentInstance.basic = true;
      modalRef.componentInstance.pro = false;

      modalRef.componentInstance.funcionpro = true;
      modalRef.componentInstance.funcionpremium = false;
    } else if (this.user.plan && this.user.plan.id === 2) {
      modalRef.componentInstance.basic = false;
      modalRef.componentInstance.pro = true;

      modalRef.componentInstance.funcionpro = false;
      modalRef.componentInstance.funcionpremium = true;
    }

    modalRef.result.then(
      (result: any) => {
        if (result === 2) {
          const customerPaymentsModal = this.modalService.open(CustomersPaymentsComponent, {
            size: 'lg',
            windowClass: 'plan-upgrade',
            backdrop: 'static',
            keyboard: true,
            backdropClass: 'light-backdrop plan-upgrade',
          });
          const plan = this.planes.find((p: Plan) => p.id === result);

          if (this.user.plan == null || this.user.plan.id === 1) {
            customerPaymentsModal.componentInstance.firstTime = true;
          }

          customerPaymentsModal.componentInstance.plan = plan;
          customerPaymentsModal.result.then((data) => {
            this.event.publish('reviewSaldo');
          });
        } else if (result === 3) {
          const customerPaymentsModal = this.modalService.open(CustomersPaymentsComponent, {
            size: 'lg',
            windowClass: 'plan-upgrade',
            backdrop: 'static',
            keyboard: true,
            backdropClass: 'light-backdrop plan-upgrade',
          });
          const plan = this.planes.find((p: Plan) => p.id === result);

          if (this.user.plan == null || this.user.plan.id === 1) {
            customerPaymentsModal.componentInstance.firstTime = true;
          }

          customerPaymentsModal.componentInstance.plan = plan;
          customerPaymentsModal.result.then((data) => {
            this.event.publish('reviewSaldo');
          });

        }
      },

      (reason) => console.error(reason)
    );
  }

  public showItems(item, type) {
    if (type === 1 && item.subvisible === false) {
      this.closeMenu();
    }
    if (item.subvisible) {
      item.subvisible = !item.subvisible;
    } else {
      item.subvisible = true;
    }
  }

  public showMaestros() {
    if (!this.clickedsubEvents) {
      this.showMaestrosMenu = !this.showMaestrosMenu;
    }
    this.clickedsubEvents = false;
  }

  public showCampaigns() {
    if (!this.clickedsubEvents) {
      this.showCampaignsMenu = !this.showCampaignsMenu;
    }
    this.clickedsubEvents = false;
  }

  public showConfigs() {
    this.showConfigsMenu = !this.showConfigsMenu;
    this.clickedsubEvents = !this.clickedsubEvents;
  }

  public showHelp() {
    this.showHelpMenu = !this.showHelpMenu;
  }

  public showPromociones() {
    this.showPromocionesMenu = !this.showPromocionesMenu;
  }

  public showDestinos() {
    this.showDestinosMenu = !this.showDestinosMenu;
  }

  public showCityManagers() {
    this.showCityManagersMenu = !this.showCityManagersMenu;
  }

  public showAds() {
    this.showAdsMenu = !this.showAdsMenu;
  }

  public showCards(event: Event) {
    if (this.checkPlan()) {
      event.preventDefault();

      this.mostrarMensajeCompra();
    } else {
      this.showCardsMenu = !this.showCardsMenu;
    }
  }

  public showLocals() {
    this.showLocalsMenu = !this.showLocalsMenu;
  }

  public showRoute(event: Event) {
    if (this.checkPlan()) {
      event.preventDefault();

      this.mostrarMensajeCompra();
    } else {
      this.showRouteMenu = !this.showRouteMenu;
    }
  }

  public showInfoDestination() {
    this.showInfoDestinationMenu = !this.showInfoDestinationMenu;
  }

  public showUnblockPay() {
    this.showUnblockPayMenu = !this.showUnblockPayMenu;
  }

  public showResources() {
    this.showResourcesMenu = !this.showResourcesMenu;
  }

  public showResourcesSee() {
    this.showResourcesSeeMenu = !this.showResourcesSeeMenu;
  }

  public showEvents() {
    this.showEventsMenu = !this.showEventsMenu;
    this.clickedsubEvents = !this.clickedsubEvents;
  }

  public showQueVer() {
    this.showQueVerMenu = !this.showQueVerMenu;
  }

  public showQueHacer() {
    if (!this.clickedsubEvents) {
      this.showQueHacerMenu = !this.showQueHacerMenu;
    }

    this.clickedsubEvents = false;
  }

  public showEventsOther() {
    this.showEventsOtherMenu = !this.showEventsOtherMenu;
  }

  public close(e: Event) {

    if (e !== undefined) {
      e.stopPropagation();
    }

    $('.visible').removeClass('visible');
  }

  public closeMenu() {
    for (let i = 0; i < this.sidebarnavItems.length; i++) {
      this.sidebarnavItems[i].subvisible = false;
      for (let j = 0; j < this.sidebarnavItems[i].submenu.length; j++) {
        this.sidebarnavItems[i].submenu[j].subvisible = false;
      }
    }
  }
}
