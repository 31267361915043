import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../services/authentication.service';
import { NotificationService } from '../../../services/notification.service';
import { PlanService } from '../../../services/plan.service';
import { Local } from '../../../models/local';
import { Promo } from '../../../models/promo';
import { Plan } from '../../../models/plan';
import { Events } from '../../../services/events.service';


interface Activar {
  locales: Array<Local>;
  promosInApp: Array<Promo>;
  promosQR: Array<Promo>;
  promos: Array<Promo>;
}

@Component({
  selector: 'app-plan-review',
  templateUrl: './plan-review.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./plan-review.component.scss'],
})
export class PlanReviewComponent implements OnInit {
  @Input() public locales: Array<Local>;
  @Input() public promosInApp: Array<Promo>;
  @Input() public promosQR: Array<Promo>;

  public form: FormGroup;
  public plan: Plan;
  public submitted: boolean;
  public tab = 1;
  public cantidadPromoInApp = 0;
  public cantidadPromoQR = 0;
  public cantidadCarta = 0;
  public cantidadLocal = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private event: Events,
    private planService: PlanService
  ) {
    this.submitted = false;
    this.locales = [];
    this.promosInApp = [];
    this.promosQR = [];

    this.plan = this.authenticationService.currentUserValue.user.plan;
  }

  ngOnInit() {
    this.tab = 1;
    this.form = this.formBuilder.group(
      {
        locales: this.formBuilder.array([]),
        promosInApp: this.formBuilder.array([]),
        promosQR: this.formBuilder.array([]),
      },
      {
        validator: [],
      }
    );

    this.promosQR.forEach((promo: Promo) => {
      (this.form.get('promosQR') as FormArray).push(
        new FormGroup({
          id: new FormControl({
            value: promo.id,
            disabled: false,
          }),
          activa: new FormControl({
            value: false,
            disabled: false,
          }),
          titulo: new FormControl({
            value: promo.titulo,
            disabled: false,
          }),
          descripcion: new FormControl({
            value: promo.descripcion,
            disabled: false,
          }),
          createdAt: new FormControl({
            value: promo.createdAt,
            disabled: false,
          }),
        })
      );
    });

        this.promosInApp.forEach((promo: Promo) => {
          (this.form.get('promosInApp') as FormArray).push(
            new FormGroup({
              id: new FormControl({
                value: promo.id,
                disabled: false,
              }),
              activa: new FormControl({
                value: false,
                disabled: false,
              }),
              titulo: new FormControl({
                value: promo.titulo,
                disabled: false,
              }),
              descripcion: new FormControl({
                value: promo.descripcion,
                disabled: false,
              }),
              createdAt: new FormControl({
                value: promo.createdAt,
                disabled: false,
              }),
            })
          );
        });

      this.locales.forEach((local: Local) => {

        (this.form.get('locales') as FormArray).push(
          new FormGroup({
            id: new FormControl({
              value: local.id,
              disabled: false,
            }),
            activo: new FormControl({
              value: false,
              disabled: false,
            }),
            nombre: new FormControl({
              value: local.nombre,
              disabled: false,
            }),
            descripcion: new FormControl({
              value: local.descripcion,
              disabled: false,
            }),
            createdAt: new FormControl({
              value: local.createdAt,
              disabled: false,
            }),
          })
        );
      });
  }

  public checkLimit(control: AbstractControl): ValidationErrors | null {
    const elements: FormGroup[] = (control as FormArray)
      .controls as FormGroup[];
    let cantidad = 0;

    elements.forEach((element: any) => {
      if (element.contains('activa') && element.get('activa').value) {
        cantidad++;
      } else {
        if (element.contains('activo') && element.get('activo').value) {
          cantidad++;
        }
      }
    });
    return cantidad < 2 ? { checkLimit: { value: control.value } } : null;
  }

  /**
   * isLocal
   */
  public isLocal(formGroup: FormGroup): void {
    const elements: FormGroup[] = (formGroup.parent as FormArray)
      .controls as FormGroup[];

    if (
      this.cantidadLocal < this.plan.configuracion.localLimit ||
      !formGroup.get('activo').value
    ) {
      if (formGroup.get('activo').value) {
        this.cantidadLocal++;
      } else {
        this.cantidadLocal--;
      }
    } else {
      this.notificationService.error(
        'Solo puede tener ' +
          this.plan.configuracion.localLimit +
          ' locales activos'
      );

      formGroup.get('activo').setValue(false);
    }
  }

  /**
   * isPromoInApp
   */
  public isPromoInApp(event: Event, formGroup: FormGroup): void {
    const elements: FormGroup[] = (formGroup.parent as FormArray)
      .controls as FormGroup[];

    if (
      this.cantidadPromoInApp < this.plan.configuracion.promoLimitInApp ||
      !formGroup.get('activa').value
    ) {
      if (formGroup.get('activa').value) {
        this.cantidadPromoInApp++;
      } else {
        this.cantidadPromoInApp--;
      }
    } else {
      this.notificationService.error(
        'Solo puede tener ' +
          this.plan.configuracion.promoLimitInApp +
          ' promos In App activas'
      );

      formGroup.get('activa').setValue(false);
    }
  }

  /**
   * isPromoQr
   */
  public isPromoQr(formGroup: FormGroup): void {
    const elements: FormGroup[] = (formGroup.parent as FormArray)
      .controls as FormGroup[];

    if (
      this.cantidadPromoQR < this.plan.configuracion.promoLimitQr ||
      !formGroup.get('activa').value
    ) {
      if (formGroup.get('activa').value) {
        this.cantidadPromoQR++;
      } else {
        this.cantidadPromoQR--;
      }
    } else {
      this.notificationService.error(
        'Solo puede tener ' +
          this.plan.configuracion.promoLimitQr +
          ' promos QR activas'
      );

      formGroup.get('activa').setValue(false);
    }
  }

  public activar(): void {
    if (this.form.valid) {
      const activar: Activar = {
        locales: [],
        promosInApp: [],
        promosQR: [],
        promos: []
      };

      const locales: Array<AbstractControl> = (
        this.form.get('locales') as FormArray
      ).controls;

      locales.forEach((local: any) => {

        activar.locales.push({
          id: local.get('id').value,
        });

        // NO BORRAR, temporal
        /*if (local.get('activo').value) {
          activar.locales.push({
            id: local.get('id').value,
          });
        }*/

      });


      const promosInApp: Array<AbstractControl> = (
        this.form.get('promosInApp') as FormArray
      ).controls;

      promosInApp.forEach((promo: any) => {
        if (promo.get('activa').value) {
          activar.promosInApp.push({
            id: promo.get('id').value,
          });
          activar.promos.push({
            id: promo.get('id').value,
          });
        }
      });

      const promosQR: Array<AbstractControl> = (
        this.form.get('promosQR') as FormArray
      ).controls;

      promosQR.forEach((promo: any) => {
        if (promo.get('activa').value) {
          activar.promosQR.push({
            id: promo.get('id').value,
          });
          activar.promos.push({
            id: promo.get('id').value,
          });
        }
      });

      // NO BORRAR , es temporal
      /*if ( activar.locales.length <= this.plan.configuracion.localLimit &&
        activar.promosQR.length <= this.plan.configuracion.promoLimitInApp &&
        activar.promosQR.length <= this.plan.configuracion.promoLimitQr) {*/

      if ( activar.promosQR.length <= this.plan.configuracion.promoLimitInApp &&
          activar.promosQR.length <= this.plan.configuracion.promoLimitQr) {

        this.submitted = true;

        this.planService
          .checkLimit(activar)
          .toPromise()
          .then(
            (message: string) => {
              this.notificationService.success('Actualizado correctamente');
              this.submitted = false;
              this.activeModal.close(message);
            },

            () => {
              this.notificationService.error( 'No se ha guardado los cambios');
            }
          );
      } else {
        this.notificationService.error(
          'Su plan ' +
            this.plan.titulo +
            ' solo acepta ' +
            //this.plan.configuracion.localLimit +
            //' locales activos. ' +
            this.plan.configuracion.promoLimitInApp +
            ' promos In App activos. ' +
            this.plan.configuracion.promoLimitQr +
            ' promos QR activos.'
        );
      }
    } else {
      this.notificationService.error(
        `Debes seleccionar ${this.plan.configuracion.promoLimitQr} promociones QR y ${this.plan.configuracion.promoLimitInApp} promociones in.app para poder guardar tus cambios.`
      );
    }
  }

  public changeTap(tab: number): void {
    this.tab = tab;
  }

  public htmlToText(html: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  goToUpgrade() {
    this.activeModal.close();
    this.event.publish('upgradePlan');
  }
}
