import * as global from '../../../services/global.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { objsToCamelCase } from '../../../helpers/object.helper';
import { EspaceEnlace } from '../interfaces/espaceEnlace';
import { EspaceEnlaceRequest } from '../models/espaceEnlaceRequest.model';

@Injectable({
  providedIn: 'root'
})
export class EspaceEnlaceService {
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public byId( id: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set( 'idiomas', 'true');

    this.httpOptions = this.buildOptions( httpParams);
    const url = this.byIdUrl( id);

    return this.processResponse(
      this.http.get<EspaceEnlace>( url, this.httpOptions)
    );
  }

  public index( request: EspaceEnlaceRequest) {
    const httpParams = this.buildParams( request);
    this.httpOptions = this.buildOptions( httpParams);

    const url = this.indexUrl();

    return this.processResponse(
      this.http.get( url, this.httpOptions)
    );
  }

  public create( espaceEnlace: EspaceEnlace): Promise<EspaceEnlace> {
    this.httpOptions = this.buildOptions( {}, 'Content-Type');
    const url = this.postUrl();

    return this.http.post<EspaceEnlace>( url, espaceEnlace, this.httpOptions).toPromise();
  }

  public update( espaceEnlace: EspaceEnlace): Promise<EspaceEnlace> {
    this.httpOptions = this.buildOptions( {}, 'Content-Type');
    const url = this.putUrl( espaceEnlace.id);

    return this.http.put<EspaceEnlace>( url, espaceEnlace, this.httpOptions).toPromise();
  }

  public delete( espaceEnlace: EspaceEnlace): Promise<any> {
    this.httpOptions = this.buildOptions();
    const url = this.deleteUrl( espaceEnlace.id);

    return this.http.delete<any>( url, this.httpOptions).toPromise();
  }

  private buildParams( request: EspaceEnlaceRequest): HttpParams {
    let result = new HttpParams();

    const paramNames = [
      'perPage',
      'orderBy',
    ];

    paramNames.forEach( param => {
      const getter = this.buildGetter( param);
      const value = request[getter]();

      if (value) {
        result = result.set( param, String( value));
      }
    });

    return result;
  }

  private buildOptions( httpParams?: HttpParams|Object, excludedHeader?: string): object {
    let headers = this.httpHeaders;

    if (excludedHeader) {
      headers = headers.delete( excludedHeader);
    }

    return {
      headers,
      params: httpParams || {}
    };
  }

  private indexUrl(): string {
    return this.baseUrl;
  }

  private postUrl(): string {
    return this.baseUrl;
  }

  private putUrl( id: number): string {
    return this.byIdUrl( id);
  }

  private deleteUrl( id: number): string {
    return this.byIdUrl( id);
  }

  private byIdUrl( id: number): string {
    return `${this.baseUrl}/${id}`;
  }

  private get baseUrl(): string {
    return `${global.API_URL}/api/localsenlace`;
  }

  private buildGetter( name: string): string {
    return 'get' + name.charAt( 0).toUpperCase() + name.substring( 1);
  }

  private processResponse( request: Observable<any>): Promise<Object> {
    return request.pipe(
        map( (response: any) => objsToCamelCase( response)
      ))
      .toPromise();
  }
}
