import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() public title: string;
  @Input() public msg: string;
  @Input() public ok: string;
  @Input() public ko: string;

  constructor(
    private modal: NgbActiveModal
  ) { }

  ngOnInit() {
    
  }

  close(){
    this.modal.close({close: true, didmiss: false});
  }

  close2(){
    this.modal.close({close: false, didmiss: false, close2: true});
  }

  didmiss(){
    this.modal.dismiss({close: false, didmiss: true});
  }



}
