import { PaginationRequest } from '../../../interfaces/paginationRequest.model';

export class TarjetaRequest extends PaginationRequest {
  private userId: number;
  private clienteId: number;
  private destinoId: number;
  private promos: boolean;
  private productos: boolean;
  private patrocinadores: boolean;
  private invitations: boolean;
  private photos: boolean;

  constructor() {
    super();
  }

  public getUserId(): number {
    return this.userId;
  }

  public setUserId( userId: number) {
    this.userId = userId;
  }

  public getClienteId(): number {
    return this.clienteId;
  }

  public setClienteId( clienteId: number) {
    this.clienteId = clienteId;
  }

  public getDestinoId(): number {
    return this.destinoId;
  }

  public setDestinoId( destinoId: number) {
    this.destinoId = destinoId;
  }

  public getPromos(): boolean {
    return this.promos;
  }

  public setPromos( promos: boolean) {
    this.promos = promos;
  }

  public getProductos(): boolean {
    return this.productos;
  }

  public setProductos( productos: boolean) {
    this.productos = productos;
  }

  public getPatrocinadores(): boolean {
    return this.patrocinadores;
  }

  public setPatrocinadores( patrocinadores: boolean) {
    this.patrocinadores = patrocinadores;
  }

  public getInvitations(): boolean {
    return this.invitations;
  }

  public setInvitations( invitations: boolean) {
    this.invitations = invitations;
  }

  public getPhotos(): boolean {
    return this.photos;
  }

  public setPhotos( photos: boolean) {
    this.photos = photos;
  }
}
