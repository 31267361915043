// core imports
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// business imports
import * as global from '../../services/global.service';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';
import { Events } from '../../services/events.service';
import { TranslateService } from '@ngx-translate/core';
import { Compra } from '../customers/interfaces/compra';
import { CompraService } from '../customers/services/compra.service';

@Component({
  selector: 'app-tpv-integration',
  templateUrl: './tpv-integration.component.html',
  styleUrls: ['./tpv-integration.component.css'],
})
export class TpvIntegrationComponent implements OnInit {

  @Output() public payment = new EventEmitter();
  @Output() public timeout = new EventEmitter();

  @Input() amount: string;
  @Input() product: string;
  @Input() compra: Compra;

  public clientId: string;
  public orderId = '_no_order_id_';
  
  // TPV info API
  public default: any;
  public countries: any;
  public currencies: any;
  public languages: any;
  public paymentsTypes: any;
  public providers: any;

  // Provider
  public hostTpvIframe: string;
  public pathTpvIframeTemplate: string;
  public urlCheckOrderPrefix: string;
  public urlCheckOrderTemplate: string;
  public urlNewOrderPrefix: string;
  public urlNewOrderTemplate: string;


  // En la llamada de pedir el TPV me envia la URL del iframe y TPV Provider
  // Eliminaremos las variables de entorno
  public tpvUrl = '';
  public timerId: any;
  public tpvProvider = '';
  // Establecemos en código de país estático por el momento
  public countryCode = 'ES';
  public langCode = 'es';

  // Eliminar esta parte cuando se obtiene de la API
  public pathTpvMicroservice = '/#/drop-in/';
  public pathTpvParametersTemplate = '[:clientId]/[:orderId]/[:amount]';

  public timeLeft = 300;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private event: Events,
    private compraService: CompraService,
    private translate: TranslateService,

  ) {
    // TPV: get clientId from user
    this.clientId = this.authenticationService.currentUserValue.user.cliente.id.toString();
    // TPV: get language code
    this.translate.setDefaultLang(this.langCode);
    this.translate.use(this.langCode);

  }

  /**
   * Must wait for API response to build iframe URL
   * @param changes
   */
  async ngOnInit() {

    this.event.subscribe('stopCheckPaymenyt', () => {
      clearInterval(this.timerId);
      this.event.destroy('stopCheckPaymenyt');
    });

    try {

      // Obtenemos el proveedor del TPV
      await this.getProvider();
      await this.setProviderVariables();

      // Obtenemos el ID de la compra
      await this.getOrderId();

      // 
      this.tpvUrl = this.buildTpvUrl();

      // Comprobamos cada cierto tiempo
      this.timerId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          if (this.timeLeft % 2 === 0) {
            this.http.get(this.urlCheckOrderPrefix + `${this.clientId}/${this.orderId}/${this.amount}`).subscribe(
              (response: any) => {
                clearInterval(this.timerId);
                let event = null;
                if (response['status'] === 'paid') {
                  event = 1;
                  this.compraService
                    .create(this.compra)
                    .toPromise()
                    .then(
                      (compra: Compra) => {
                        const user = this.authenticationService.currentUserValue;
                        if(user.user.plan.id < compra.plan.id){
                          user.user.plan = compra.plan;
                          user.user.planId = compra.plan.id;
                          this.authenticationService.updateUser(user);
                          this.event.publish('changePlan');
                        }
                        this.event.publish('reviewSaldo');
                      },
                      (error: HttpErrorResponse) => {
                      }
                    );
                } else if (response['status'] === 'error') {
                  event = 0;
                }
                this.payment.emit(event);
              },
              (error: HttpErrorResponse) => {
              }
            );
          }
        } else {
          this.timeout.emit();
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(this.timerId);
      }, 320000);
    } catch (error) {
      console.error('Error getting compra ID from API:', error);
    }
    
  }

  private getOrderId(): Promise<void> {
    // 
    const url = this.urlNewOrderPrefix + this.tpvProvider + this.countryCode + '/' + this.translate.currentLang + '/' + this.default.provider + '/' + this.product + '/' + this.clientId + '/' + this.amount;

    // Let's call API point and wait for response (in compra to build iframe URL)
    return new Promise<void>((resolve, reject) => {
      this.http.get(url).subscribe(
        (response) => {
          this.orderId = response['hash'];
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  
  private getProvider(): Promise<void> {
    // 
    const url = global.API_URL + '/api/tpv/providers';

    // Let's call API point and wait for response (in compra to build iframe URL)
    return new Promise<void>((resolve, reject) => {
      this.http.get(url).subscribe(
        (response) => {
          this.providers = response;
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private setProviderVariables(){

    // TPV info API
  this.default = this.providers.default;
  this.countries = this.providers.countries;
  this.currencies = this.providers.currencies;
  this.languages = this.providers.languages;
  this.paymentsTypes = this.providers.paymentsTypes;

  // Provider
  let provider = Object.entries(this.providers.providers).filter(([key]) => key === this.default.provider);
  this.hostTpvIframe = provider[0][1]['hostTpvIframe'];
  this.pathTpvIframeTemplate = provider[0][1]['pathTpvIframeTemplate'];
  this.urlCheckOrderPrefix = provider[0][1]['urlCheckOrderPrefix'];
  this.urlCheckOrderTemplate = provider[0][1]['urlCheckOrderTemplate'];
  this.urlNewOrderPrefix = provider[0][1]['urlNewOrderPrefix'];
  this.urlNewOrderTemplate = provider[0][1]['urlNewOrderTemplate'];

  }
  
  /**
   * Builds proper url and returns it
   */
  buildTpvUrl(): string {
    const replacements = {
      '{cliente_id}': this.clientId,
      '{order_hash}': this.orderId,
      '{amount}': this.amount
    };
    "/#/drop-in/{cliente_id}/{order_hash}/{amount}"

    const pathTpvIframeTemplate = this.pathTpvIframeTemplate.replace(
      /(\{cliente_id\}|\{order_hash\}|\{amount\})/g,
      (match) => {
        return replacements[match];
      }
    );
    return this.hostTpvIframe + pathTpvIframeTemplate;
  }

  public getFormattedTimeLeft(): string {
    const minutes = String(Math.floor(this.timeLeft / 60)).padStart(2, '0');
    const seconds = String(this.timeLeft % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
}
