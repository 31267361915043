import * as global from '../../services/global.service';
import { DatePipe } from '@angular/common';
import { Form, FormGroup, ValidationErrors } from '@angular/forms';
import { Pagination } from '../../models/pagination';
import { Plan } from '../../models/plan';

const datePipe = new DatePipe('en-US');

export class Utils {
  public static buildImageUrl( image: string): string {
    return global.CDN_URL + image;
  }

  public static toDate( value: string): Date {
    return new Date( datePipe.transform( value, 'yyyy-MM-dd'));
  }

  public static removeFromArray( array: any[], item: any) {
    array.splice( array.indexOf( item), 1);
  }

  public static addToArray( array: any[], item: any) {
    array.push(item);
  }

  /**
   * Convierte el nombre de un campo en camelCase a "Camel Case".
   *
   * @example
   *    descripcionCorta -> "Descripcion Corta"
   */
  public static toDisplayFieldName( name: string): string {
    return this.capitalize( name)
                .split( /(?=[A-Z])/)
                .join( ' ');
  }

  public static capitalize( value: string): string {
    return value[0].toUpperCase() + value.substring( 1);
  }

  public static getFirstNotNull( list: Function[]): string|null {
    for (let fn of list) {
      const result = fn();

      if (result) {
        return result;
      }
    }

    return null;
  }

  public static getFirstNotNullAsync( list: Function[]): Promise<string|null> {
    for (let fn of list) {
      const result = fn();

      if (result) {
        if (Utils.isPromise( result)) {
          return result;
        } else {
          return Promise.resolve( result);
        }
      }
    }

    return Promise.resolve( null);
  }

  public static getFirstFormError( form: FormGroup, errorMessage: string): string|null {
    for (let fieldName of Object.keys( form.controls)) {
      const errors: ValidationErrors = form.get( fieldName).errors;

      if (errors !== null) {
        for (let key of Object.keys( errors)) {
          if (key === 'required' && errors[key]) {
            return errorMessage + Utils.toDisplayFieldName( fieldName);
          } else {
            return key;
          }
        }
      }
    }

    return null;
  }

  public static getFirstFormErrorAsync( form: FormGroup, errorMessage: string): Promise<string|null> {
    return Promise.resolve( Utils.getFirstFormError( form, errorMessage));
  }

  public static isPresent( value: any): boolean {
    return value !== null && typeof value !== 'undefined';
  }

  public static isBlank( value?: string): boolean {
    return !Utils.isPresent( value) || value.trim().length === 0;
  }

  public static anyIsBlank( items: Array<any>): boolean {
    return items.some( Utils.isBlank);
  }

  public static isPromise( value?: any): boolean {
    return typeof value !== 'undefined' && value !== null && 'then' in value;
  }

  public static calculatePage(index: number, pagination: Pagination){

    let total = pagination['links'].length - 1;
    let currentPage = pagination.currentPage;
    
    // Anterior
    if ( index === 0 && index !== total  ){
      return currentPage - 1;
    }

    // Siguiente
    if ( total === index && index !== 0 ){
      return currentPage + 1;
    }

    // Page
    if ( index > 0 && index < total){
      return Number(pagination['links'][index].label);
    }
  }

  public static getPlan(id): Plan{

    if( id === 1 ) {
      return {
        id : 1,
        titulo : 'BASIC',
        moMinimo : 120
      }
    }

    if( id === 2 ) {
      return {
        id : 2,
        titulo : 'PRO',
        moMinimo : 120
      }
    }
    
  }
}
