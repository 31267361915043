import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as global from './global.service';
import { objToCamelCase } from '../helpers/object.helper';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { Administracion } from '../models/administracion';

@Injectable({
  providedIn: 'root'
})
export class DestinationService {

  private static DESTINATION_PATH = global.API_URL + '/api/administration';
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public show(id: number): Observable<Administracion> {

    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<Administracion>(DestinationService.DESTINATION_PATH + `/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Administracion>(response);
      })
    );
  }

  public byUserId(id: number): Observable<Administracion> {
    this.httpOptions = {
      headers: this.httpHeaders,
    };

    return this.http.get<Administracion>(DestinationService.DESTINATION_PATH + `/user/${id}`, this.httpOptions).pipe(
      map((response: any) => {
        return objToCamelCase<Administracion>(response);
      })
    );
  }

  public delete(destination: Administracion): Observable<any> {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(DestinationService.DESTINATION_PATH + `/${destination.id}`, this.httpOptions);
  }
}
